import to from 'await-to-js'
import AuthService from '../services/AuthService'
import {mapActions} from 'vuex'
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth'
import {EventBus} from "../store/event-bus";

export default {
  data () {
    return {
      googleLoad: false
    }
  },
  computed: {
    displayGoogleLogin () {
      return (this.company?.login?.google_login && !this.isModal) ?? true;
    }
  },
  mounted () {
    GoogleAuth.initialize()
  },
  methods: {
    ...mapActions(['loginSetup', 'getLoggedInAccounts']),
    async googleLogin () {
      this.googleLoad = true
      const [errAuth, user] = await to(GoogleAuth.signIn())
      if (errAuth) return this.googleLoad = false
      if (user.authentication && (user.authentication.accessToken || user.authentication.idToken)) {
        const [err, response] = await to(AuthService.googleSDK(user))
        if (err) {
          this.googleLoad = false
          this.$store.commit('setLoading', false)
          return this.errorHandler(err)
        }
        if (response.data.success) {
          this.loginSetup(response)
	      this.getLoggedInAccounts(this.storage.refreshTokens.split(','))
          this.$router.push('/' + response.data.data.username + '#edit')
          setTimeout(() => {
            if (response.data.message === 'SUCCESS_REGISTER') {
	            EventBus.$emit('openModalOverlay')
              EventBus.$emit('toggleUsernameModal')
            }
            this.googleLoad = false
            this.$store.commit('setLoading', false)
          }, 1000)
        } else this.errorSnack(this.lang[this.appLang].unexpected_err)
      } else {
        this.googleLoad = false
        this.$store.commit('setLoading', false)
      }
    }
  }
}
