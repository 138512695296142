<template>
  <div class="menu-wrapper menu-light menu-modal center-text activate-page" :class="{'active-menu': toggle}" style="height: auto; margin-top: -172.5px;">
    <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
      <i class="font-17 fa color-black">
        <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
      </i>
    </a>
    <img :src="'https://cdn.tapni.co/icons/handshake.png'" style="margin: 0 auto" alt="afterConnect"/>

    <h3 class="center-text bold small-top half-bottom" v-if="user"> {{this.lang[this.appLang].stay_in_touch}}<br> @{{user.username}}</h3>



    <div style="width: 65%; display: inline-block; margin: 0 auto; margin-top:5px;">
      <p>{{this.lang[this.appLang].create_free_account}}</p>
    </div>

    <button @click="join" class="button white-button half-top" style="margin: 0 auto; width: 65%;">
      {{this.lang[this.appLang].create_account}}
    </button>

    <div style="width: 65%; display: inline-block; margin: 0 auto; margin-top:10px;">
      <a @click="close" style="margin: 0 auto; margin-top: 20px; text-decoration: underline; text-underline-position: under; display: inline;" class="color-black small-top pointer">{{this.lang[this.appLang].no_thanks}}</a>
    </div>
  </div>
</template>

<script>
import {EventBus} from '../../store/event-bus'
export default {
  name: 'AfterConnect',
  data () {
    return {
      toggle: false,
      showDetails: false
    }
  },
  mounted () {
    EventBus.$on('toggleAfterConnectModal', this.toggleModal)
    EventBus.$on('closeModal', () => { this.toggle = false })
  },
  methods: {
    join () {
      this.close()
      this.$router.push('/register')
    },
    close () {
      EventBus.$emit('closeModal')
    },
    toggleModal () {
      this.toggle = !this.toggle
    }
  }
}
</script>

<style scoped>


</style>
