<template>
    <form @submit.prevent="saveLink" class="menu-wrapper menu-light menu-modal center-text activate-page" :class="{'active-menu': toggle}" style="height: 390px; margin-top: -172.5px;">
      <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
        <i class="font-17 fa color-black">
          <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
        </i>
      </a>
	  <img v-if="data && data.type !== 'customlink'" :src="'https://cdn.tapni.co/icons/' + data.type + '.png'" class="link-img half-bottom half-top" :alt="data.name"/>
	  <img v-else-if="data && !linkImageBase64" :src="data.custom_icon ? data.style.custom_icon : 'https://cdn.tapni.co/icons/customlink.png'" style="border-radius: 22.46%;" class="custom-link-img half-bottom half-top" :alt="data.name"/>
	  <img v-else-if="data && linkImageBase64" :src="linkImageBase64" class="custom-link-img half-bottom half-top"  style="border-radius: 15px;" :alt="data.name"/>

	  <span class="center-text">
        <input v-if="data" id="linkText" type="text" v-model="data.text" :placeholder="lang[this.appLang].link_text_p"
               v-autowidth="{maxWidth: '100%', minWidth: '50%', comfortZone: 0}"
               class="edit-input edit-shadow" maxlength="30"/>
        <p class="close-text close-menu center-text small-top half-bottom">{{this.lang[this.appLang].link_icon}}</p>

        <vue-google-autocomplete
              id="review"
              ref="review"
              :placeholder="lang[appLang].search"
              types="establishment"
              @placechanged="getAddressData"
              v-autowidth="{maxWidth: '95%', minWidth: '50%', comfortZone: 0}"
              classname="edit-input edit-shadow margin-center"
        />
        <p v-if="data" class="close-text close-menu center-text small-top half-bottom">{{data.desc[this.appLang].long}}</p>

        <a v-if="false" @click="redirectLink(data, true)" class="button white-button small-top pointer edit-shadow" style="margin: 0 5px;">
          {{this.lang[this.appLang].open}}
        </a>

        <!-- Delete -->
        <a v-if="data && data.id && !confirmDelete" @click="confirmDelete = true" class="button white-button small-top pointer edit-shadow"
           style="margin: 0 5px; width: 35%;">
          {{this.lang[this.appLang].delete}}
        </a>
        <a v-if="data && data.id && confirmDelete" @click="deleteLink(data.id)" class="button small-top white-button pointer edit-shadow"
           style="margin: 0 5px; color: #fc1414; width: 35%;">
          {{this.lang[this.appLang].confirm}}
        </a>

        <button type="submit" class="button white-button small-top edit-shadow pointer" style="margin: 0 5px; width:35%;">
          {{this.lang[this.appLang].save}}
        </button>
      </span>
    </form>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapState, mapActions, mapGetters} from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

export default {
  name: 'GoogleReview',
  components: {
      VueGoogleAutocomplete
  },
  data () {
    return {
      data: null,
      confirmDelete: false,
      toggle: false,
	  linkImageBase64: '',
	  placeID: '',
      placeName: '',
    }
  },
  computed: {
      linkText () {
          if (this.appLang !== 'en' &&  this.data.text) {
              return this.lang[this.appLang]['link_text_' + this.data.text.toLowerCase().trim().replace(/ /g, '_')] || this.data.text
          }
          return this.data.text
      }
  },
  mounted () {
    EventBus.$on('toggleGoogleReviewModal', this.toggleModal)
    EventBus.$on('closeModal', () => {
      setTimeout(() => {
        this.confirmDelete = false
        this.linkImageBase64 = ''
      }, 1000)
      this.toggle = false
    })
  },
  methods: {
    ...mapActions(['addOrUpdateLink', 'removeLink']),
	uploadLinkIcon () {
	  EventBus.$emit('typeUploadModal', 'link')
	  document.getElementById('media').click()
	},
    close () {
      EventBus.$emit('closeModal')
    },
    deleteLink (id) {
      this.removeLink({id})
      EventBus.$emit('closeModal')
      setTimeout(() => {
        this.confirmDelete = false
        this.linkImageBase64 = ''
      }, 1000)
    },
    saveLink () {
      if (!this.placeID) return this.errorSnack(this.lang[this.appLang].link_validate);
      this.addOrUpdateLink({
        id: this.data.id,
        type: this.data.type,
        url: this.placeID,
        subtext: this.placeName,
        text: this.data.text,
        is_direct: this.data.is_direct,
        custom_icon: this.linkImageBase64 ? this.data.type === 'customlink' : undefined,
        linkImageBase64: this.linkImageBase64 ? this.linkImageBase64 : undefined
      })
      EventBus.$emit('toggleGoogleReviewModal')
      this.$router.push({
        path: this.home.split('#')[0],
        hash: 'edit'
      })
      this.confirmDelete = false
    },
    toggleModal (data) {
      this.toggle = !this.toggle
      this.confirmDelete = false
      if (data) {
        this.data = {...data}
        const linkType = this.linkTypes.filter(e => e.type === data.type)[0]
        this.data.desc = linkType.desc

        this.data.text = this.linkText

        this.$refs.review.update(this.data.subtext);

        if (linkType.prefill_url) {
          if (!data.id) {
            this.data.url = linkType.base_url
          }
        } else {
          if (data.id && this.data.url) {
            this.data.url = this.data.url.replace(linkType.base_url, '')
          }
        }
      }
    },
    async getAddressData(address, place) {
        this.placeID = place.place_id
        this.placeName = place.name
    }
  }
}
</script>

<style scoped>
  .link-img {
    margin: 0 auto;
    width: 70px;
  }
  .custom-link-img {
	  margin: 0 auto;
	  width: 85px;
  }
  #linkText {
    font-size:22px;
	margin: 0 auto;
  }

  .notransition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }

  #linkUrl {
    font-size:17px;
	margin: 0 auto;
  }
</style>
