<template>
  <div
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    :style="`height: auto; margin-top: -172.5px; max-height: 90vh`"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>

    <h1 class="left-text bold small-top half-bottom headerText" style="padding-left: 5px">
      {{ lang[this.appLang].filterConnectionsHeader }}
    </h1>

    <div style="overflow-y: scroll; max-height: calc(92vh - 170px)">
      <h5 class="half-top">
        {{ lang[this.appLang].selectConnectionType }}
      </h5>

      <div class="connectionType">
        <span
          :class="{ active: connectionType === 'all' }"
          @click="connectionType = 'all'"
        >
          {{ lang[this.appLang].all }}
        </span>
        <span
          :class="{ active: connectionType === 'connected' }"
          @click="connectionType = 'connected'"
        >
          {{ lang[this.appLang].direct }}
        </span>
        <span
          :class="{ active: connectionType === 'anonymous' }"
          @click="connectionType = 'anonymous'"
        >
          {{ lang[this.appLang].form }}
        </span>
        <span
          :class="{ active: connectionType === 'manually' }"
          @click="connectionType = 'manually'"
        >
          {{ lang[this.appLang].manually }}
        </span>
        <span
          :class="{ active: connectionType === 'scan' }"
          @click="connectionType = 'scan'"
        >
          {{ lang[this.appLang].scan }}
        </span>
      </div>
      <p class="small-top">{{ connectionTypeLabel[connectionType] }}</p>

      <h5 class="full-top">
        {{ lang[this.appLang].sortOptions }}
      </h5>

      <div class="connectionType sortOptions">
        <span
          :class="{ active: sortBy === 'createdAt' }"
          @click="sortBy = 'createdAt'"
        >
          {{ lang[this.appLang].date }}
        </span>
        <span :class="{ active: sortBy === 'name' }" @click="sortBy = 'name'">
          {{ lang[this.appLang].fullName }}
        </span>
      </div>

      <div class="connectionType sortOptions">
        <span
          :class="{ active: sortDirection === 'desc' }"
          @click="sortDirection = 'desc'"
        >
          {{ lang[this.appLang].descending }}
        </span>
        <span
          :class="{ active: sortDirection === 'asc' }"
          @click="sortDirection = 'asc'"
        >
          {{ lang[this.appLang].ascending }}
        </span>
      </div>

      <h5 class="full-top">
        {{ lang[this.appLang].selectDateRange }}
      </h5>
      <VueDatePicker
        v-model="rangeDate"
        range
        class="rangeDatePicker edit-input"
        color="#000000"
        format="DD MMM YYYY"
      />

      <h5 class="full-top">
        {{ lang[this.appLang].selectMarkers }}
      </h5>
      <multiselect
        v-model="markers"
        :options="options"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :placeholder="lang[this.appLang].selectMarkers"
        label="name"
        track-by="name"
        :preselect-first="true"
        selectLabel=""
        deselectLabel=""
        tagPlaceholder=""
        :taggable="true"
        style="width: 95%; margin: 0 auto; border-radius: 8px"
        class="full-bottom markerFilter"
        selectedLabel=""
      />
    </div>

    <div style="display: flex; justify-content: space-between">
      <!-- Reset -->
      <button
        @click="resetFilter"
        class="button white-button half-bottom small-top"
        style="margin: 0; width: auto; margin-left: 10px"
      >
        {{ lang[this.appLang].resetFilter }}
      </button>

      <!-- Save -->
      <button
        @click="saveFilter"
        class="button black-button half-bottom small-top"
        style="margin: 0; width: 62%; margin-right: 10px"
      >
        {{ lang[this.appLang].save }}
      </button>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import "@mathieustan/vue-datepicker/dist/vue-datepicker.min.css";
import Multiselect from "vue-multiselect";
import UserService from "@/services/UserService";

export default {
  components: {
    VueDatePicker,
    Multiselect,
  },
  data() {
    return {
      toggle: false,
      connectionType: "all",
      sortBy: "createdAt",
      sortDirection: "desc",
      rangeDate: this.createRangeDate(),
      markers: [],
      options: [],
    };
  },
  computed: {
    connectionTypeLabel() {
      return {
        all: this.lang[this.appLang].allConnectionsLabel,
        connected: this.lang[this.appLang].directConnectionsLabel,
        anonymous: this.lang[this.appLang].formConnectionsLabel,
        manually: this.lang[this.appLang].manualConnectionsLabel,
        scan: this.lang[this.appLang].scanConnectionsLabel,
      };
    },
  },
  mounted() {
    EventBus.$on("toggleFilterModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  methods: {
    saveFilter() {
      const { start, end } = this.rangeDate;

      EventBus.$emit("closeModal");
      EventBus.$emit("refreshConnections", {
        dateFrom: start,
        dateTo: end,
        type: this.connectionType,
        markers: this.markers.map((marker) => marker.id),
        sortBy: this.sortBy,
        sortDirection: this.sortDirection,
      });
    },
    resetFilter() {
      this.connectionType = "all";
      this.sortBy = "createdAt";
      this.sortDirection = "desc";
      this.rangeDate = this.createRangeDate();
      this.markers = [];
    },
    createRangeDate() {
      const start = this.$moment()
        .startOf("year")
        .subtract(1, "year")
        .format("YYYY-MM-DD");
      const end = this.$moment().format("YYYY-MM-DD");

      return {
        start,
        end,
      };
    },
    close() {
      EventBus.$emit("closeModal");
    },
    async toggleModal() {
      const {
        data: { markers },
      } = await UserService.getAllMarkers();

      this.options = markers;
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style>
.rangeDatePicker {
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 50px !important;
}
.rangeDatePicker .vd-picker__input {
  justify-content: center;
}
.vd-picker__input input {
  flex: unset;
  width: 220px;
  line-height: 30px;
}
.vd-picker__input-icon {
  margin-right: unset;
}

.sortBy,
.sortDirection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.connectionType {
  display: flex;
  justify-content: center;
  padding: 10px 10px 0;
}

.connectionType .active {
  background: black;
  color: white;
}

.connectionType span {
  border: 1px solid #e8e8e8;
  padding: 10px;
  cursor: pointer;
  flex: 1;
  background: white;
}

.connectionType span:not(:last-child) {
  border-right: none;
}

.connectionType span:first-child {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 15px;
}
.connectionType span:last-child {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 15px;
}
.sortOptions span {
  padding-top: 5px;
  padding-bottom: 5px;
}
.multiselect {
  width: auto !important;
  margin-top: 15px !important;
  margin-right: 10px !important;
  margin-left: 10px !important;
}

.markerFilter input {
  margin-left: 0 !important;
}
</style>
