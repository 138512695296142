import Api from './Api'

export default {
  register (data) {
    return Api().post('/users/register', data)
  },
  login (data) {
    return Api().post('/users/login', data)
  },
  logout (data) {
    return Api().post('/users/logout', data)
  },
  sendResetEmail (data) {
    return Api().post('/users/reset', data)
  },
  changePassword (data) {
    return Api().put('/users/password', data, {
      headers: { Authorization: 'Bearer ' + data.token }
    })
  },
  verify (data) {
    return Api().get('/users/verify?c=' + data.code + '&e=' + data.email)
  },
  googleUrl () {
    return Api().get('/users/google/url')
  },
  google (data) {
    return Api().post('/users/google', data)
  },
  facebook (data) {
    return Api().post('/users/facebook', data)
  },
  googleSDK (data) {
    return Api().post('/users/google/sdk', data)
  },
  facebookSDK (data) {
    return Api().post('/users/facebook/sdk', data)
  },
  appleSDK (data) {
    return Api().post('/users/apple/sdk', data)
  },
  microsoftSDK (data) {
    return Api().post('/users/microsoft/sdk', data)
  },
}
