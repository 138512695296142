<template>
  <div>
    <div id="rootAppId" style="position: relative">
      <!-- Loader with spinner -->
      <Loader :loading="loading" />

      <ModalOverlay />
      <Direct />
      <ProfileSetup />
      <Profile />
      <Password />
      <Ambassador />
      <Username />
      <Language />
      <DeleteAccount />
      <Upload />
      <QR />
      <QRScanner />
      <Link />
      <ContactCard />
      <GoogleReview />
      <Tag />
      <Connect />
      <AfterConnect />
      <Connection />
      <AddAccount />
      <SSO />
      <ForbiddenRule />
      <Header />
      <Sidebar />
      <ScanCropper/>
      <ConnectionsFilter />
      <FollowUpSchedule />
      <CookiePolicy />

      <router-view
        v-if="false && initialized && !isMaintenance && isOnline"
        style="padding-top: 55px !important"
        id="page-content"
        class="
          page-content page-content-scroll
          content-padding
          page-content-light
          header-clear-larger
        "
      />

      <pull-to
        v-if="initialized"
        :top-load-method="refresh"
        style="padding-top: 55px"
        :top-config="topConfig"
        :is-bottom-keep-scroll="true"
        :is-top-bounce="onTop"
        :is-bottom-bounce="false"
      >
        <router-view
          v-if="!isMaintenance && isOnline"
          id="page-content"
          class="
            small-top-padding
            page-content page-content-scroll
            content-padding
            page-content-light
            header-clear-larger
            no-top
          "
        />
        <ErrorMaintenance
          v-else-if="isOnline"
          id="page-content"
          class="
            small-top-padding
            page-content page-content-scroll
            content-padding
            page-content-light
            header-clear-larger
            no-top
          "
        />
        <ErrorOffline
          v-else-if="!isOnline"
          id="page-content"
          class="
            small-top-padding
            page-content page-content-scroll
            content-padding
            page-content-light
            header-clear-larger
            no-top
          "
        />
      </pull-to>
      <FooterNav />
      <!-- Snackbars Success / Error-->
      <div class="snackbar snackbar-boxed">
        <p class="bg-red-light snack-body" id="errorSnack">
          <i class="fa fa-warning"></i
          ><span id="errorMessage" style="font-weight: 500;"> {{ lang[this.appLang].error }} </span
          ><a @click="closeSnacks" class="pointer"><i class="fa fa-close font-15"></i></a>
        </p>
        <p class="bg-black snack-body" id="successSnack">
          <i class="fa fa-check"></i>
          <span id="successMessage" style="font-weight: 500;"> {{ lang[this.appLang].success }} </span>
          <a @click="closeSnacks" class="pointer"><i class="fa fa-close font-15"></i></a>
        </p>
      </div>
    </div>
    <QRScannerOverlay />
  </div>
</template>

<script>
/* eslint-disable no-undef */

import "../styles/font.css";
import "../styles/framework.css";
import "../styles/style.css";
import Loader from "./components/Loader";
import { mapState, mapMutations, mapActions } from "vuex";
import { Capacitor } from "@capacitor/core";
import PullToMixin from "./mixins/pull-to.mixin";
import Password from "./components/modals/Password";
import QRScanner from "./components/modals/QRScanner";
import Tag from "./components/modals/Tag";
import Link from "./components/modals/Link";
import Upload from "./components/modals/Upload";
import Header from "./components/Header";
import ModalOverlay from "./components/modals/ModalOverlay";
import QR from "./components/modals/QR";
import Profile from "./components/modals/Profile";
import ProfileSetup from "./components/modals/ProfileSetup";
import Sidebar from "./components/Sidebar";
import Connect from "./components/modals/Connect";
import AfterConnect from "./components/modals/AfterConnect";
import Connection from "./components/modals/Connection";
import ErrorMaintenance from "./views/Maintenance";
import { VueOfflineMixin } from "vue-offline";
import ErrorOffline from "./views/Offline";
import Username from "./components/modals/Username";
import Ambassador from "./components/modals/Referral";
import { StatusBar, Style } from "@capacitor/status-bar";
import FooterNav from "./components/FooterNav";
import Direct from "./components/modals/Direct";
import AddAccount from "./components/modals/AddAccount";
import { EventBus } from "./store/event-bus";
import ContactCard from "./components/modals/ContactCard";
import Language from "./components/modals/Language";
import DeleteAccount from "./components/modals/DeleteAccount";
import GoogleReview from "./components/modals/GoogleReview";
import UtilService from "./services/UtilService";
import SSO from "./components/modals/SSO";
import ForbiddenRule from "./components/modals/ForbiddenRule";
import ScanCropper from "./components/modals/ScanCropper";
import ConnectionsFilter from "./components/modals/ConnectionsFilter";
import FollowUpSchedule from "./components/modals/FollowUpSchedule";
import CookiePolicy from "./components/modals/CookiePolicy";
import QRScannerOverlay from '@/components/QRScannerOverlay';
import {LocalNotifications} from '@capacitor/local-notifications';
import { App } from '@capacitor/app';
import { CapacitorUpdater, VersionInfo } from '@capgo/capacitor-updater'
import CONSTANTS from '@/constants';

export default {
  name: "App",
  mixins: [PullToMixin, VueOfflineMixin],
  components: {
    SSO,
    GoogleReview,
    Language,
    ContactCard,
    AddAccount,
    Direct,
    FooterNav,
    Ambassador,
    Username,
    ErrorOffline,
    ErrorMaintenance,
    Connect,
    AfterConnect,
    Connection,
    Sidebar,
    Profile,
    ProfileSetup,
    Password,
    DeleteAccount,
    Tag,
    Link,
    QRScanner,
    Upload,
    Header,
    Loader,
    ModalOverlay,
    QR,
    ForbiddenRule,
    ScanCropper,
    ConnectionsFilter,
    FollowUpSchedule,
    CookiePolicy,
    QRScannerOverlay
  },
  computed: {
    ...mapState([
      "loading",
      "initialized",
      "isMaintenance",
      "loggedInAccounts",
    ]),
  },
  methods: {
    ...mapActions([
      "getUser",
      "getLinkTypes",
      "getLoggedInAccounts",
      "maintenance",
      "refreshTokenAction",
      "getCompanyBySubdomain",
    ]),
    ...mapMutations(["setToken", "setRefreshToken", "setInitialize"]),
    async init() {
      // Cookies config
      this.$cookies.config(60 * 60 * 24 * 365); // 1 year

      if (this.storage.username) {
        await this.getUser({ username: this.storage.username, login: true });
      }

      if (this.isB2B) {
        await this.getCompanyBySubdomain({
          subdomain: this.$store.getters.subdomain,
        });
      }


      setTimeout(() => {
        if (
          this.route.name &&
          this.route.name.includes("Home") &&
          !this.route.params.serial &&
          !this.route.params.username &&
          this.user.username === this.storage.username
        ) {
          this.$router.push(this.home);
        }
        this.$store.commit("setInitialize", true);
        this.$forceUpdate();
      }, 1000);

      // NFC event when the app is turned on
      document.addEventListener("onNewIntentNFC", (data) => {
        if (data.url) {
          const slug = data.url.split("tapni.co")[1];
          if (slug) {
            this.$router.push(slug + "#refresh");
          }
        }
      });

      if (this.isNative) {
        const statusInfo = await StatusBar.getInfo();
        if (statusInfo.color === "#000000") {
          await StatusBar.setStyle({ style: Style.Dark });
        } else {
          await StatusBar.setStyle({ style: Style.Light });
        }
      }

      if (this.route.hash === "#clear") {
        this.clearAppData();
      }

      if (process.env.VUE_APP_COMPARE_LANGUAGE_KEYS === "true") {
        UtilService.compareLangKeys();
      }

      this.$forceUpdate();
    },
    clearAppData() {
      // Remove all cookies
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie));

      // Refresh local storage
      this.storage.token = "";
      this.storage.refreshTokens = "";
      this.storage.username = "";
      this.storage.UserId = "";
      this.storage.verifyEmail = "";
    },
  },
  created() {
    LocalNotifications.addListener('localNotificationActionPerformed', (payload) => {
      let data = null;
      if(payload.notification.extra) data = payload.notification.extra
      if(payload.notification.data) data = payload.notification.data;
      if(data.username) return window.location.href = "/" + data.username;
      if(data.email) return window.location.href = "/connections?search=" + data.email;
      else return window.location.href = "/connections";
    });

    this.maintenance();

    if (this.storage && this.storage.token && this.storage.refreshTokens) {
      this.setToken(this.storage.token);
      this.setRefreshToken(this.storage.refreshTokens.split(",")[0]);

      this.getLoggedInAccounts(this.storage.refreshTokens.split(","));
    }

    if (this.isEmpty(this.linkTypes)) {
      this.getLinkTypes();
    }

  },
  async mounted() {
    if (this.route.name) this.init();


    EventBus.$on("switchAccount", async (username) => {
      this.$store.commit("setLoading", true);
      this.storage.username = username;
      this.storage.UserId = this.loggedInAccounts[username].id;

      const newRefreshToken = this.loggedInAccounts[username].refreshToken;
      let refreshTokensArray = this.storage.refreshTokens.split(",");
      refreshTokensArray = refreshTokensArray.filter(
        (t) => t !== newRefreshToken
      );
      this.storage.refreshTokens = refreshTokensArray.join(",");

      this.setRefreshToken(newRefreshToken);
      this.setInitialize(false);
      await this.refreshTokenAction({ id: this.loggedInAccounts[username].id });
      EventBus.$emit("closeModal");
      this.$router.push("/" + username);
      this.init();
    });


  },
  watch: {
    "route.name"(nv, ov) {
      if (!ov && nv) this.init();
    },
  },
};




CapacitorUpdater.notifyAppReady()
let version = null;
App.addListener('appStateChange', async (state) => {

  if(!Capacitor.isNativePlatform()) return;

  if(process.env.NODE_ENV !== 'production'){
    console.log("Autoupdater skipped.")
    console.log("Evnironment is: " + process.env.NODE_ENV )
    return;
  }

  if (state.isActive) {

    // Do the download during user active app time to prevent failed download
    if(!version) {
      version = await CapacitorUpdater.download({url: process.env.VUE_APP_API_ROOT + '/v1/updater/app?version=' + (CONSTANTS.WEB_VERSION || '0.0.0'), version: CONSTANTS.WEB_VERSION})
    }
  }

  if (!state.isActive && version) {
    // Activate the update when the application is sent to background
    try {
      await CapacitorUpdater.set(version);
      // At this point, the new version should be active, and will need to hide the splash screen
    } catch (err) {
      console.log(err)
    }
  }
})

</script>

<style>
.action-block-top .default-text,
.action-block-top .default-text:after {
  border-radius: 50%;
  width: 10em !important;
  height: 10em !important;
}
.action-block-top .default-text {
  margin: 10px auto;
  font-size: 3px;
  position: relative;
  text-indent: -9999em;
  /*
    border-left: 0.5em solid #ffffff;
    border-top: 0.5em solid rgba(255, 255, 255, 0.2);
    border-right: 0.5em solid rgba(255, 255, 255, 0.2);
    border-bottom: 0.5em solid rgba(255, 255, 255, 0.2);
    */
  border-left: 0.5em solid #000000;
  border-top: 0.5em solid rgba(0, 0, 0, 0.2);
  border-right: 0.5em solid rgba(0, 0, 0, 0.2);
  border-bottom: 0.5em solid rgba(0, 0, 0, 0.2);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.smartbanner-ios {
  background: linear-gradient(to bottom, #f4f4f4, #f4f4f4);
  box-shadow: 0 0px 0px #ffffff;
  border-bottom: 1px solid #dddddd;
}
.smartbanner-icon {
  border-radius: 16px;
}
.smartbanner-ios .smartbanner-button {
  font-size: 13px;
  padding: 9px 0px;
  height: 40px;
  border-radius: 13px;
  background: #f4f4f4;
  color: #0a99d2;
  border: none;
  box-shadow: none;
  text-transform: uppercase;
}

.snack-body {
  border-radius: 12px !important;
  font-size: 15px !important;
}
</style>
