<template>
  <div
    v-if="isLoggedIn && showNav && !isEmpty(user)"
    class="navbar"
    id="myNavbar"
  >
    <router-link :to="home.replace('#edit', '')" exact>
      <img
        v-if="$route.name && $route.name.includes('Home') && isLoggedInUser"
        src="https://cdn.tapni.co/icons/home-hover.png"
        class="responsive-image"
      />
      <img
        v-else
        src="https://cdn.tapni.co/icons/Home.png"
        class="responsive-image"
      />
    </router-link>
    <a
      v-if="isLoggedInUser"
      class="direct"
    >
      <div
        v-if="$route.name && $route.name.includes('Connections')"
        class="pointer"
        @click="openAddConnectionModal"
      >
        <img
          src="https://cdn.tapni.co/icons/Add.png"
          class="responsive-image"
          style="margin-top: 6px; margin-bottom: 15px; border-radius: 20px"
        />
      </div>
      <div v-else class="ios-switch" style="display: inline-block">
        <input
          v-model="user.is_direct"
          type="checkbox"
          name="ios-switch"
          class="ios-switch-checkbox"
          :id="'direct-switch'"
        />
        <label
          @click="updateDirectLink(!user.is_direct)"
          class="ios-switch-label"
          :class="{ 'direct-image': user.is_direct }"
          :for="'direct-switch'"
        ></label>
      </div>
    </a>
    <router-link
      v-else-if="isLoggedInUser && user.tags && user.tags.length === 0"
      to="/tags#activate"
      exact
      class="direct"
    >
      <img
        v-if="$route.name === 'Tags'"
        src="https://cdn.tapni.co/icons/Activate-hover.png"
        class="responsive-image"
        style="margin-top: 6px; margin-bottom: 15px"
      />
      <img
        v-else
        src="https://cdn.tapni.co/icons/Activate.png"
        class="responsive-image pulse"
        style="margin-top: 6px; margin-bottom: 15px; border-radius: 20px"
      />
    </router-link>
    <router-link
      v-else-if="isLoggedInUser && user.links && user.links.length === 0"
      to="/link/add"
      exact
      class="direct"
    >
      <img
        v-if="$route.name === 'LinkAdding'"
        src="https://cdn.tapni.co/icons/Add-hover.png"
        class="responsive-image"
        style="margin-top: 6px; margin-bottom: 15px"
      />
      <img
        v-else
        src="https://cdn.tapni.co/icons/Add.png"
        class="responsive-image pulse"
        style="margin-top: 6px; margin-bottom: 15px; border-radius: 20px"
      />
    </router-link>
    <span v-else-if="!isLoggedInUser">
      <a @click="connectHandler" class="direct pointer">
        <img
          v-if="
            (!isLoggedIn || (isLoggedIn && !isLoggedInUser)) &&
            (user.is_public || user.isTap) &&
            !user.connected
          "
          src="https://cdn.tapni.co/icons/Add.png"
          class="responsive-image"
          style="margin-top: 6px; margin-bottom: 15px; border-radius: 20px"
        />
        <img
          v-else-if="
            user.connected && $route.name && $route.name.includes('Home')
          "
          src="https://cdn.tapni.co/icons/EditConnection.png"
          class="responsive-image"
          style="margin-top: 6px; margin-bottom: 15px"
        />
      </a>
    </span>

    <router-link
      v-if="
        !isLoggedInUser ||
        (user.tags && user.tags.length > 0) ||
        (user.links && user.links.length === 0)
      "
      to="/connections"
      exact
    >
      <img
        v-if="$route.name && $route.name.includes('Connections')"
        src="https://cdn.tapni.co/icons/Connections-hover.png"
        class="responsive-image"
      />
      <img
        v-else
        src="https://cdn.tapni.co/icons/Connections.png"
        class="responsive-image"
      />
    </router-link>
    <router-link
      v-else-if="isLoggedInUser && user.tags && user.tags.length === 0"
      to="/tags#activate"
      exact
      class="direct"
    >
      <img
        v-if="$route.name === 'Tags'"
        src="https://cdn.tapni.co/icons/Activate-hover.png"
        class="responsive-image"
        style="margin-top: 6px; margin-bottom: 15px"
      />
      <img
        v-else
        src="https://cdn.tapni.co/icons/Activate.png"
        class="responsive-image pulse"
        style="margin-top: 6px; margin-bottom: 15px; border-radius: 20px"
      />
    </router-link>
  </div>
</template>

<script>
import { EventBus } from "../store/event-bus";
import { mapGetters, mapActions, mapState } from "vuex";
import UserService from "../services/UserService";

export default {
  name: "FooterNav",
  computed: {
    ...mapState(["loading", "affiliate"]),
    ...mapGetters(["directLink", "isLoggedIn"]),
    showNav() {
      if (
        this.isLoggedIn &&
        ((this.route.name && this.route.name.includes("Home")) ||
          [
            "Tags",
            "LinkAdding",
            "Connections",
            "Devices",
            "Ambassador",
            "Products",
          ].includes(this.route.name))
      )
        return true;
      return false;
    },
  },
  mounted() {
    this.applyCSS();
    EventBus.$on("closeModal", (picked) => {
      if (!this.directLink && !picked) {
        if (this.user) {
          this.user.is_direct = false;
        }
      }
    });
  },
  beforeUpdate: function () {
    this.applyCSS();
  },
  methods: {
    ...mapActions(["addOrUpdateLink", "connect", "getUser", "eventLog"]),
    openAddConnectionModal() {
      EventBus.$emit("toggleConnectionModal", {
        addConnection: true,
      });
    },
    affiliateFooterClick() {
      this.eventLog({
        type: "affiliate_buy_click",
        affiliateId: this.affiliate.id,
      });
      location.href = "https://tapni.co?ref=" + this.affiliate.ref_code;
    },
    applyCSS() {
      if (!this.loading) {
        const myNavbar = document.getElementById("myNavbar");

        let iconUrl = "https://cdn.tapni.co/icons/direct.png";
        if (this.directLink) {
          if (this.directLink.custom_icon) {
            iconUrl = `https://s3.eu-central-1.amazonaws.com/${this.cdnHost}/custom-icons/${this.directLink.id}`;
          } else {
            iconUrl = `https://cdn.tapni.co/icons/${
              this.directLink ? this.directLink.type : "direct"
            }.png`;
          }
        }

        let cssRule = `.direct-image:before {background-image: url("${iconUrl}")!important;`;
        let style = document.createElement("style");
        style.type = "text/css";
        style.className = "directLinkNavStyle";
        if (myNavbar) {
          const elements =
            document.getElementsByClassName("directLinkNavStyle");
          while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
          }

          myNavbar.appendChild(style);
          style.innerHTML = cssRule;
        }
      }
    },
    toggleConnectModal() {
      EventBus.$emit("toggleConnectModal");
    },
    async connectHandler() {
      if (!this.isLoggedIn) {
        this.toggleConnectModal();
      } else if (!this.user.connected) {
        EventBus.$emit("loadingHeader", true);
        await this.connect({
          UserId: this.user.id,
        });
        await this.getUser({ username: this.user.username });
        this.$forceUpdate();
        setTimeout(() => {
          EventBus.$emit("loadingHeader", false);
        }, 1000);
      } else {
        const response = await UserService.getConnection(
          this.user.connectionId
        );
        EventBus.$emit("toggleConnectionModal", response.data.connection);
      }
    },
    updateDirectLink(nv) {
      if (nv === true && !this.loading && this.isLoggedInUser) {
        EventBus.$emit("toggleDirectModal");
      }
      if (nv === false && this.directLink && !this.loading) {
        this.addOrUpdateLink({
          id: this.directLink.id,
          is_direct: false,
        });
      }
    },
  },
  watch: {
    loading() {
      this.applyCSS();
      this.$forceUpdate();
    },
    "user.is_direct"() {
      this.applyCSS();
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
/* Place the navbar at the bottom of the page, and make it stick */
.navbar {
  background-color: #ffffff;
  overflow: hidden;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 500px;
  padding-bottom: env(safe-area-inset-bottom);
  z-index: 9;
  -webkit-backface-visibility: hidden;
  border: 1px solid #e8e8e8;
  /*height: 62px;*/
}

/* Style the links inside the navigation bar */
.navbar a {
  float: left;
  display: block;
  color: #000000;
  text-align: center;
  padding: 3px 14px 0px 14px;
  text-decoration: none;
  font-size: 17px;
  width: 35%;
}

.navbar img {
  width: 35px;
  margin: 0 auto;
  margin-top: 6px;
}

.direct {
  float: left;
  width: 30% !important;
  margin: 0 auto;
}

.ios-switch {
  background-color: #ffffff;
  position: relative;
  width: 81px;
  padding-top: 4px;
  padding-right: 4px;
  margin-left: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.ios-switch-checkbox {
  display: none;
}
.ios-switch-label {
  background-color: #ffffff !important;
  display: block;
  overflow: initial;
  cursor: pointer;
  height: 45px;
  padding: 0;
  line-height: 20px;
  border: 1px solid #aaaaaa;
  border-radius: 25px;
  transition: all 250ms ease;
}
.ios-switch-label:before {
  content: "";
  display: block;
  width: 43px;
  margin: 0px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 33px;
  background-image: url("https://cdn.tapni.co/icons/direct.png");
  background-size: cover;
  border: 2px solid #aaaaaa;
  border-radius: 25px;
  transition: all 250ms ease;
}


.ios-switch-checkbox:checked + .ios-switch-label {
  background-color: #ffffff;
}
.ios-switch-checkbox:checked + .ios-switch-label,
.ios-switch-checkbox:checked + .ios-switch-label:before {
  border-color: #aaaaaa;
}
.ios-switch-checkbox:checked + .ios-switch-label:before {
  right: -1px;
}
</style>
