import Vue from 'vue'
import RootApp from './App'
import router from './router'
import store from './store/store'
import { sync } from 'vuex-router-sync'
import VueObserveVisibility from 'vue-observe-visibility'
import GlobalMixin from './mixins/global.mixin'
import VueCookies from 'vue-cookies'
import VueInputAutowidth from 'vue-input-autowidth'
import ReactiveStorage from 'capacitor-reactive-localstorage-vue'
import { App } from '@capacitor/app';
import VueTextareaAutogrowDirective from 'vue-textarea-autogrow-directive'
import VueMeta from 'vue-meta'
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import moment from 'moment';
import VueMoment from 'vue-moment';
import CONSTANTS from '@/constants';


require('moment/locale/en-gb');

moment.locale('en-gb');


Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})
Vue.use(VueInputAutowidth)
Vue.use(ReactiveStorage, {
  'token': '',
  'refreshTokens': '',
  'username': '',
  'UserId': '',
  'verifyEmail': '',
  'subdomain': ''
})

Vue.use(VueTextareaAutogrowDirective)
Vue.use(VueObserveVisibility)
Vue.use(VueMoment, { moment })

Vue.config.productionTip = false

// Cookies
Vue.use(VueCookies)

// Global Mixin
Vue.mixin(GlobalMixin)

sync(store, router)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(RootApp)
})

defineCustomElements(window);

function hasQueryParams(route) {
  return !!Object.keys(route.query).length
}

router.beforeEach((to, from, next) => {
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
  next()
})

/**
 * Redirect users to desired profile - DEEP LINKING
 * @param data
 */
function redirectToProfile(data) {
  if (data && data.url) {
    const slug = data.url.split('tapni.co').pop();
    const subdomain = data.url.split('.tapni.co').shift().replace('http://', '').replace('https://', '');
    if (slug) {
      router.push({
        path: slug,
        query: {
          s: subdomain
        }
      })
    }
  }
}
/**
 * CAPACITOR_CONFIG
 * Deep linking configuration
 * Users who have an app should get the profiles opened in the app ie: tapni.co/tapni
 */
App.addListener('appUrlOpen', async function (data) {
  redirectToProfile(data)
})

// When the app is open and you trigger NFC event to open the app
// App.addListener('appStateChange', async function (l) {
// 	const data = await App.getLaunchUrl()
// })

// When the app is closed and NFC event opens the app
App.getLaunchUrl().then((data) => {
  redirectToProfile(data)
});

