import to from 'await-to-js'
import AuthService from '../services/AuthService'
import {mapActions} from 'vuex'
import { FacebookLogin } from '@capacitor-community/facebook-login';
import {EventBus} from "../store/event-bus";

window.fbAsyncInit = function () {
  // eslint-disable-next-line no-undef
  FB.init({
    appId: '202577611527680',
    cookie: true, // enable cookies to allow the server to access the session
    xfbml: true, // parse social plugins on this page
    version: 'v9.0' // use graph api current version
  })
};

// Load the SDK asynchronously
(function (d, s, id) {
  // eslint-disable-next-line one-var
  var js, fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) return
  js = d.createElement(s); js.id = id
  js.src = 'https://connect.facebook.net/en_US/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
}(document, 'script', 'facebook-jssdk'))

export default {
  data () {
    return {
      facebookLoad: false
    }
  },
  computed: {
    fbLoginUrl () {
      return 'https://www.facebook.com/v9.0/dialog/oauth?client_id=202577611527680&state={"oauth":"facebook"}&response_type=code%20granted_scopes&scope=email&redirect_uri=' + process.env.APP_ROOT + '/login'
    },
    displayFacebookLogin () {
        return (this.company?.login?.facebook_login && !this.isModal) ?? true;
    }
  },
  methods: {
    ...mapActions(['loginSetup', 'getLoggedInAccounts']),
    async facebookLogin () {
      this.facebookLoad = true
      const FACEBOOK_PERMISSIONS = ['email']
      const [errAuth, user] = await to(FacebookLogin.login({permissions: FACEBOOK_PERMISSIONS}))
      if (errAuth) return this.facebookLoad = false
      if (user.accessToken) {
        const [err, response] = await to(AuthService.facebookSDK(user))
        if (err) {
          this.facebookLoad = false
          this.$store.commit('setLoading', false)
          return this.errorHandler(err)
        }
        if (response.data.success) {
          this.loginSetup(response)
	      this.getLoggedInAccounts(this.storage.refreshTokens.split(','))
          this.$router.push('/' + response.data.data.username + '#edit')
          setTimeout(() => {
            if (response.data.message === 'SUCCESS_REGISTER') {
              EventBus.$emit('openModalOverlay')
              EventBus.$emit('toggleUsernameModal')
            }
            this.facebookLoad = false
            this.$store.commit('setLoading', false)
          }, 1000)
        } else this.errorSnack(this.lang[this.appLang].unexpected_err)
      } else {
        this.facebookLoad = false
        this.$store.commit('setLoading', false)
      }
    }
  }
}
