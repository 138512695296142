import {EventBus} from '@/store/event-bus';

let initMetaPixelAnalytics = function(pixelID){
  if (!pixelID) return;
  fbq('init', pixelID);
  fbq('track', 'PageView');
}

let initGoogleAnalytics = function(googleID) {
  if (!googleID) return;
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${googleID}`;
  script.onload = () => {
    gtag('js', new Date());
    gtag('config', googleID);
  }
  document.head.appendChild(script);
}

let checkAnalyticsCookies = function(data){
  const cookiesStatus = localStorage.getItem('analyticsCookie');

  // if is no coockieStatus in localStorage (the most common case when the policy is displayed for the first time)
  if(!cookiesStatus) return EventBus.$emit('toggleCookieModal')

  // if the cookie policy has already been accepted
  if(cookiesStatus.startsWith('accepted')) {
    let acceptedAt = parseInt(cookiesStatus.split('-')[1])

    // if it was accepted more than 6 months ago, reset analyticsCookie and show policy again
    if(Math.round(Date.now() / 1000) - acceptedAt > 15780000 ){
      localStorage.removeItem('analyticsCookie');
      EventBus.$emit('toggleCookieModal')
    }
    else {
      initMetaPixelAnalytics(data.metapixelAnalytics);
      initGoogleAnalytics(data.googleAnalytics);
    }
    return;
  }
  // if the cookie policy has already been declined
  if(cookiesStatus.startsWith('declined')) {
    let declinedAt = parseInt(cookiesStatus.split('-')[1])
    // if declined 3 days ago, reset analyticsCookie and show cookie policy again
    if(Math.round(Date.now() / 1000) - declinedAt > 259200){
      localStorage.removeItem('analyticsCookie');
      EventBus.$emit('toggleCookieModal')
    }
  }
}

let acceptAnalyticsCookies = function(data){
  initMetaPixelAnalytics(data.metapixelAnalytics);
  initGoogleAnalytics(data.metapixelAnalytics);
}

export {initMetaPixelAnalytics, initGoogleAnalytics,checkAnalyticsCookies, acceptAnalyticsCookies}
