<template>
  <div class="scanner-container" id="scannerOverlay" style="display: none;">
    <div class="scanner--area--container">
      <div class="relative">
        <p>Aim your camera at a QR code</p>
      </div>
      <div class="square surround-cover">
        <div class="scanner--area--outer surround-cover">
          <div class="scanner--area--inner"></div>
        </div>
      </div>
    </div>
    <button class="button white-button full-top pointer cancel-scanner" @click="closeScanner">
      {{ lang[this.appLang].cancel }}
    </button>
  </div>
</template>

<script>
import { EventBus } from "../store/event-bus";
export default {
  name: 'QRScannerOverlay.vue',
  methods:{
    closeScanner(){
      EventBus.$emit("closeNativeScanner");
    }
  }
}
</script>

<style scoped>

* {
  box-sizing: border-box;
}
p {
  color: #fff;
  font-family: sans-serif;
  text-align: center;
  font-weight: 600;
}
html,
body,
.scanner-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.scanner-container {
  display: flex;
}
.relative {
  position: relative;
  z-index: 1;
}
.square {
  width: 100%;
  position: relative;
  overflow: hidden;
  transition: 0.3s;
}
.square:after {
  content: '';
  top: 0;
  display: block;
  padding-bottom: 100%;
}
.square > div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.surround-cover {
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
}
.scanner--area--container {
  width: 80%;
  max-width: min(500px, 80vh);
  margin: auto;
}
.scanner--area--outer {
  display: flex;
  border-radius: 1em;
}
.scanner--area--inner {
  width: 100%;
  margin: 1rem;
  border: 2px solid #fff;
  box-shadow: 0px 0px 2px 1px rgb(0 0 0 / 0.5),
  inset 0px 0px 2px 1px rgb(0 0 0 / 0.5);
  border-radius: 1rem;
}

.cancel-scanner {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

@keyframes shake {
  0% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
  20% {
    transform: translate(5px, 5px) rotate(-1deg) scale(1.05);
  }
  40% {
    transform: translate(5px, 5px) rotate(-2deg) scale(1.07);
  }
  60% {
    transform: translate(2px, 2px) rotate(0deg) scale(1.04);
  }
  80% {
    transform: translate(-1px, -1px) rotate(-2deg) scale(1.05);
  }
  100% {
    transform: translate(0, 0) rotate(0deg) scale(1);
  }
}
</style>
