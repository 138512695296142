export default {
  state: {
    english: "Inglés",
    spanish: "Español",
    german: "Alemán",
    korean: "Koreano",
    italian: "Italiano",
    french: "Francés",
    serbian: "Serbio",
    turkish: "Turco",
    app_language: "Idioma",
    profile_preview: "Ver perfil",
    edit_profile: "Editar perfil",
    login: "Ingresar",

    referral_reach: "Proporciónanos mas información acerca de ti y tu alcance.",
    referral_apply_placeholder: "Cómo planeas promover Tapni?",
    referral_apply_note:
      "Agrega todos los links que tengas en tu cuenta, <br>\n" +
      "\t\t  para que podamos conocerte.\n" +
      "\t\t  <br><br>Programa exclusivo.\n" +
      "\t\t  <br>No todas las solicitudes son aceptadas.",
    referral_apply_now: "Aplica ahora",
    referral_apply_thanks:
      "Gracias por aplicar. Revisaremos tu solicitud pronto.",
    connect: "Añadir",
    exchange_contact: "Contacto de intercambio",
    add_details: "Añadir más",
    show_details: "Mostrar más",
    download_contact: "descargar contacto",
    stay_in_touch: "Manténgase en contacto con",
    no_thanks: "No, gracias",
    connect_join: "Unirse a Tapni",
    connect_with: "Añadir a",
    connect_required_fields:
      "Por favor ingresa al menos uno de los campos de contacto",
    name: "Nombre",
    first_name: "Nombre",
    last_name: "Apellido",
    email: "Email",
    phone: "Numero de teléfono",
    phone_p: "Teléfono",
    company: "Empresa",
    address: "Dirección",
    website: "Página web",
    open: "Abrir",
    delete: "Borrar",
    confirm: "Confirmar",
    cancel: "Cancelar",
    save: "Guardar",
    code: "Código",
    note: "Nota",
    note_p: "Añadir nota",
    contact_card_icon:
      "Ingresa el texto que aparece bajo la tarjeta de contacto",
    contact_card_name: "Ingresa el nombre para la tarjeta de contacto",
    contact_card_name_p: "Nombre del contacto",
    contact_card_email: "Ingresa el email para la tarjeta de contacto",
    contact_card_email_p: "Email del contacto",
    contact_card_phone: "Ingresa el teléfono para la tarjeta de contacto",
    contact_card_phone_p: "Teléfono del contacto",
    contact_card_website: "Ingresa el sitio web para la tarjeta de contacto",
    contact_card_website_p: "Sitio web de la tarjeta de contacto",
    contact_card_company_name:
      "Introduzca el nombre de la empresa para la tarjeta de contacto",
    contact_card_company_name_p:
      "Nombre de la empresa de la tarjeta de contacto",
    contact_card_job_title: "Cargo",
    contact_card_job_title_p: "Introduzca el título del trabajo",
    contact_card_company_website: "Página web de la empresa",
    contact_card_company_website_p: "Introduzca el sitio web de la empresa",
    contact_card_bio: "Ingresa la descripción para la tarjeta de contacto",
    contact_card_bio_p: "Descripción del contacto",
    contact_card_home_address:
      "Ingresa la dirección de casa para la tarjeta de contacto",
    contact_card_home_address_p: "Dirección de casa de la tarjeta de contacto",
    contact_card_work_address:
      "Ingresa la dirección de trabajo de la tarjeta de contacto",
    contact_card_work_address_p:
      "Dirección de trabajo de la tarjeta de contacto",
    contact_card_social:
      "Ingresa los enlaces de tus redes sociales a tu perfil de Tapni <br>para agregarlos a tu tarjeta de contacto",
    contact_card_social_p: "Selecciona las redes sociales que quieres incluir",

    link_text_p: "Descripción del Enlace",
    link_icon: "Ingresa el texto que aparece bajo el enlace",

    direct_pick: "Escoge un enlace directo",
    direct_p:
      "Cuando uses tus productos Tapni, <br> se abrirá éste enlace en vez de tu perfil completo.",

    password_change: "Cambiar contraseña",
    password_repeat: "Vuelve a ingresar tu contraseña",
    password_reset_t: "Restablecer contraseña",
    password_current: "Ingresa tu contraseña actual",
    password_current_p: "Contraseña actual",
    password_new: "Ingresa tu nueva contraseña",
    password_new_p: "Nueva contraseña",
    password_new_repeat: "Vuelve a ingresar tu nueva contraseña",
    password_new_repeat_p: "Vuelve a ingresar tu nueva contraseña",
    password_update: "Actualizar contraseña",
    password_reset:
      "Si no recuerdas tu contraseña actual,<br>\n" +
      "\t\t  puedes restablecerla ",
    by_clicking_here: "haciendo click aquí",
    password_8_chars: "La contraseña debe contener al menos 8 caracteres",
    password_not_match: "Las contraseñas no coinciden",
    password_change_success: "Cambio de contraseña exitoso",

    or: "o",
    profile: "Perfil",
    profile_public: "Perfil público",
    profile_public_p: "Tu perfil está disponible públicamente a través de: ",
    profile_active: "Perfil activo",
    profile_active_p:
      "Si desactivas tu perfil, nadie podrá acceder a él y tus tags no funcionarán.",
    profile_change_password: "Cambiar Contraseña",
    profile_change_username: "Cambiar Nombre de Usuario",
    profile_change_language: "Cambiar Idioma",
    profile_delete_account: "Borrar la cuenta",
    profile_confirm_delete_account: "Borrar la cuenta",
    profile_cancel_delete_account: "Cancelar",
    profile_delete_account_success: "La cuenta ha sido eliminada con éxito",
    profile_remove_p:
      "Si quieres eliminar tu cuenta,<br>\n" + "\t\t    por favor escríbenos a",
    profile_contact_support:
      "Si desea eliminar su cuenta,<br>\n" + "\t\t    escríbanos a",
    profile_email_subject: "Tapni App Support",
    profile_remove_subject: "Solicitud de Eliminación de Cuenta",
    profile_update_success: "Perfil actualizado exitosamente",
    profile_complete: "Completa tu perfil",
    completed: "Completo",
    uncompleted: "Incompleto",
    contact_us: "contáctanos",
    contact_us_verify: "para verificar tu correo electrónico.",
    verify_email: "Verificar Correo Electrónico",
    verify_email_subject: "Verificacion de Correo Electrónico",
    verify_email_inbox: "Revisa tu correo para",

    add_first_link: "Agrega tu primer enlace",
    add_first_link_p:
      "Haz click aquí para añadir tus redes sociales <br>\n" +
      "\t\t\t  o información de contacto a tu perfil de Tapni.",
    add_picture: "Agrega una foto de perfil",
    add_picture_p: "Haz click aquí para subir tu foto de perfil.",
    add_tag: "Activa un producto Tapni",
    add_tag_p: "Haz click aquí para activar tu tag NFC de Tapni.",
    completing_profile:
      "Tienes problemas completando tu perfil?<br>\n" +
      "\t\t    Escríbenos a  ",
    completing_profile_subject: "Completar perfil",
    share_profile: "Compartir perfil",
    share_profile_p:
      "Otras personas pueden escaner este código QR <br> para ver tu perfil",
    share_profile_change_username:
      "Si quieres cambiar el enlace de tu perfil, <br> <u>haz click aquí</u> para cambiar tu nombre de usuario.",
    scan_qr_code: "Escanear código QR",
    enter_activation_code: "Ingresa el código de activación",
    activate_tag: "Activa tu tag",
    order_tapni:
      "Si aún no tienes un producto Tapni,<br>\n" +
      "          puedes comprar uno ",
    activation_success: "Tag añadido exitosamente. <br> Todo está listo! 🥳",
    activation_success_message: "Tag añadido exitosamente. Todo está listo! 🥳",
    activation_error:
      "El tag que ingresaste no es válido. <br> Intenta de nuevo.",
    activation_error_message:
      "El tag que ingresaste no es válido. <br> Intenta de nuevo.",
    activation_progress: "Registro de tag en proceso...",
    activation_invalid_code: "Código de activación inválido.",
    activation_qr_failed: "Error al escanear código QR",
    activation_qr_area: "Coloca el código QR dentro del area de escaneo",
    activation_code: "Código de activación",
    invalid_code: "Código inválido",
    total_taps: "Taps totales",
    active: "Activo",
    tag_active_p:
      "Si lo desactivas, no abrirá tu perfil <br> cuando alguien lo escanee con su teléfono.",
    tag_delete_confirm:
      "Guarda el código de activación antes de eliminar el tag,<br> de otra manera no podrá ser reactivado.",

    upload_profile_picture: "Cargar Foto de Perfil",
    upload_cover_picture: "Cargar Foto de Portada",
    upload_link_picture: "Cargar Imagen para el Enlace",
    select_from_device: "Seleccionar de tu dispositivo",
    select_from_mobile: "Selecciona la imagen de tu dispositivo",
    select_from_computer: "o arrástrala si estás usando una computadora",
    crop: "Recortar",
    crop_upload: "Recortar y Cargar",
    crop_image: "Recortar la imagen",
    drag_and_drop: "Arrastra el archivo aquí",
    upload_success: "Imagen cargada exitosamente",
    browser_not_supported: "Tu navegador no soporta",
    change_username: "Cambiar nombre de usuario",
    change_username_success: "Cambio de nombre de usuario exitoso",
    update_username: "Actualizar nombre de usuario",
    new_username: "Ingresa tu nuevo nombre de usuario",
    username_check: "Por favor ingresa un nombre de usuario apropiado",
    anonymous: "Anónimo",
    connected: "Conectado",
    exchanged: "Intercambiado",
    accept: "Aceptar",
    view: "Ver",
    join: "Unirse",
    add_account: "Añadir cuenta",
    complete_profile: "Completar perfil",
    my_profile: "Mi perfil",
    my_qr: "Mi QR",
    my_connections: "Mis conexiones",
    my_tags: "Mis tags",
    buy_tapni: "Comprar Tapni",
    referral_program: "Programa de embajadores",
    device_compatibility: "Compatibilidad",
    help: "Ayuda",
    settings: "Configuración",
    logout: "Salir",
    version: "Versión",
    configure: "Configurar",
    unexpected_err:
      "Ha ocurrido un error, por favor contactanos en support@tapni.co",
    success_login: "Has iniciado sesión con otra cuenta exitosamente.",
    password_reset_success:
      "Se ha enviado un código para restablecer la contraseña a tu correo.",
    password_reseted_success:
      "Cambio de contraseña exitoso. Ya puedes iniciar sesión.",
    success_remove_link: "Enlace eliminado exitosamente",
    profile_active_message: "Tu perfil ha sido activado",
    profile_inactive_message: "Tu perfil ha sido desactivado",
    profile_public_message: "Tu perfil ahora es público",
    profile_private_message: "Tu perfil ahora es privado",
    redirect_enabled: "Enlace de auto-redirect activado",
    redirect_disabled: "Enlace de auto-redirect desactivado",
    tag_active: "El tag ha sido activado. Puedes comenzar a usarlo!",
    tag_inactive:
      "El tag ha sido desactivado. Tus visitas irán directo a tapni.co",
    tag_removed: "Tag eliminado exitosamente",
    connection_accepted: "Solicitud de conexión aceptada.",
    not_found: "No encontrado",
    not_found_p: "Esta página no existe",
    go_back: "Regresar",
    referral_p1: "Gana el 20% de comisión<br> en las ventas que refieras 💰",
    referral_p2: "Regala un 20% de descuento<br> con tu código de descuento 🎁",
    referral_p3: "Todos salen ganando 🤝",
    referral_p4:
      "Si quieres cambiar tus ventas referidas,<br>\n" +
      "\t\t\t\t  o si tienes alguna duda,<br>\n" +
      "\t\t\t\t  escríbenos en",
    referral_p5: "Empieza a ganar dinero ahora!",
    referral_p66: "Comparte tu código de descuento y gana dinero!",
    referral_p6:
      "Si eres blogger, influencer o te encantan las redes sociales, eres ideal para nuestro Programa de Embajadores.\n" +
      "\t\t\tPromueve nuestra marca y gana un 20% de comisión en las ventas que generes a través de tu código de descuento.",
    referral_p7:
      "Una vez que te registres en el Programa de Embajadores de tapni, recibirás un código de descuento personalizado.\n" +
      "\t\t\t\tCompártelo en tu página y redes sociales, y gana dinero mientras nosotros creamos una nueva experiencia de conexión.",
    referral_p8: "Nota de Pago",
    referral_p9:
      "Paypal (Mundial) y Transferencias Bancarias (Europa) <br> La cantidad mínima de pago es de 25€.",
    referral_p10: "Solicitar Pago",
    referral_p11: "Pago Solicitado",
    your_connections: "Tus conexiones",
    connections_p1: "Ponte en contacto con las personas que conociste",
    connections_p2:
      "<br><br>No tienes ninguna conexión. <br> Comienza a usar Tapni!",
    connections_p3: "Ninguna conexión encontrada.",
    connection_success: "Solicitud de conexión enviada exitosamente",
    exchange_success: "Intercambio de contactos con éxito.",
    create_free_account:
      "Crea una cuenta gratuita en Tapni y mantén tus contactos actualizados!",
    connection_edit: "Editar conexión",
    connection_edit_success: "Conexión actualizada con éxito",
    connection_delete_success: "Conexión eliminada con éxito",
    search: "Buscar",
    devices_p1: "Lista de dispositivos compatibles",
    devices_p2:
      "Tapni usa tecnología compatible con la mayoría de los iPhones nuevos y dispositivos Android.",
    devices_p3:
      "Algunos dispositivos Android pueden tener NFC desactivado. Si tu Tapni no funciona en un dispositivo Android, busca NFC en configuración y asegúrate de que esté encendido.",
    devices_p4: "con el widged de NFC en el centro de control de iOS 14",
    download_p1: "Descarga la aplicación Tapni",
    download_p2: "Disponible para dispositivos Apple y Android",
    user_not_found: "Usuario no encontrado",
    user_not_found_p:
      "No pudimos encontrar el usuario en nuestra base de datos 🤷",
    taps: "Interacciones",
    private_profile: "Perfil privado",
    private_profile_p:
      "es un perfil privado. <br> El perfil es visible usando tags de Tapni solamente ",
    profile_bio_p: "Escribe algo acerca de tí o tu marca",
    add_to_contacts: "Agregar a contactos",
    drag_and_drop_links: "Arrastra tus enlaces en el orden que quieras",
    no_links: "No hay enlaces en tu perfil.",
    add_link: "Añadir enlace",
    direct_link_p1: "Tus visitantes serán redireccionados automáticamente a:",
    profile_not_active:
      "Tu perfil está desactivado <br> y sólo tú lo puedes ver.",
    profile_not_public:
      "Tu perfil no es público <br> y solo lo podrán ver las personas que escaneen tu tag.",
    activate_profile_now: "Activa tu perfil ahora",
    publish_profile_now: "Haz tu perfil público ahora",
    link_add_p1: "Escoge un tipo de enlace",
    link_add_not_found: "Ningún enlace encontrado 🤷",
    link_add_not_found_p2:
      "Si no encuentras tu plataforma favorita, mándanos un correo (<b>support@tapni.co</b>) o envíanos un mensaje en nuestras redes sociales (<b>@tapni.co</b>)",
    link_add_not_found_p3:
      "Haremos lo posible para incluir todas las plataformas 😉",
    featured: "Destacado",
    social_media: "Redes Sociales",
    contact: "Contacto",
    finance: "Finanzas",
    portfolio: "Portafolio",
    music: "Música",
    business: "Negocios",
    fun: "Diversión",
    ecommerce: "E-commerce",
    more: "Más",
    email_username: "Correo / Nombre de Usuario",
    username: "Nombre de Usuario",
    password: "Contraseña",
    sign_in: "Iniciar sesión",
    sign_in_with: "Iniciar sesión con",
    sign_in_with_new: "Iniciar sesión con una nueva cuenta",
    sign_up_with: "Registrarse con",
    verify_account: "Verificar cuenta",
    terms_by_signing_in: "Al iniciar sesión, aceptas nuestros",
    terms_by_registering: "Al registrarte, aceptas nuestros",
    terms_by_submitting:
      "Al enviar este formulario, usted acepta nuestras condiciones de servicio",
    terms_of_service: "Términos de Servicio",
    and: "y",
    refresh: "Actualizar",
    privacy_policy: "Política de Privacidad",
    create_account: "Crear cuenta",
    reset_password: "Restablecer contraseña",
    reset_password_p1:
      "Si no pudiste restablecer tu contraseña,<br> contáctanos en ",
    reset_password_p2:
      "Si no pudiste restablecer tu contraseña,<br> contáctanos en",
    create_new_account: "Crear una cuenta nueva",
    maintenance_p1: "Estaremos de regreso pronto",
    maintenance_p2: "Mientras tanto, puedes explorar nuestros productos",
    maintenance_p3:
      "Tapni está en un mantenimiento programado <br> y estará de regreso en unos minutos.",
    offline_p1: "Sin conexión a Internet",
    offline_p2: "Checa tu conexión, y actualiza la página",
    offline_p3_1: "Mientras estés desconectado,",
    offline_p3_2:
      "puedes compartir tu tarjeta de contacto utilizando este código QR",
    products_p1:
      "Impresiona a todas las personas que conozcas 😎 <br> Compra el tuyo ahora 👇",
    cards: "Tarjetas",
    stickers: "Stickers",
    keychains: "Llaveros",
    explore_collection: "Explora nuestra colección",
    register: "Registrarse",
    register_success:
      "Ya casi terminas. Por favor confirma tu correo electrónico.",
    register_success_modal: "Has iniciado sesión con otra cuenta exitosamente.",
    check_your_inbox: "Checa tu bandeja de entrada",
    tapni_tags: "Tags Tapni",
    tapni_tags_p1: "Configura los tags conectados a tu perfil",
    tapni_tags_p2:
      "No hay ningún tag conectado a tu perfil. <br> Conecta uno ahora!",
    verify_account_p1:
      "Si no recibiste un código, checa tu <br> folder de spam o contáctanos en",
    verify_account_success:
      "Cuenta verificada exitosamente. Por favor cambia tu contraseña ahora.",
    verify_account_success2:
      "Cuenta verificada exitosamente. Todo está listo! 🥳",
    welcome: "Te damos la bienvenida",
    welcome_p1: "al futuro de las tarjetas de presentación",
    how_it_works: "Cómo funciona",
    already_registered: "Ya estás registrado",
    error: "Error",
    success: "Éxito",
    copied: "Copiado al portapapeles",
    connect_validate:
      "Por favor ingresa al menos uno de los campos de contacto",
    connect_validate_name: "Por favor ingresa tu nombre",
    connect_validate_email:
      "Por favor, introduzca una dirección de correo electrónico válida",
    link_validate: "Datos del enlace inválidos.",
    link_text_contact_card: "Tarjeta de contacto",
    link_text_custom_link: "Enlace personalizado",
    link_text_menu: "Menu",
    link_text_phone: "Teléfono",
    save_to_wallet: "Guardar en Apple Wallet",
    card: "Tarjeta",
    sticker: "Sticker",
    keychain: "Llavero",
    band: "Banda",
    case: "Caso",
    standee: "Standee",
    decoded: "Decoded",
    wristbanditz: "Wristbanditz",
    update_p1: "Versión de la aplicación obsoleta detectada",
    update_p2: "Actualizar",
    update_p3: "Actualice la aplicación a la versión más reciente",

    company_domain: "El dominio de su empresa",
    search_company_domain: "Busca el dominio de tu empresa",
    company_not_found: "Empresa no encontrada",
    ik_company_domain: "Conozco el dominio de la empresa",
    idk_company_domain: "No conozco el dominio de la empresa",
    continue: "Continuar",
    forbidden_rule:
      "Esta opción está deshabilitada, comuníquese con el administrador de su empresa.",
    companyName: "Nombre de la empresa",
    createMarkerPlaceholder: "Crear o seleccionar un marcador",
    clickToCreateMarker: "Haga clic aquí para crear un marcador",
    pleaseInviteUser:
      "Invite al usuario a unirse a Tapni para tener los datos sincronizados con usted.",
    connectionSynchronized:
      "Los datos de conexión se sincronizan automáticamente.",
    viewProfile: "Ver perfil",
    createConnectionHeader: "Añadir nueva conexión",
    createConnectionButton: "Añadir",
    createConnectionSuccess: "Se ha creado la conexión.",
    allow_company_management: "Permitir a la dirección de la empresa",
    company_join_request:
      "solicita gestionar su perfil y asociarlo a su cuenta de empresa",
    account_ownership_transfer:
      "La propiedad de tu cuenta se transferirá a la empresa si aceptas la solicitud. La empresa podrá modificar tu perfil, editar y añadir enlaces, ver tus conexiones, modificar tu contraseña, eliminar tu cuenta, etc. Asegúrate de consultar con tu empresa antes de aceptar la solicitud.",
    request_expired:
      "Der Antrag ist abgelaufen. Bitten Sie Ihr Unternehmen, einen neuen Antrag zu stellen.",
    request_expiration_desc:
      "Para mayor seguridad, la solicitud sólo funcionará durante 14 días después de su envío.",
    invitation_link_not_valid: "El enlace de invitación no es válido.",
    invitation_link_not_valid2:
      "Vuelva a hacer clic en el botón del correo electrónico de invitación o copie el enlace de invitación.",
    accept_request: "Aceptar la solicitud",
    close: "Cerrar",
    request_accepted: "La solicitud ha sido aceptada con éxito",
    companyEmail: "Correo electrónico de la empresa",
    companyPhone: "Teléfono de la empresa",
    addBusinessInformations: "Añadir información de la empresa",
    resetFilter: "Restablecer",
    selectMarkers: "Seleccionar marcadores",
    filterConnectionsHeader: "Conexiones del filtro",
    selectConnectionType: "Seleccione el tipo de conexión",
    all: "Todo",
    direct: "Directo",
    form: "Formulario",
    manually: "Manualmente",
    scan: "Escáner",
    sortOptions: "Opciones de clasificación",
    date: "Fecha de creación",
    fullName: "Nombre completo",
    descending: "Descendiendo",
    ascending: "Ascendente",
    selectDateRange: "Seleccione el rango de fechas",
    follow_up_reminder: "Recordatorio de seguimiento",
    now: "ahora",
    one_hour: "1 Stunde",
    one_day: "1 Día",
    three_days: "3 Días",
    one_week: "1 Semana",
    two_weeks: "2 Semanas",
    one_month: "1 Mes",
    custom: "Elija la hora",
    set_reminder: "Establecer recordatorio",
    select_date_time: "Seleccione la fecha y la hora",
    enter_reminder_text: "Introduzca el texto del recordatorio",
    reminder_created: "Recordatorio creado con éxito",
    connectionNameLabel: "Introduzca el nombre de la conexión",
    connectionEmailLabel:
      "Introduzca la dirección de correo electrónico privada",
    privatePhoneLabel: "Introduzca el número de teléfono privado",
    companyNameLabel: "Introduzca el nombre de la empresa",
    businessEmail: "Dirección de correo electrónico de la empresa",
    businessEmailLabel:
      "Introduzca la dirección de correo electrónico de la empresa",
    businessPhone: "Número de teléfono de la empresa",
    businessPhoneLabel: "Introduzca el número de teléfono de la empresa",
    noteLabel: "Introduzca la nota",
    markersLabel: "Añadir marcadores de conexión",
    showScan: "Mostrar escaneo",
    hideScan: "Ocultar la exploración",
    create_connection: "Añadir conexiones",
    create_manually: "Añadir manualmente",
    create_using_scanner: "Añadir usando el escáner de tarjetas",
    companyTitle: "Título de la empresa",
    companyTitleLabel: "Introduzca el título de la empresa",
    allConnectionsLabel: "Todos los tipos de conexión",
    directConnectionsLabel:
      "Conexión directa en la aplicación con los usuarios de Tapni",
    formConnectionsLabel: "Conexiones rellenadas en el formulario de conexión",
    manualConnectionsLabel: "Conexiones creadas manualmente",
    scanConnectionsLabel: "Conexiones creadas por el escáner de tarjetas",
  },
};
