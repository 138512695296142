<template>
  <form
    @submit.prevent="saveLink"
    class="
      menu-wrapper menu-light menu-modal
      center-text
      activate-page
      no-padding
    "
    :class="{ 'active-menu': toggle }"
    :style="`height: auto; margin-top: -172.5px;`"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 20px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>

    <h3 class="modal-title">{{ lang[this.appLang].link_text_contact_card }}</h3>

    <div
      class="contactcard"
      style="overflow-y: scroll; max-height: calc(92vh - 160px); width: 100%"
    >
      <div style="display: flex; width: 90%; margin: 0 auto">
        <img
          v-if="data"
          :src="'https://cdn.tapni.co/icons/' + data.type + '.png'"
          class="link-img half-bottom"
          :alt="data.name"
        />

        <div style="display: flex; flex-direction: column; width: 100%">
          <input
            v-if="data"
            id="linkText"
            type="text"
            v-model="data.desc[appLang].short"
            :placeholder="lang[this.appLang].link_text_p"
            style="width: 100% margin-top: 20px; margin-bottom: 5px;"
            class="edit-input h-50 lh-50"
          />
          <p
            class="close-text close-menu"
            style="text-align: left !important; margin-left: 5px"
          >
            {{ this.lang[this.appLang].link_icon }}
          </p>
        </div>
      </div>
      <div
        style="
          width: 90%;
          display: flex;
          flex-direction: column;
          margin: 0 auto;
        "
      >
        <input
          v-if="data"
          id="vcardFirstName"
          type="text"
          v-model="vcardFirstName"
          :placeholder="lang[this.appLang].first_name"
          class="edit-input h-50 lh-50 half-bottom"
          style="width: 100%"
        />
        <input
          v-if="data"
          id="vcardLastName"
          type="text"
          v-model="vcardLastName"
          :placeholder="lang[this.appLang].last_name"
          class="edit-input h-50 lh-50 half-bottom"
          style="width: 100%"
        />
      </div>
      <textarea
        v-if="data"
        class="edit-input margin-center h-65 lh-20 half-bottom"
        :placeholder="lang[this.appLang].contact_card_bio_p"
        maxlength="110"
        rows="2"
        v-model="vcardBio"
        style="resize: none; color: #1d1d1d; width: 90%"
      />

      <div class="pills-wrapper">
        <div
          class="pill left-pill pointer"
          style="float: left"
          @click="activePill = 'personal'"
          :class="{ 'pill-active': activePill == 'personal' }"
        >
          <span>Personal</span>
        </div>
        <div
          class="pill right-pill pointer"
          @click="activePill = 'business'"
          :class="{ 'pill-active': activePill == 'business' }"
        >
          <span>Business</span>
        </div>
      </div>
      <div class="contact-input-wrapper">
        <input
          v-if="data && activePill == 'personal'"
          id="vcardPhone"
          type="tel"
          v-model="vcardPhone"
          :placeholder="lang[this.appLang].contact_card_phone_p"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />

        <input
          v-if="data && activePill == 'personal'"
          id="vcardEmail"
          type="email"
          v-model="vcardEmail"
          :placeholder="lang[this.appLang].contact_card_email_p"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />

        <input
          v-if="data && activePill == 'personal'"
          id="vcardWebsite"
          type="text"
          v-model="vcardWebsite"
          :placeholder="lang[this.appLang].contact_card_website_p"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />

        <input
          v-if="data && activePill == 'business'"
          id="vcardCompanyPhone"
          type="text"
          v-model="vcardWorkPhone"
          :placeholder="this.lang[this.appLang].businessPhone"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />

        <input
          v-if="data && activePill == 'business'"
          id="vcardCompanyEmail"
          type="text"
          v-model="vcardWorkEmail"
          :placeholder="this.lang[this.appLang].businessEmail"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />

        <input
          v-if="data && activePill == 'business'"
          id="vcardJobTitle"
          type="text"
          v-model="vcardTitle"
          :placeholder="lang[this.appLang].contact_card_job_title"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />

        <input
          v-if="data && activePill == 'business'"
          id="vcardCompanyName"
          type="text"
          v-model="vcardCompanyName"
          :placeholder="lang[this.appLang].contact_card_company_name_p"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />
        <input
          v-if="data && activePill == 'business'"
          id="vcardWorkUrl"
          type="text"
          v-model="vcardWorkUrl"
          :placeholder="this.lang[this.appLang].contact_card_company_website"
          class="edit-input margin-center h-50 lh-50 half-top half-bottom"
        />

        <vue-google-autocomplete
          id="homeAddress"
          ref="homeAddress"
          :placeholder="lang[this.appLang].contact_card_home_address_p"
          types="address"
          @placechanged="getHomeAddressData"
          @inputChange="homeAddressChange"
          classname="edit-input margin-center h-50 lh-50 half-top half-bottom"
          v-show="activePill == 'personal'"
        />

        <vue-google-autocomplete
          id="workAddress"
          ref="workAddress"
          :placeholder="lang[this.appLang].contact_card_work_address_p"
          types="address"
          @placechanged="getWorkAddressData"
          @inputChange="workAddressChange"
          classname="edit-input margin-center h-50 lh-50 half-top half-bottom"
          v-show="activePill == 'business'"
        />
      </div>

      <div
        v-if="data && !isEmpty(user) && contactCardLinks.length > 0"
        class="links-wrapper"
      >
        <LinkIcon
          v-for="link in contactCardLinks"
          :key="link.id"
          :data="link"
          :link-style="'link-list'"
          :switching="true"
          :editing="false"
          class="link-transition"
        />
      </div>

      <a
        v-if="false"
        @click="redirectLink(data, true)"
        class="button white-button small-top pointer"
        style="margin: 0 5px"
      >
        {{ this.lang[this.appLang].open }}
      </a>
    </div>
    <div class="sticky-footer">
      <!-- Delete -->
      <a
        v-if="data && data.id && !confirmDelete"
        @click="confirmDelete = true"
        class="button white-button pointer small-top half-bottom"
        style="
          margin: 0;
          width: 60px;
          border-radius: 8px;
          padding-left: 7px;
          padding-right: 7px;
        "
      >
        <img
          src="https://cdn.tapni.co/icons/delete.png"
          height="25"
          width="25"
          class="btn-icon"
          style="margin-right: 0px"
        />
      </a>
      <a
        v-if="data && data.id && confirmDelete"
        @click="deleteLink(data.id)"
        class="button white-button pointer small-top half-bottom"
        style="margin: 0; color: #fc1414; width: auto; max-width: 43%"
      >
        {{ this.lang[this.appLang].confirm }}
      </a>

      <button
        type="submit"
        class="button black-button pointer small-top half-bottom"
        style="margin: 0; width: 55%"
      >
        {{ this.lang[this.appLang].save }}
      </button>
    </div>
  </form>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { mapActions } from "vuex";
import VueGoogleAutocomplete from "vue-google-autocomplete";

import LinkIcon from "../LinkIcon";
export default {
  name: "ContactCard",
  components: { LinkIcon, VueGoogleAutocomplete },
  data() {
    return {
      data: null,
      confirmDelete: false,
      toggle: false,
      vcardEmail: "",
      vcardPhone: "",
      vcardWebsite: "",
      vcardFirstName: "",
      vcardLastName: "",
      vcardBio: "",
      vcardWorkPhone: "",
      vcardWorkEmail: "",
      vcardTitle: "",
      vcardCompanyName: "",
      vcardWorkUrl: "",
      vcardAddress: { work: {}, home: {} },
      activePill: "personal",
    };
  },
  computed: {
    contactCardLinks() {
      if (!this.user || !this.user.links || this.user.links.length === 0)
        return [];
      const links = this.user.links.filter((l) =>
        this.linkTypes.find((e) => e.type === l.type && !!e.vcardLink)
      );
      links.forEach((l) => (l.switching = true));
      return links;
    },
  },
  mounted() {
    EventBus.$on("toggleContactCardModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      setTimeout(() => {
        this.confirmDelete = false;
      }, 1000);
      this.toggle = false;
    });
  },
  methods: {
    ...mapActions(["updateVCard", "removeLink"]),
    close() {
      EventBus.$emit("closeModal");
    },
    deleteLink(id) {
      this.removeLink({ id });
      EventBus.$emit("closeModal");
      setTimeout(() => {
        this.confirmDelete = false;
      }, 1000);
    },
    saveLink() {
      let data = {
        // email: this.contactCardEmail.switching,
        // phone: !this.isEmpty(phone),
        vcardEmail: this.vcardEmail,
        vcardWorkEmail: this.vcardWorkEmail,
        vcardPhone: this.vcardPhone,
        vcardWorkPhone: this.vcardWorkPhone,
        vcardWebsite: this.vcardWebsite,
        vcardFirstName: this.vcardFirstName,
        vcardLastName: this.vcardLastName,
        vcardBio: this.vcardBio,
        vcardCompanyName: this.vcardCompanyName,
        vcardTitle: this.vcardTitle,
        vcardWorkUrl: this.vcardWorkUrl,
        vcardAddress: this.vcardAddress,
        socialUrls: {},
      };
      debugger;
      this.contactCardLinks.forEach((l) => {
        if (l.switching) {
          let link = l.type;
          if (link === "customlink") link = "website";
          data.socialUrls[link] = this.redirectLink(l, false, true);
        }
      });
      this.updateVCard({
        vcard: data,
        text: this.data.desc[this.appLang].short,
      });
      EventBus.$emit("toggleContactCardModal");
      this.$router.push(this.home);
      this.confirmDelete = false;
    },
    toggleModal(data) {
      this.toggle = !this.toggle;
      this.confirmDelete = false;
      if (data) {
        this.data = data;
        const linkType = this.linkTypes.filter((e) => e.type === data.type)[0];

        this.data.desc = linkType.desc;
        if (!this.isEmpty(this.user.vcard)) {
          this.data.desc.short = this.user.links.filter(
            (l) => l.type === "contactcard"
          )[0]
            ? this.user.links.filter((l) => l.type === "contactcard")[0].text
            : data.text;
        }

        let email = this.contactCardLinks.filter(
          (l) => l.type === "email" && !!l.switching
        )[0]
          ? this.contactCardLinks.filter(
              (l) => l.type === "email" && !!l.switching
            )[0].url
          : "";
        if (!email)
          email = this.contactCardLinks.filter(
            (l) => l.type === "gmail" && !!l.switching
          )[0]
            ? this.contactCardLinks.filter(
                (l) => l.type === "gmail" && !!l.switching
              )[0].url
            : "";
        if (!email) email = this.user.email;

        this.vcardEmail = email || "";

        let phone = this.contactCardLinks.filter(
          (l) => l.type === "phone" && !!l.switching
        )[0];
        if (!phone)
          phone = this.contactCardLinks.filter(
            (l) => l.type === "sms" && !!l.switching
          )[0];
        if (!phone)
          phone = this.contactCardLinks.filter(
            (l) => l.type === "whatsapp" && !!l.switching
          )[0];
        if (!phone)
          phone = this.contactCardLinks.filter(
            (l) => l.type === "viber" && !!l.switching
          )[0];
        if (!phone)
          phone = this.contactCardLinks.filter(
            (l) => l.type === "telegram" && !!l.switching
          )[0];

        this.vcardPhone = phone ? phone.url : "";
        this.vcardWebsite =
          "https://" +
          this.$store.getters.subdomain +
          ".tapni.co/" +
          this.user.username;
        this.$refs.homeAddress.update(
          !this.isEmpty(this.user.vcard.vcardAddress) &&
            !this.isEmpty(this.user.vcard.vcardAddress.home)
            ? this.user.vcard.vcardAddress.home.place.formatted_address
            : ""
        );
        this.$refs.workAddress.update(
          !this.isEmpty(this.user.vcard.vcardAddress) &&
            !this.isEmpty(this.user.vcard.vcardAddress.work)
            ? this.user.vcard.vcardAddress.work.place.formatted_address
            : ""
        );

        this.vcardBio = this.user.bio;
        if (this.user.name) {
          this.vcardFirstName = this.user.name.split(" ")[0];
          this.vcardLastName = this.user.name.split(" ")[1] || "";
        }

        if (linkType.prefill_url) {
          if (!data.id) {
            this.data.url = linkType.base_url;
          }
        } else {
          if (data.id && this.data.url) {
            this.data.url = this.data.url.replace(linkType.base_url, "");
          }
        }
      }

      if (!this.isEmpty(this.user.vcard)) {
        // Old name as first name
        if (this.user.vcard.vcardName)
          this.vcardFirstName = this.user.vcard.vcardName;

        // New name split - first and last name
        if (this.user.vcard.vcardFirstName)
          this.vcardFirstName = this.user.vcard.vcardFirstName;
        if (this.user.vcard.vcardLastName)
          this.vcardLastName = this.user.vcard.vcardLastName;

        if (this.user.vcard.vcardEmail)
          this.vcardEmail = this.user.vcard.vcardEmail;
        if (this.user.vcard.vcardWorkEmail)
          this.vcardWorkEmail = this.user.vcard.vcardWorkEmail;
        if (this.user.vcard.vcardPhone)
          this.vcardPhone = this.user.vcard.vcardPhone;
        if (this.user.vcard.vcardWorkPhone)
          this.vcardWorkPhone = this.user.vcard.vcardWorkPhone;
        if (this.user.vcard.vcardWebsite)
          this.vcardWebsite = this.user.vcard.vcardWebsite;
        if (this.user.vcard.vcardCompanyName)
          this.vcardCompanyName = this.user.vcard.vcardCompanyName;
        if (this.user.vcard.vcardTitle)
          this.vcardTitle = this.user.vcard.vcardTitle;
        if (this.user.vcard.vcardBio) this.vcardBio = this.user.vcard.vcardBio;
        if (this.user.vcard.vcardWorkUrl)
          this.vcardWorkUrl = this.user.vcard.vcardWorkUrl;
        if (this.user.vcard.vcardAddress)
          this.vcardAddress = this.user.vcard.vcardAddress;

        if (this.user.vcard.socialUrls) {
          this.contactCardLinks.forEach((l) => {
            l.switching = !!this.user.vcard.socialUrls[l.type];
          });
        }
      }
    },
    workAddressChange(e) {
      if (e.newVal === "") {
        this.vcardAddress.work = {};
      }
    },
    getWorkAddressData(address, place) {
      this.vcardAddress.work = { address, place };
    },
    homeAddressChange(e) {
      if (e.newVal === "") {
        this.vcardAddress.home = {};
      }
    },
    getHomeAddressData(address, place) {
      this.vcardAddress.home = { address, place };
    },
  },
};
</script>

<style scoped>
.link-img {
  width: 65px;
  margin-right: 15px;
}
#linkText {
  font-size: 17px;
  margin: 0 auto;
  width: 100%;
}

.notransition {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

#linkUrl {
  font-size: 17px;
  margin: 0 auto;
}

.contactcard p {
  margin-bottom: 15px !important;
}

.contact-input-wrapper {
  max-width: 90%;
  margin: 0 auto;
}

.pills-wrapper {
  width: 100%;
  max-width: 90%;
  display: flex;
  border-radius: 50px;
  height: 50px;
  margin: 0 auto;
  background: rgb(233, 233, 233) !important;
  padding: 2px;
  line-height: 48px;
}

.pill {
  width: 50%;
}

.pill-active {
  background-color: #ffffff;
  border-radius: 50px;
}

.left-pill {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.right-pill {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.contact-input-wrapper input {
  width: 100%;
}

.links-wrapper {
  max-height: 50vh;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 15px;
  overflow-y: scroll;
  border-radius: 15px !important;
}

.sticky-footer {
  height: 90px;
  width: 100%;
  position: sticky;
  bottom: 0;
  z-index: 999;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 15px !important;
}

.link-list {
  background-color: rgb(244, 244, 244) !important;
  width: 100%;
}
</style>
