<template>
  <form class="page-login content-boxed content-boxed-padding" @submit.prevent="submit" :style="isModal ? 'max-width: 500px;' : ''">
	<a v-if="isModal" @click="close" class="color-black pull-right pointer" style="margin-top: 0px; position: absolute; right: 20px; text-align: right;">
	  <i class="font-17 fa color-black">
	    <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 35%;"/>
	  </i>
	</a>
	<h1 class="bold small-top no-bottom center-text">{{lang[this.appLang].verify_account}}</h1>
    <p class="half-bottom half-top center-text color-black"></p>
	<div class="page-login-field half-top">
	  <i class="fa" style="padding-top: 8px; margin-left: 10px;">
	    <img src="https://cdn.tapni.co/icons/mail.png" class="fa responsive-image" style="width: 80%;">
	  </i>
	  <input id="emailInput" v-model="email" @keydown.space.prevent type="email" :placeholder="lang[this.appLang].email" required>
	  <em></em>
	</div>
	<div class="page-login-field half-top">
	    <i class="fa" style="padding-top: 13px; margin-left: 10px;">
		    <img src="https://cdn.tapni.co/icons/password2.png" class="fa responsive-image" style="width: 100%;">
	    </i>
      <input id="codeInput" v-model="code" @keydown.space.prevent type="tel" :placeholder="lang[this.appLang].code" required>
      <em></em>
    </div>
      <button type="submit" class="button google-button bg-tapni-grey button-90 button-center uppercase bold full-top">
      <span v-if="!submitted" class="uppercase">{{lang[this.appLang].verify_account}}</span>
      <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 15px auto 0;width: 100%; text-align: center; font-size: 20px;"></i>
    </button>
    <div class="decoration decoration-lines-thin no-bottom"></div>

	<p class="close-text center-text half-top color-black">
		<span v-html="lang[this.appLang].verify_account_p1"></span>
		<a href="mailto:support@tapni.co?subject=Email Verification"> support@tapni.co</a>
	</p>

	<div class="decoration decoration-lines-thin no-bottom"></div>
	<div v-if="!isModal" class="page-login-links">
		<router-link class="create float-right" to="/register">{{lang[this.appLang].register}}</router-link>
		<router-link class="forgot float-left" to="/login">{{lang[this.appLang].sign_in}}</router-link>
		<div class="clear"></div>
	</div>
	<div v-else class="page-login-links" @click="registerAccountModal">
		<a class="forgot center-text pointer small-top">{{lang[this.appLang].or}} <br> {{lang[this.appLang].create_new_account}}</a>
		<div class="clear"></div>
	</div>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import router from '../router'
import {EventBus} from "../store/event-bus";
export default {
  name: 'AuthVerify',
  props: {
  	isModal: {
  		type: Boolean,
  		required: false,
  		default: false
  	},
  },
  data () {
    return {
      loading: true,
      submitted: false,
      action: 'verify',
      email: '',
      code: '',
      captcha: ''
    }
  },
  mounted () {
	if (this.storage.verifyEmail) {
	  this.email = this.storage.verifyEmail
	}
    if (this.route) this.init()
  },
  methods: {
    ...mapActions([
      'verify', 'loginSetup', 'getLoggedInAccounts'
    ]),
    init() {
      if (this.isLoggedIn) {
        this.$router.push(this.home)
      } else {
        if (this.storage.verifyEmail) {
          this.email = this.storage.verifyEmail
        }
        if (this.route.hash === '#reset') {
          this.action = 'reset'
        }
        if (this.route.query.e) {
          this.email = this.route.query.e
        }
        if (this.route.query.c) {
          this.code = this.route.query.c
          this.submitted = true
          setTimeout(this.submit, 1500)
        }
        this.$store.commit('setInitialize', true);
        this.$store.commit('setLoading', false);
      }
    },
    submit() {
	  this.submitted = true
      // if (this.captcha === '') return this._vm.errorHandler({ message: 'Please select recaptcha' });
      this.code = this.code.trim()
      if (this.code.length !== 6) return this.errorSnack(this.lang[this.appLang].invalid_code);

	  this.email = this.email.trim()
	  this.code = this.code.trim()

      let data = {
        code: this.code,
        email: this.email,
        captcha: this.captcha,
      };

      this.verify(data).then((response) => {
        this.submitted = false
        if (response && response.data && response.data.success === true) {
          if (this.action === 'reset') {
            this.$router.push('/reset?c=' + this.code + '&e=' + this.email)
            this.successSnack(this.lang[this.appLang].verify_account_success)
          }
          if (this.action === 'verify') {
			if (this.isModal) {
			  this.email = ''
			  this.code = ''
			  EventBus.$emit('closeModal')
			}
			this.loginSetup(response)
			this.getLoggedInAccounts(this.storage.refreshTokens.split(','))

            router.push('/' + response.data.data.username + '#edit')
			this.successSnack(this.lang[this.appLang].verify_account_success2)
		  }
        }
      })
    },
	registerAccountModal () {
		EventBus.$emit('toggleAddAccountModalRegister', false)
	},
	close () {
  	    EventBus.$emit('toggleAddAccountModal', false)
	},
  },
  watch: {
    'storage.verifyEmail' (nv) {
    	if (nv)	this.email = nv
	},
    'route.name' (nv, ov) {
      if (!ov && nv) this.init()
    },
    'route.query': {
      handler(nv) {
        if (!this.isEmpty(nv)) this.init()
      },
      deep: true,
    },
  }
}
</script>

<style scoped>
  #codeInput {
    text-align: center;
    padding-right: 15%;
    font-size: 17px;
  }
  #emailInput {
	text-align: center;
	padding-right: 15%;
	font-size: 17px;
  }
</style>
