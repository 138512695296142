const vCardJS = require('vcards-js2');
import Compressor from 'compressorjs';

export default {
    getFirstBrowserLanguage () {
        let nav = window.navigator
        let browserLanguagePropertyKeys = ['language', 'browserLanguage', 'systemLanguage', 'userLanguage']
        let i
        let language

        // support for HTML 5.1 "navigator.languages"
        if (Array.isArray(nav.languages)) {
            for (i = 0; i < nav.languages.length; i++) {
                language = nav.languages[i]
                if (language && language.length) {
                    return language.slice(0, 2)
                }
            }
        }

        // support for other well known properties in browsers
        for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
            language = nav[browserLanguagePropertyKeys[i]]
            if (language && language.length) {
                return language.slice(0, 2)
            }
        }

        return null
    },
    compareLangKeys () {
        const en = Object.keys(require('../store/modules/en').default.state);
        const de = Object.keys(require('../store/modules/de').default.state);
        const es = Object.keys(require('../store/modules/es').default.state);
        const kr = Object.keys(require('../store/modules/kr').default.state);
        const it = Object.keys(require('../store/modules/it').default.state);
        const fr = Object.keys(require('../store/modules/fr').default.state);
        const sr = Object.keys(require('../store/modules/sr').default.state);
        const tr = Object.keys(require('../store/modules/tr').default.state);

        // Compare en and de
        console.log('In en but not in de', en.filter(x => !de.includes(x)));
        console.log('In de but not in en', de.filter(x => !en.includes(x)));
        console.log();

        // Compare en and es
        console.log('In en but not in es', en.filter(x => !es.includes(x)));
        console.log('In es but not in en', es.filter(x => !en.includes(x)));
        console.log();

        // Compare en and kr
        console.log('In en but not in kr', en.filter(x => !kr.includes(x)));
        console.log('In kr but not in en', kr.filter(x => !en.includes(x)));
        console.log();

        // Compare en and it
        console.log('In en but not in it', en.filter(x => !it.includes(x)));
        console.log('In it but not in en', it.filter(x => !en.includes(x)));
        console.log();

        // Compare en and fr
        console.log('In en but not in fr', en.filter(x => !fr.includes(x)));
        console.log('In fr but not in en', fr.filter(x => !en.includes(x)));
        console.log();

        // Compare en and sr
        console.log('In en but not in sr', en.filter(x => !sr.includes(x)));
        console.log('In sr but not in en', sr.filter(x => !en.includes(x)));
        console.log();

        // Compare en and tr
        console.log('In en but not in tr', en.filter(x => !tr.includes(x)));
        console.log('In tr but not in en', tr.filter(x => !en.includes(x)));
        console.log();
    },
    generateVcard (vcard) {

        // create a new vCard
        let vCard = vCardJS();

        if (vcard.vcardName) {
            // New code
            vCard.firstName = vcard.vcardName;
        } else {
            // Old code
            vCard.firstName = vcard.name || '';
        }

        // new code v2
        if (vcard.vcardFirstName) vCard.firstName = vcard.vcardFirstName;

        if (vcard.vcardMiddleName) vCard.middleName = vcard.vcardMiddleName;
        if (vcard.vcardLastName) vCard.lastName = vcard.vcardLastName;
        if (vcard.vcardCompanyName) vCard.organization = vcard.vcardCompanyName;

        if (vcard.vcardPhone) vCard.homePhone = vcard.vcardPhone;
        if (vcard.vcardHomePhone) vCard.homePhone = vcard.vcardHomePhone;
        if (vcard.vcardWorkPhone) vCard.workPhone = vcard.vcardWorkPhone;
        if (vcard.vcardTitle) vCard.title = vcard.vcardTitle;
        if (vcard.vcardWorkUrl) vCard.workUrl = vcard.vcardWorkUrl;
        if (vcard.vcardWorkPosition) vCard.role = vcard.vcardWorkPosition;
        if (vcard.vcardGender) vCard.gender = vcard.vcardGender;
        if (vcard.vcardHomeFax) vCard.homeFax = vcard.vcardHomeFax;
        if (vcard.vcardWorkFax) vCard.workFax = vcard.vcardWorkFax;
        if (vcard.vcardBio) vCard.note = vcard.vcardBio;
        if (vcard.vcardEmail) vCard.email = vcard.vcardEmail;
        if (vcard.vcardHomeEmail) vCard.email = vcard.vcardHomeEmail;
        if (vcard.vcardWorkEmail) vCard.workEmail = vcard.vcardWorkEmail;
        if(vcard.vcardWebsite) vCard.url = vcard.vcardWebsite;

        // set address information
        if (vcard.vcardAddress) {
            if (vcard.vcardAddress.work) {
                const { address } = vcard.vcardAddress.work;
                if (address) {
                    vCard.workAddress.label = 'Work Address';
                    vCard.workAddress.street = `${address.route} ${address.street_number}`;
                    vCard.workAddress.city = address.locality;
                    vCard.workAddress.stateProvince = address.administrative_area_level_1;
                    vCard.workAddress.postalCode = address.postal_code;
                    vCard.workAddress.countryRegion = address.country;
                }
            }

            if (vcard.vcardAddress.home) {
                const { address } = vcard.vcardAddress.home;
                if (address) {
                    vCard.homeAddress.label = 'Home Address';
                    vCard.homeAddress.street = `${address.route} ${address.street_number}`;
                    vCard.homeAddress.city = address.locality;
                    vCard.homeAddress.stateProvince = address.administrative_area_level_1;
                    vCard.homeAddress.postalCode = address.postal_code;
                    vCard.homeAddress.countryRegion = address.country;
                }
            }
        }
        // set social media URLs
        //vCard.socialUrls = vcard.socialUrls || {};

        vCard.version = '3.0';
        return vCard.getFormattedString()
    },
    base64ImageToBlob(base64Image) {
        // Split into two parts
        const parts = base64Image.split(';base64,');

        // Hold the content type
        const imageType = parts[0].split(':')[1];

        // Decode Base64 string
        const decodedData = window.atob(parts[1]);

        // Create UNIT8ARRAY of size same as row data length
        const uInt8Array = new Uint8Array(decodedData.length);

        // Insert all character code into uInt8Array
        for (let i = 0; i < decodedData.length; ++i) {
            uInt8Array[i] = decodedData.charCodeAt(i);
        }

        // Return BLOB image after conversion
        return new Blob([uInt8Array], { type: imageType });
    },
    blobToBase64(blob) {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        return new Promise(resolve => {
            reader.onloadend = () => {
                resolve(reader.result);
            };
        });
    },
    base64toFile(base64, filename) {

        var arr = base64.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);

        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, {type:mime});
    },
    fileToBase64(file) {
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    },
    compressImage(file) {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                quality: .8,
                maxWidth: 1024,
                maxHeight: 1024,
                success: resolve,
                error: reject
            });
        });
    },
    getUTMParams(data) {
        let utm = {}
        if (data.utm_source) utm.utm_source = data.utm_source;
        if(data.utm_medium) utm.utm_medium = data.utm_medium;
        if(data.utm_campaign) utm.utm_campaign = data.utm_campaign;
        if(data.utm_term) utm.utm_term = data.utm_term;
        if(data.utm_content) utm.utm_content = data.utm_content;
        return utm;
    }
}
