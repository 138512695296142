<template>
  <form
    @submit.prevent="submit"
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="height: auto; margin-top: -172.5px"
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>
    <img
      :src="'https://cdn.tapni.co/icons/delete-account.png'"
      class="password-img small-bottom"
      :alt="lang[this.appLang].password_change"
    />
    <!--
    <input type="password" v-model="password" :placeholder="lang[this.appLang].password_current_p"
           v-autowidth="{maxWidth: '90%', minWidth: '70%', comfortZone: 0}"
           class="edit-input edit-shadow" required minlength="8" />
    <p class="small-text small-top-padding small-bottom">{{lang[this.appLang].password_current}}</p>
    -->
    <h5 class="bold small-top-padding small-bottom">
      Are you sure you want to delete your Tapni account permanently?
    </h5>

    <button
      type="submit"
      class="button white-button button-full"
      style="margin: 0 auto; margin-top: 13px; color: red"
    >
      {{ lang[this.appLang].profile_confirm_delete_account }}
    </button>
    <button
      @click.prevent="close"
      class="button white-button button-full full-bottom"
      style="margin: 0 auto; margin-top: 13px"
    >
      {{ lang[this.appLang].profile_cancel_delete_account }}
    </button>
    <!--
    <p class="center-text font-13" style="position: absolute; bottom: 30px; width: 90%; max-width: 500px;">
      <span v-html="lang[this.appLang].password_reset"></span>
      <router-link to="/reset" exact class="color-black" @click.native="forceClose">{{lang[this.appLang].by_clicking_here}}</router-link>
    </p>
    -->
  </form>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { mapState, mapActions } from "vuex";
export default {
  name: "DeleteAccount",
  data() {
    return {
      toggle: false,
    };
  },
  mounted() {
    EventBus.$on("toggleDeleteAccountModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  methods: {
    ...mapActions(["deleteAccount"]),
    forceClose() {
      EventBus.$emit("closeModal");
    },
    close() {
      this.toggleModal();
      EventBus.$emit("toggleProfileModal", false);
    },
    async submit() {
      const result = await this.deleteAccount();
      if (result === true) {
        this.successSnack(
          this.lang[this.appLang].profile_delete_account_success
        );
        EventBus.$emit("closeModal");
        setTimeout(this.$store.dispatch("logout"), 1000);
      }
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style scoped>
.password-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 65px;
}
input {
  margin: 0 auto;
  height: 20px;
}
</style>
