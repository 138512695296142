export default {
  state: {
    english: "English",
    spanish: "Spanish",
    german: "German",
    korean: "Korean",
    italian: "Italian",
    french: "French",
    serbian: "Serbian",
    turkish: "Turkish",
    app_language: "Language",
    profile_preview: "Profile preview",
    edit_profile: "Edit profile",
    login: "Login",
    referral_reach:
      "Provide us with more information about you and your reach.",
    referral_apply_placeholder: "How do you plan to promote Tapni?",
    referral_apply_note:
      "Add as many links as possible in your account, <br>\n" +
      "\t\t  so that we can know who you are.\n" +
      "\t\t  <br><br>Be exclusive.\n" +
      "\t\t  <br>Not everyone gets accepted.😉",
    referral_apply_now: "Apply now",
    referral_apply_thanks:
      "Thanks for applying. We will review your application soon!",
    connect: "Connect",
    exchange_contact: "Exchange Contact",
    add_details: "Add more",
    show_details: "Show more",
    download_contact: "Download Contact",
    stay_in_touch: "Stay in touch with",
    no_thanks: "No Thanks",
    connect_join: "Join Tapni",
    connect_with: "Exchange contact with",
    connect_required_fields: "One of the two contact fields must be provided",
    name: "Name",
    first_name: "First name",
    last_name: "Last name",
    email: "Email",
    phone: "Phone number",
    phone_p: "Phone",
    company: "Company",
    address: "Address",
    website: "Website",
    open: "Open",
    delete: "Delete",
    confirm: "Confirm",
    cancel: "Cancel",
    save: "Save",
    code: "Code",
    note: "Note",
    note_p: "Add a note",
    contact_card_icon: "Set text under the contact card icon",
    contact_card_name: "Enter first and lastname",
    contact_card_name_p: "Contact card name",
    contact_card_email: "Enter personal email address",
    contact_card_email_p: "Contact card email",
    contact_card_phone: "Enter personal phone number",
    contact_card_phone_p: "Contact card phone",
    contact_card_website: "Enter personal website",
    contact_card_website_p: "Contact card website",
    contact_card_company_name: "Enter company name",
    contact_card_company_name_p: "Contact card company name",
    contact_card_job_title: "Job title",
    contact_card_job_title_p: "Enter job title",
    contact_card_company_website: "Company website",
    contact_card_company_website_p: "Enter company website",
    contact_card_bio: "Enter bio for the contact card",
    contact_card_bio_p: "Enter bio for the contact card",
    contact_card_home_address: "Enter home address",
    contact_card_home_address_p: "Contact card home address",
    contact_card_work_address: "Enter work address",
    contact_card_work_address_p: "Contact card work address",
    contact_card_social:
      "Choose social media links to your Tapni profile <br>in order to add them in the contact card",
    contact_card_social_p: "Choose social media links for the contact card",

    link_text_p: "Link Text",
    link_icon: "Set text under the link icon",

    direct_pick: "Pick a direct link",
    direct_p:
      "When you tap someone's phone, <br> this link will be opened instead of your full profile.",

    password_change: "Change password",
    password_repeat: "Repeat password",
    password_reset_t: "Password reset",
    password_current: "Enter your current password",
    password_current_p: "Current Password",
    password_new: "Enter your new password",
    password_new_p: "New Password",
    password_new_repeat: "Enter your new password again",
    password_new_repeat_p: "Enter your new password again",
    password_update: "Password update",
    password_reset:
      "If you can't remember your current password,<br>\n" +
      "\t\t  you can reset it ",
    by_clicking_here: "by clicking here",
    password_8_chars: "Password must contain at least 8 characters",
    password_not_match: "Passwords do not match",
    password_change_success: "Successfully changed password",

    or: "or",
    profile: "Profile",
    profile_public: "Public profile",
    profile_public_p: "Your profile is publicly available via: ",
    profile_active: "Active profile",
    profile_active_p:
      "If you deactivate your profile, nobody will have access to it and the connected tags won't work anymore",
    profile_change_password: "Change Password",
    profile_change_username: "Change Username",
    profile_change_language: "Change Language",
    profile_delete_account: "Delete Account",
    profile_confirm_delete_account: "Delete Account",
    profile_cancel_delete_account: "Cancel",
    profile_delete_account_success: "The account has been successfully deleted",
    profile_remove_p:
      "If you would like to remove your account,<br>\n" +
      "\t\t    please write us at",
    profile_contact_support:
      "If you have problems with the settings,<br>\n" +
      "\t\t    please write us at",
    profile_email_subject: "Tapni App Support",
    profile_remove_subject: "Account Removal Request",
    profile_update_success: "Successfully updated profile",
    profile_complete: "Complete your profile",
    completed: "Completed",
    uncompleted: "Uncompleted",
    contact_us: "contact us",
    contact_us_verify: "in order to verify your email address.",
    verify_email: "Verify Email address",
    verify_email_subject: "Email Verification",
    verify_email_inbox: "Check your inbox for",

    add_first_link: "Add your first link",
    add_first_link_p:
      "Tap here to add your social media channels <br>\n" +
      "\t\t\t  or contact information to your Tapni profile.",
    add_picture: "Add a profile picture",
    add_picture_p: "Tap here to upload your profile picture.",
    add_tag: "Activate your Tapni",
    add_tag_p: "Tap here to activate your Tapni NFC tag.",
    completing_profile:
      "Problems with completing your profile?<br>\n" +
      "\t\t    Feel free to write us at ",
    completing_profile_subject: "Complete profile",
    share_profile: "Share profile",
    share_profile_p:
      "Others can scan this QR code <br> in order to see your profile",
    share_profile_change_username:
      "If you want to change your link, <br> <u>click here</u> to change username.",
    scan_qr_code: "Scan QR Code",
    enter_activation_code: "Enter the activation code",
    activate_tag: "Activate tag",
    order_tapni:
      "If you haven't ordered your Tapni yet,<br>\n" +
      "          you can pick yours ",
    activation_success: "Successfully connected tag. <br> You're all set!🥳",
    activation_success_message: "Successfully connected tag. You're all set!🥳",
    activation_error: "This is not a valid tag. <br> Try again.",
    activation_error_message: "This is not a valid tag. <br> Try again.",
    activation_progress: "Tag registration in progress...",
    activation_invalid_code: "Invalid activation code.",
    activation_qr_failed: "QR Code Scanning failed",
    activation_qr_area: "Place a QR Code inside the scan area",
    activation_code: "Activation code",
    invalid_code: "Invalid code",
    total_taps: "Total taps",
    active: "Active",
    tag_active_p:
      "If deactivated, it won't open your profile <br> when you tap someone's phone.",
    tag_delete_confirm:
      "Save the activation code before unlinking the tag,<br> otherwise it can't be reactivated.",

    upload_profile_picture: "Upload Profile Picture",
    upload_cover_picture: "Upload Cover Picture",
    upload_link_picture: "Upload Link Picture",
    select_from_device: "Select from device",
    select_from_mobile: "Select picture from your mobile device",
    select_from_computer: "or drag & drop it if you are using a computer",
    crop: "Crop",
    crop_upload: "Crop and Upload",
    crop_image: "Crop Image",
    drag_and_drop: "Drag & Drop files here",
    upload_success: "Media successfully uploaded",
    browser_not_supported: "Your browser does not support",
    change_username: "Change username",
    change_username_success: "Successfully changed username",
    update_username: "Update username",
    new_username: "Enter your new username",
    username_check: "Please provide a proper username",
    anonymous: "Anonymous",
    connected: "Connected",
    exchanged: "Exchanged",
    accept: "Accept",
    view: "View",
    join: "Join",
    add_account: "Add account",
    complete_profile: "Complete profile",
    my_profile: "My profile",
    my_qr: "My QR",
    my_connections: "My connections",
    my_tags: "My tags",
    buy_tapni: "Buy Tapni",
    referral_program: "Referral program",
    device_compatibility: "Device compatibility",
    help: "Help",
    settings: "Settings",
    logout: "Logout",
    version: "Version",
    configure: "Configure",
    unexpected_err:
      "Unexpected error occurred, please contact us at support@tapni.co",
    success_login: "Successfully logged in with another account.",
    password_reset_success: "Password reset code was sent to your email.",
    password_reseted_success:
      "Successfully changed password. You can log in now.",
    success_remove_link: "Successfully removed link",
    profile_active_message: "Your profile is now active",
    profile_inactive_message: "Your profile is now disabled",
    profile_public_message: "Your profile is now public",
    profile_private_message: "Your profile is now private",
    redirect_enabled: "Auto-redirect Link enabled",
    redirect_disabled: "Auto-redirect Link disabled",
    tag_active: "Tag is now active. Let’s get tappin'",
    tag_inactive: "Tag is now disabled. Your visitors will land on tapni.co",
    tag_removed: "Successfully removed tag",
    connection_accepted: "Connection request accepted.",
    not_found: "Not found",
    not_found_p: "It seems like you are lost",
    go_back: "Go back",
    referral_p1: "Earn 20% commission<br> on referral sales 💰",
    referral_p2: "Give others 20% off<br> with your discount code 🎁",
    referral_p3: "It's a win-win 🤝",
    referral_p4:
      "If you want to change your referrals,<br>\n" +
      "\t\t\t\t  or if you have any questions,<br>\n" +
      "\t\t\t\t  write us on",
    referral_p5: "Start earning money now!",
    referral_p66: "Share your referral link and make money!",
    referral_p6:
      "If you are a blogger, influencer or social media enthusiast you are a perfect fit for our Referral Program.\n" +
      "\t\t\t\tPromote our Brand and earn a 20% commission on purchases made with your discount code or referral link.",
    referral_p7:
      "After registering for the Tapni Referral Program, you will get a personal referral link and discount code.\n" +
      "\t\t\t\tShare them on your website and social media, and make money while we deliver a great connecting experience.",
    referral_p8: "Payout Note",
    referral_p9:
      "Paypal (Worldwide) and Bank Transfers (EU) <br> Minimum payout amount is 25€.",
    referral_p10: "Request Payout",
    referral_p11: "Requested Payout",
    your_connections: "Your connections",
    connections_p1: "Get in touch with people you met",
    connections_p2: "<br><br>No connections so far. <br> Let's get tappin'💥",
    connections_p3: "No connections found.",
    connection_success: "Successfully sent a connection request",
    exchange_success: "Successfully exchanged contacts.",
    create_free_account:
      "Create a free Tapni account and keep your contacts up to date!",
    connection_edit: "Manage connection",
    connection_edit_success: "Successfully updated connection",
    connection_delete_success: "Successfully deleted connection",
    search: "Search",
    devices_p1: "Devices compatibility list",
    devices_p2:
      "Tapni uses technology that is compatible with most newer iPhone and Android devices.",
    devices_p3:
      "Some Android phones might have NFC turned off. If your Tapni is not working on an Android device, search for NFC in settings and make sure it is turned on.",
    devices_p4: "with iOS 14 NFC widget in control center",
    download_p1: "Download Tapni App",
    download_p2: "Available for Apple and Android devices",
    user_not_found: "User not found",
    user_not_found_p: "We couldn't find the user in our database🤷",
    taps: "taps",
    private_profile: "Private profile",
    private_profile_p:
      "is a private profile. <br> Profile is visible using Tapni tags only ",
    profile_bio_p: "Write something about you or your brand",
    add_to_contacts: "Add to contacts",
    drag_and_drop_links: "Drag & Drop links in the desired order",
    no_links: "There are no links in your profile.",
    add_link: "Add link",
    direct_link_p1: "Visitors will be automatically redirected to:",
    profile_not_active:
      "Your profile is currently not active <br> and only you can see it when logged in.",
    profile_not_public:
      "Your profile is currently not public <br> and only those who tap you can see it.",
    activate_profile_now: "Activate your profile now",
    publish_profile_now: "Make your profile public now",
    link_add_p1: "Choose a link type",
    link_add_not_found: "No links found 🤷",
    link_add_not_found_p2:
      "If we missed your favourite platform, please drop us and email (<b>support@tapni.co</b>) or DM us on social media (<b>@tapni.co</b>)",
    link_add_not_found_p3: "We will try our best to support all platforms 😉",
    featured: "Featured",
    social_media: "Social media",
    contact: "Contact",
    finance: "Finance",
    portfolio: "Portfolio",
    music: "Music",
    business: "Business",
    fun: "Fun",
    ecommerce: "Ecommerce",
    more: "More",
    email_username: "Email / Username",
    username: "Username",
    password: "Password",
    sign_in: "Sign In",
    sign_in_with: "Sign In with",
    sign_in_with_new: "Sign In with new account",
    sign_up_with: "Sign Up with",
    verify_account: "Verify account",
    terms_by_signing_in: "By signing in, you agree to our",
    terms_by_registering: "By registering, you agree to our",
    terms_by_submitting:
      "By submitting this form, you agree to our Terms of Service",
    terms_of_service: "Terms of Service",
    and: "and",
    refresh: "Refresh",
    privacy_policy: "Privacy Policy",
    create_account: "Create account",
    reset_password: "Reset password",
    reset_password_p1:
      "If you couldn't reset your password,<br> feel free to contact us at ",
    reset_password_p2:
      "If you couldn't reset your password,<br> feel free to contact us at",
    create_new_account: "Create new account",
    maintenance_p1: "We will be back soon",
    maintenance_p2: "Explore Tapni collections in the meantime",
    maintenance_p3:
      "Tapni is down for a scheduled maintenance <br> and is expected to be back online in a few minutes.",
    offline_p1: "No Internet connection",
    offline_p2: "Check your connection, then refresh the page",
    offline_p3_1: "While you are offline,",
    offline_p3_2: "you can share your contact card using this QR code",
    products_p1: "Impress everyone you meet 😎 <br> Get yours now 👇",
    cards: "Cards",
    stickers: "Stickers",
    keychains: "Keychains",
    explore_collection: "Explore collection",
    register: "Register",
    register_success: "You are almost done. Please confirm your email.",
    register_success_modal: "Successfully logged in with another account.",
    check_your_inbox: "Check your inbox",
    tapni_tags: "Tapni tags",
    tapni_tags_p1: "Configure tags connected to your profile",
    tapni_tags_p2:
      "There are no tags connected to your profile. <br> Connect one now!",
    verify_account_p1:
      "If you didn't receive a code, check your <br> spam folder or contact us at",
    verify_account_success:
      "Successfully verified account. Please change your password now.",
    verify_account_success2:
      "Successfully verified account. You are all set!🥳",
    welcome: "Welcome",
    welcome_p1: "to the future of business cards",
    how_it_works: "How it works",
    already_registered: "Already registered",
    error: "Error",
    success: "Success",
    copied: "Copied to clipboard",
    connect_validate: "One of the two contact fields must be provided",
    connect_validate_name: "Please enter your name",
    connect_validate_email: "Please enter a valid email address",
    link_validate: "Invalid link data.",
    link_text_contact_card: "Contact card",
    link_text_custom_link: "Custom link",
    link_text_menu: "Menu",
    link_text_phone: "Phone",
    save_to_wallet: "Save to Apple wallet",
    card: "Card",
    sticker: "Sticker",
    keychain: "Keychain",
    band: "Band",
    case: "Case",
    standee: "Standee",
    decoded: "Decoded",
    wristbanditz: "Wristbanditz",
    update_p1: "Outdated app version detected",
    update_p2: "Update",
    update_p3: "Please update the app to the newest version",
    company_domain: "Your company domain",
    search_company_domain: "Search your company domain",
    company_not_found: "Company not found",
    ik_company_domain: "I know the company domain",
    idk_company_domain: "I don't know the company domain",
    continue: "Continue",
    forbidden_rule:
      "This option is disabled, contact your company administrator.",
    companyName: "Company Name",
    createMarkerPlaceholder: "Create or select a marker",
    clickToCreateMarker: "Click here to create marker",
    pleaseInviteUser:
      "Please invite user to join Tapni in order to have synchronized data with you.",
    connectionSynchronized: "Connection data is automatically synchronized.",
    viewProfile: "View Profile",
    createConnectionHeader: "Create new connection",
    createConnectionButton: "Create connection",
    createConnectionSuccess: "Connection has been created.",
    allow_company_management: "Allow company management",
    company_join_request:
      "is requesting to manage your profile and associate it to it's Enterprise account.",
    account_ownership_transfer:
      "Ownership of your account will be transferred to the company if you accept the request. The company will be able to modify your profile, edit and add links, see your connections, modify your password, delete your account, and more. Make sure to check with your company before accepting the request.",
    request_expired:
      "The request has expired, ask your company to send a new request.",
    request_expiration_desc:
      "For added security, the request will only work for 14 days after it was sent.",
    invitation_link_not_valid: "The invitation link is not valid!",
    invitation_link_not_valid2:
      "Click the button from the invitation email again or copy the invitation link.",
    accept_request: "Accept request",
    close: "Close",
    request_accepted: "The request was successfully accepted",
    companyEmail: "Company Email",
    companyPhone: "Company Phone",
    addBusinessInformations: "Add business informations",
    resetFilter: "Reset",
    selectMarkers: "Select Markers",
    filterConnectionsHeader: "Filter Connections",
    selectConnectionType: "Select Connection Type",
    all: "All",
    direct: "Direct",
    form: "Form",
    manually: "Manually",
    scan: "Scan",
    sortOptions: "Sort Options",
    date: "Creation Date",
    fullName: "Full Name",
    descending: "Descending",
    ascending: "Ascending",
    selectDateRange: "Select Date Range",
    follow_up_reminder: "Follow Up Reminder",
    now: "Now",
    one_hour: "1 Hour",
    one_day: "1 Day",
    three_days: "3 Days",
    one_week: "1 Week",
    two_weeks: "2 Weeks",
    one_month: "1 Month",
    custom: "Choose time",
    set_reminder: "Set Reminder",
    select_date_time: "Seleccione la fecha y la hora",
    enter_reminder_text: "Introduzca el texto del recordatorio",
    reminder_created: "Reminder successfully created",
    connectionNameLabel: "Enter connection Name",
    connectionEmailLabel: "Enter private email address",
    privatePhoneLabel: "Enter private phone number",
    companyNameLabel: "Enter company name",
    businessEmail: "Business email address",
    businessEmailLabel: "Enter business email address",
    businessPhone: "Business phone number",
    businessPhoneLabel: "Enter business phone number",
    noteLabel: "Enter note",
    markersLabel: "Add connection markers",
    showScan: "Show Scan",
    hideScan: "Hide Scan",
    create_connection: "Create connection",
    create_manually: "Create Manually",
    create_using_scanner: "Scan paper business card",
    companyTitle: "Job Title",
    companyTitleLabel: "Enter Job Title",
    allConnectionsLabel: "All connection types",
    directConnectionsLabel: "Direct in-app connection with Tapni Users",
    formConnectionsLabel: "Connections filled on connect form",
    manualConnectionsLabel: "Manually created connections",
    scanConnectionsLabel: "Card scanner created connections",
  },
};
