<template>
  <div class="menu-wrapper menu-light menu-modal activate-page center-text" :class="{'active-menu': toggle}" style="overflow-y: hidden; height: 50vh">
    <a  @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
      <i class="font-17 fa color-black">
        <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
      </i>
    </a>
    <div class="full-top">
      <img :src="'https://cdn.tapni.co/icons/privacy.png'" class="forbidden-img small-bottom" :alt="lang[this.appLang].forbidden_rule"/>
      <p class="close-text close-menu center-text full-bottom font-18" style="width: 80%; margin: 1em auto;"><br>{{lang[this.appLang].forbidden_rule}}</p>
    </div>
  </div>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapActions} from 'vuex'
export default {
  name: 'ForbiddenRuleModal',
  data () {
    return {
      loading: false,
      toggle: false,
    }
  },
  computed: {
  },
  mounted () {
    EventBus.$on('closeModal', () => { this.toggle = false })
    EventBus.$on('toggleForbiddenRuleModal', this.toggleModal)
  },
  methods: {
    toggleModal () {
      this.toggle = !this.toggle
    },
    close () {
      EventBus.$emit('closeModal')
    }
  }
}
</script>

<style scoped>

.menu-wrapper {
  margin-top: -172.5px;
  padding: 0;
}

.forbidden-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 65px;
}
</style>
