<template>
  <div
      class="menu-wrapper menu-light menu-modal activate-page center-text"
      :class="{ 'active-menu': toggle }"
      style="height: auto; max-height: 90vh; margin-top: -172.5px"
  >
    <a
        @click="close"
        class="color-black pull-right pointer"
        style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
            src="https://cdn.tapni.co/icons/down-arrow.png"
            class="fa responsive-image"
            style="width: 45%"
        />
      </i>
    </a>
    <h1
        class="bold small-top left-text headerText"
        style="padding-left: 5px"
        v-if="!loader"
    >
      {{ lang[this.appLang].crop_image}}
    </h1>

    <!-- LOADER -->
    <div v-if="loader" class="action-block-top full-top" style="height: 40vh">
      <div class="loader-wrapper">
        <img src="https://cdn.tapni.co/images/save-planet.gif" width="200" height="200" style="margin: 0 auto;"/>
        <p style="margin-top: 20px; position: absolute; text-align: center; width: 100%; font-weight: 600; font-size:20px;">Analyzing...</p>
        <p style="margin-top: 48px; position: absolute; text-align: center; width: 100%; font-size:16px;">Save the planet, switch to digital business cards.</p>
      </div>
    </div>

    <!--- Crop image modal--->
    <div v-show="!loader" style="margin-top: 50px;">
      <img
          ref="editImage"
          :src="'data:image/jpeg;base64, ' + imageBase64"
          style="width: 100%; max-height: 65vh"
      />
    </div>
    <div class="modal-footer half-top center-text" style="display: flex; justify-content: space-between" v-show="!loader">
      <!--<button class="button button-red button-round button-xs pull-left edit-shadow pointer">{{lang[this.appLang].delete}}</button>-->
      <!--<button type="submit" class="button button-green button-round button-xs pull-right edit-shadow" @click="onCrop">{{lang[this.appLang].crop_upload}}</button> -->
      <button
          type="submit"
          class="
          button
          white-button
          small-top
          half-bottom
          pointer
          red-text
        "
          style="width: auto;"
          @click="close"
      >
        {{ lang[this.appLang].cancel }}
      </button>
      <button
          type="submit"
          class="button black-button small-top  half-bottom pointer"
          style="margin-right: 0; width: 55%"
          @click="onCrop"
      >
        {{ lang[this.appLang].crop_upload }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { EventBus } from "../../store/event-bus";
import  {CapacitorPluginMlKitTextRecognition}  from '@pantrist/capacitor-plugin-ml-kit-text-recognition'
import Cropper from "cropperjs";
import UtilService from '@/services/UtilService';

export default {
  name: "ScanCropper",
  components: {},
  data() {
    return {
      imageBase64: "",
      cropper: null,
      toggle: false,
      loader: false,
    };
  },
  mounted() {
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
    EventBus.$on("toggleScanCropperModal", this.toggleModal);
  },
  methods: {
    ...mapActions(["createCardScan"]),
    close() {
      EventBus.$emit("closeModal");
    },
    toggleModal(image) {
      if (image) {
        this.imageBase64 = image;
        setTimeout(() => {
          this.showCropper();
        }, 0);
      }
      this.toggle = !this.toggle;
    },
    showCropper() {
      if (this.cropper) {
        this.cropper.destroy();
      }
      this.cropper = new Cropper(this.$refs.editImage, {
        minCropBoxWidth: 100,
        autoCrop: true,
        autoCropArea: 1,
        viewMode: 1,
        checkOrientation: false,
        zoomable: true,
      });
    },
    async onCrop() {
      this.loader = true;
      const base64Image = this.cropper.getCroppedCanvas().toDataURL();
      let postData = { image1: base64Image, text: null }
      if (this.isNative) {
        let extractedText = await CapacitorPluginMlKitTextRecognition.detectText({base64Image: base64Image.split(",")[1]})
        if(extractedText.text) {
          postData.text = extractedText.text;
          let blobImg = UtilService.base64ImageToBlob(base64Image);
          let compBlobImg = await UtilService.compressImage(blobImg)
          let compBase64Img = await UtilService.blobToBase64(compBlobImg)
          postData = { image1: compBase64Img, text: extractedText.text }
        }
      }

      const response = await this.createCardScan(postData);
      this.loader = false;

      let preparedData = {
        addConnection: true,
        name: "",
        email: "",
        email2: "",
        phone: "",
        phone2: "",
        company: "",
        address: "",
        website: "",
        website2: "",
        //note: response.data?.comment || "",
        note: "",
        scanID: response.data?.id || ""
      };

      if(response.data?.name) preparedData.name = response.data.name[0].toUpperCase() + response.data.name.substring(1);
      if(response.data?.email) preparedData.email = response.data.email.toLowerCase();
      if(response.data?.email2) preparedData.email2 = response.data.email2.toLowerCase();
      if(response.data?.phone) preparedData.phone = response.data.phone.replace(/\s/g, '')
      if(response.data?.phone2) preparedData.phone2 = response.data.phone2.replace(/\s/g, '')
      if(response.data?.company) preparedData.company = response.data.company[0].toUpperCase() + response.data.company.substring(1);
      if(response.data?.address) preparedData.address = response.data.address
      if(response.data?.website) {
        preparedData.website = response.data.website
        if(!preparedData.website.startsWith("http")) preparedData.website = "https://" + preparedData.website;
      }
      if(response.data?.website2) {
        preparedData.website2 = response.data.website2
        if(!preparedData.website2.startsWith("http")) preparedData.website2 = "https://" + preparedData.website2;
      }

      // email, name, company, phone, phone2, website, website1, address ,comment
      EventBus.$emit("closeModal");
      EventBus.$emit("toggleConnectionModal", preparedData);
    },
  },
};
</script>

<style>
.loader-wrapper {
  margin-top: 10%;
}

</style>
