<template>
  <form
    @submit.prevent="submit"
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="height: 280px; margin-top: -172.5px"
  >
    <a
      @click="forceClose"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>
    <h3
      v-if="!findCompanySubdomain"
      class="bold center-text color-black half-top half-bottom"
    >
      {{ lang[this.appLang].sign_in_with }} SSO
    </h3>
    <h3 v-else class="bold center-text color-black half-top half-bottom">
      {{ lang[this.appLang].search_company_domain }}
    </h3>
    <div v-if="!findCompanySubdomain" style="">
      <input
        type="text"
        v-model="companySubdomain"
        :placeholder="lang[this.appLang].company_domain"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        class="edit-input h-40 lh-40"
        required
        @keydown.space.prevent
        style="
          float: left;
          z-index: 0;
          text-align: right;
          padding-right: 150px;
          width: 100%;
        "
      />
      <span
        style="
          float: left;
          padding: 4px 0 0 5px;
          font-size: 15px;
          margin-left: -154px;
          z-index: 1;
          position: inherit;
        "
        >.tapni.co</span
      >
      <br />
    </div>

    <div v-else>
      <input
        type="text"
        v-model="email"
        :placeholder="lang[this.appLang].email"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        class="edit-input h-40 lh-40"
        required
        @keydown.space.prevent
      />
    </div>
    <a
      v-if="!findCompanySubdomain"
      class="small-text small-top-padding half-top full-bottom color-black"
      style="text-decoration: underline"
      @click="findCompanySubdomain = !findCompanySubdomain"
    >
      {{ lang[this.appLang].idk_company_domain }}
    </a>
    <a
      v-else
      class="small-text small-top-padding full-bottom color-black"
      style="text-decoration: underline; margin-top: 6px"
      @click="findCompanySubdomain = !findCompanySubdomain"
    >
      {{ lang[this.appLang].ik_company_domain }}
    </a>

    <button
      type="submit"
      class="button white-button button-full"
      style="margin: 0 auto; margin-top: 13px"
    >
      {{ lang[this.appLang].continue }}
    </button>
  </form>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { mapActions } from "vuex";
export default {
  name: "SSO",
  data() {
    return {
      toggle: false,
      findCompanySubdomain: true,
      email: "",
      companySubdomain: "",
    };
  },
  mounted() {
    EventBus.$on("toggleSSOModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  methods: {
    ...mapActions([
      "saveProfile",
      "getLoggedInAccounts",
      "getUser",
      "getCompanyBySubdomain",
    ]),
    forceClose() {
      EventBus.$emit("closeModal");
    },
    close() {
      this.toggleModal();
    },
    async submit() {
      const response = await this.getCompanyBySubdomain({
        subdomain: !this.findCompanySubdomain
          ? this.companySubdomain
          : this.email,
      });
      if (!response || !response.name) {
        return this.errorHandler({
          message: this.lang[this.appLang].company_not_found,
        });
      } else {
        if (this.route.name === "AuthRegister") {
          this.$router.push("/login");
        }
        this.forceClose();
        this.$forceUpdate();
      }
    },
    toggleModal() {
      this.toggle = !this.toggle;
    },
  },
};
</script>

<style scoped>
.sso-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 35px;
}
input {
  margin: 0 auto;
  height: 25px;
}
</style>
