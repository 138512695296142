<template>
	<div class="center-text" style="padding: 10px;">
	  <h1 class="center-text full-top">
		  <b>{{lang[this.appLang].offline_p1}}</b>
	  </h1>
	  <p class="center-text half-top">
		  {{lang[this.appLang].offline_p2}}
	  </p>
	  <br>
	  <a @click="reload" style="z-index: 999; margin: 0;" class="button white-button">
		  {{lang[this.appLang].refresh}}
	  </a>
    <div id="qr-code-offline" class="center-text small-bottom" style="margin-top: 10px;"></div>
    <p class="center-text" v-if="vcard">{{lang[this.appLang].offline_p3_1}}<br>{{lang[this.appLang].offline_p3_2}}</p>
	</div>
</template>

<script>
  /* eslint-disable */
  import UtilService from "../services/UtilService";
  import QRCodeStyling from 'qr-code-styling';

  export default {
  name: 'ErrorOffline',
    data () {
      return {
        vcard: ''
      }
    },
    methods: {
      init () {
        this.$store.commit('setInitialize', true)
        this.$store.commit('setLoading', false)
        if (this.storage.localUser && this.storage.localUser.vcard && (Object.keys(this.storage.localUser.vcard).length > 0)) {

          this.vcard = UtilService.generateVcard(this.storage.localUser.vcard)
          const qrCode = new QRCodeStyling({
            width: 320,
            height: 320,
            data: this.vcard,
            margin: 0,
            dotsOptions: {
              color: "#000000",
              type: "extra-rounded",
            },
            backgroundOptions: {
              color: "#efefef00",
            },
            imageOptions: {
              crossOrigin: "anonymous",
              imageSize: 0.5,
            },
            cornersSquareOptions: {
              type: "extra-rounded",
            },
          });

          qrCode.append(document.getElementById("qr-code-offline"));
        }

      },
      reload () {
        location.reload()
      }
    },
    mounted () {
      if (this.route) this.init()
    },
    watch: {
      'route.name' (nv, ov) {
        if (!ov && nv) this.init()
      },
    }
}
</script>

<style scoped>

</style>
