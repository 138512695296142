<template>
  <a @click="click" class="disable-select" :class="linkStyle + (disabled ? ' opacity-50' : '')" :style="(editing ? 'cursor: move' : 'cursor: pointer') + '!important; overflow: visible;'">

	<span v-if="editing && data.is_direct" @click="toggleLinkModal(data)" class="edit-shadow direct-icon pointer">
		<img :src="'https://cdn.tapni.co/icons/direct.png'" class="responsive-image" :alt="data.text"/>
	</span>

	<span v-if="editing" @click="editLink(data)" class="edit-shadow edit-icon pointer">
		<img :src="'https://cdn.tapni.co/icons/edit.png'" :alt="data.text" class="responsive-image"/>
	</span>

	<span v-if="data.type !== 'addlink' && !editing && linkStyle === 'link-list' && !switching && !hideExternalIcon" class="edit-shadow edit-icon pointer" style="width: 30px!important; top: 26.5%">
		<img :src="'https://cdn.tapni.co/icons/link-external.png'" :alt="data.text" class="responsive-image" />
	</span>
	<span v-if="data.type !== 'addlink' && !editing && linkStyle === 'link-list' && switching && !hideExternalIcon" :class="{'edit-icon pointer': true, 'switching': switching, 'edit-shadow': !switching}" >
	  <div class="ios-switch">
	    <input v-model="data.switching" type="checkbox"
	           name="ios-switch" class="ios-switch-checkbox" :id="data.type + '-switch'"/>
		<label class="ios-switch-label" :for="data.type + '-switch'"></label>
	  </div>
	</span>
	<span  v-if="data.type === 'addlink' && !editing && linkStyle === 'link-list'" class="edit-shadow edit-icon pointer" >
        <img :src="'https://cdn.tapni.co/icons/addlink.png'" :alt="data.text" class="responsive-image"/>
	</span>

	<span :class="{'user-link-img': true, 'pulse pulse-border': pulse, 'link-drag': editing }">
	  <img v-if="!data.custom_icon && data.type !== 'customlink'" :src="'https://cdn.tapni.co/icons/' + data.type + '.png'" class="responsive-image" :alt="data.text" @load="$emit('loaded')" @error="$emit('loaded')"/>
	  <img v-else-if="!data.custom_icon && data.type === 'customlink' && linkAdding" :src="'https://cdn.tapni.co/icons/custom-icon-128.gif'" class="responsive-image" :alt="data.text" @load="$emit('loaded')" @error="$emit('loaded')"/>
	  <img v-else-if="!data.custom_icon && data.type === 'customlink' && !linkAdding" :src="'https://cdn.tapni.co/icons/customlink.png'" class="responsive-image" :alt="data.text" @load="$emit('loaded')" @error="$emit('loaded')"/>
	  <img v-else :src="data.style.custom_icon" style="border-radius: 22.46%;" class="responsive-image" :alt="data.text" @load="$emit('loaded')" @error="$emit('loaded')"/>
	</span>

    <span class="link-text link-drag color-black">
      {{linkText}}
    </span>
  </a>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {EventBus} from '../store/event-bus'
export default {
  name: 'LinkIcon',
  props: {
    linkAdding: {
      type: Boolean,
      required: false,
      default: false
    },
	hideExternalIcon: {
	  type: Boolean,
	  required: false,
	  default: false
	},
	switching: {
	  type: Boolean,
	  required: false,
	  default: false
	},
    pulse: {
      type: Boolean,
      required: false,
      default: false
    },
    editing: {
      type: Boolean,
      required: false,
      default: true
    },
    directPick: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: String,
      required: false,
      default: ''
    },
    linkStyle: {
      type: String,
      required: false,
      default: 'link-grid'
    },
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
  	linkText () {
  		if (this.appLang !== 'en' &&  this.data.text) {
  			return this.lang[this.appLang]['link_text_' + this.data.text.toLowerCase().trim().replace(/ /g, '_')] || this.data.text
		  }
  		return this.data.text
	  },
    rules (){
      return this.$store.getters.rules
    }
  },
  methods: {
    ...mapActions(['linkClick']),
    click () {
      if (this.switching) {
	      return this.data.switching = !this.data.switching
      }

      if (this.data.id && !this.editing && !this.directPick) {
        this.linkClick(this.data)
        this.redirectLink(this.data)
      } else if (this.to) {
        if(!this.rules.addLinks) return EventBus.$emit('toggleForbiddenRuleModal')
        else this.$router.push(this.to)
      } else if (this.directPick) {
        this.$emit('directPick', this.data)
      }
    },
    editLink(data){
      if(!this.rules.editCompanyLinks && data.CompanyId) return EventBus.$emit('toggleForbiddenRuleModal')
      this.toggleLinkModal(data)
    },
    toggleLinkModal (link) {
      if (link.type === 'contactcard') EventBus.$emit('toggleContactCardModal', link)
      else if (link.type === 'googlereview') EventBus.$emit('toggleGoogleReviewModal', link)
      else EventBus.$emit('toggleLinkModal', link)
    }
  }
}
</script>

<style>
  /* ********* */
  /* Link Grid */
  /* ********* */
  .link-grid, .user-link-img {
	width: 100%;
	display: inline-table;
    position: relative;
  }

  .link-grid{
	width: 30%;
	margin: 1.6%;
	padding: 1.6%;
  }

  .link-grid .user-link-img img {
	  /* box-shadow: 0px 2px 10px rgba(200,200,200,0.8); */
    border: 1px solid #e8e8e8;
	  border-radius: 25%!important;
  }

  .link-grid .edit-icon {
    border-radius: 20px;
    right: -3%; top: -3%;
    position: absolute;
    z-index:555;
    max-width: 23%;
	overflow: hidden;
	min-width: 30px;
  }

  .link-grid .direct-icon {
    border-radius: 20px;
    left: -3%; top: -3%;
    position: absolute;
    z-index:555;
    max-width: 23%;
	overflow: hidden;
	min-width: 30px;
  }

  .link-grid .link-text {
    min-height: 20px;
    margin-top:3px!important;
    display:block;
    text-align: center;
	line-height: normal;
	overflow-wrap: anywhere;
  }

  /* ********* */
  /* Link List */
  /* ********* */

  .link-list{
    width: 93%;
	  max-height: 67px!important;
    margin: 0px;
    display: inline-block;
    border-radius:15px!important;
    background-color:#FFFFFF;
    /* box-shadow: 0px 2px 5px rgba(0,0,0,0.15); */
    border: solid 1px #e8e8e8;
    position: relative;
  }

  .link-list .user-link-img {
    float: left;
    display: inline-block;
    width: 65px;
    /* box-shadow: 0px 3px 6px rgba(0,0,0,0.16); */
  	border-radius: 25%!important;
  }

  .link-list .link-text {
    display:inline-block;
    float: left;
    padding: 18px 0% 17px 10%;
	  font-size: 15px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 75%;
    text-align: left;
  }

  .link-list .edit-icon {
    border-radius: 20px;
    right: 7%; top: 30%;
    position: absolute;
    z-index:555;
    width: 26px;
	overflow: hidden;
  }

  .link-list .switching {
	  width: 45px;
  }

  .link-list .direct-icon {
    border-radius: 20px;
    left: -2%; top: -13%;
    position: absolute;
    z-index:555;
	width: 26px;
	overflow: hidden;
  }
  /* ********* */

  .small{
	  width: 22%!important;
	  margin: 2% 4% 0%;
	  padding: 0%;
  }

  .small .link-text {
	  margin-top: 0px!important;
	  font-size: 13px;
  }
</style>
