<template>
  <form class="page-login content-boxed content-boxed-padding" @submit.prevent="submit" autocomplete="on" :style="isModal ? 'max-width: 500px;' : ''">
	<a v-if="isModal" @click="close" class="color-black pull-right pointer" style="margin-top: 0px; position: absolute; right: 20px; text-align: right;">
	  <i class="font-17 fa color-black">
		  <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 35%;"/>
	  </i>
	</a>
    <h1 class="bold small-top no-bottom center-text">{{this.lang[this.appLang].login}}</h1>
    <p class="half-bottom small-top center-text color-black"></p>

	<span v-if="displayFormLogin">
	  <div class="page-login-field half-top">
	    <i class="fa" style="padding-top: 2px;">
	  	  <img src="https://cdn.tapni.co/icons/user.png" class="fa responsive-image" style="width: 50%;">
	    </i>
	    <input v-model="emailOrUsername" @keydown.space.prevent
	  		 autocorrect="off" autocapitalize="off" spellcheck="false"
	  		 type="text" name="email" :placeholder="lang[this.appLang].email_username" required>
	    <em></em>
	  </div>
	  <div class="page-login-field half-bottom">
	    <i class="fa" style="padding-top: 2px;">
	  	  <img src="https://cdn.tapni.co/icons/padlock.png" class="fa responsive-image" style="width: 50%;">
	    </i>
	    <input v-model="password" :type="revealPassword ? 'text' : 'password'" :placeholder="lang[this.appLang].password" name="password" required minlength="8">
	    <em @click="revealPassword = !revealPassword" class="pointer">
	  	  <img v-show="!revealPassword" src="https://cdn.tapni.co/icons/reveal-password.png" class="fa responsive-image password-reveal-icon">
	  	  <img v-show="revealPassword" src="https://cdn.tapni.co/icons/hide-password.png" class="fa responsive-image password-reveal-icon">
	    </em>
	  </div>
	  <button type="submit" class="button google-button bg-tapni-grey button-90 button-center uppercase bold full-top">
	    <span v-if="!submitted">{{lang[this.appLang].login}}</span>
	    <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 15px auto 0;width: 100%; text-align: center; font-size: 20px;"></i>
	  </button>
	</span>
	<div class="decoration decoration-lines-thin half-top half-bottom"></div>

	<!-- Microsoft Login Button -->
	<a @click="microsoftLogin" v-if="displayMicrosoftLogin"
	   class="button-center button button-90 google-button pointer">
		<img src="https://cdn.tapni.co/icons/microsoft.png" style="position: absolute; margin-left: -8px; padding: 10px 0; height: 100%;"/>
		<span v-if="!microsoftLoad">{{lang[this.appLang].sign_in_with}} Microsoft</span>
		<i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 11px auto 0;width: 100%; text-align: center; font-size: 24px;"></i>
	</a>

	<!-- Apple Login Button -->
	<a @click="appleLogin" v-if="displayAppleLogin"
	   class="button-center button button-90 google-button pointer">
		<img src="https://cdn.tapni.co/icons/apple-logo.png" style="position: absolute; margin-left: -11px; padding: 8px 0; height: 100%;"/>
		<span v-if="!appleLoad">{{lang[this.appLang].sign_in_with}} Apple</span>
		<i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 11px auto 0;width: 100%; text-align: center; font-size: 24px;"></i>
	</a>

	<!-- Google Login Button -->
	<a @click="googleLogin" v-if="displayGoogleLogin"
	 class="button-center button button-90 google-button pointer">
	  <img src="https://cdn.tapni.co/icons/g-logo.png" style="position: absolute; margin-left: -10px; padding: 8px 0; height: 100%;"/>
	  <span v-if="!googleLoad">{{lang[this.appLang].sign_in_with}} Google</span>
	  <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 11px auto 0;width: 100%; text-align: center; font-size: 24px;"></i>
	</a>

	<!-- Facebook Login Button -->
	<a @click="facebookLogin" v-if="displayFacebookLogin"
	   class="button-center button button-90 google-button pointer">
		<img src="https://cdn.tapni.co/icons/facebook-blue.png" style="position: absolute; margin-left: -9px; padding: 10px 0; height: 100%;"/>
		<span v-if="!facebookLoad">{{lang[this.appLang].sign_in_with}} Facebook</span>
		<i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 11px auto 0;width: 100%; text-align: center; font-size: 24px;"></i>
	</a>

	<!-- SSO Login Button -->
	<a @click="ssoLogin" v-if="!displayMicrosoftLogin"
	   class="button-center button button-90 google-button pointer">
		<img src="https://cdn.tapni.co/icons/sso-key.png" style="position: absolute; margin-left: -9px; padding: 10px 0; height: 100%;"/>
		<span>{{lang[this.appLang].sign_in_with}} SSO</span>
	</a>

	<!-- Email Login Button -->
	<a @click="emailLogin = true" v-if="!displayFormLogin"
	   class="button-center button button-90 google-button pointer">
		<img src="https://cdn.tapni.co/icons/mail.png" style="position: absolute; margin-left: -8px; padding: 12px 0; height: 100%;"/>
		<span>{{lang[this.appLang].sign_in_with}} Email</span>
	</a>

	<div class="decoration decoration-lines-thin no-bottom"></div>

	<p class="close-text center-text half-top color-black">
		{{lang[this.appLang].terms_by_signing_in}} <br>
		<a class="" href="https://tapni.co/policies/terms-of-service">{{lang[this.appLang].terms_of_service}}</a> {{lang[this.appLang].and}} <a href="https://tapni.co/policies/privacy-policy">{{lang[this.appLang].privacy_policy}}</a>
	</p>
	<div class="decoration decoration-lines-thin no-bottom"></div>
	<div v-if="!isModal" class="page-login-links">
	  <router-link v-if="displayRegisterLogin" class="forgot float-right" to="/register">{{lang[this.appLang].create_account}}</router-link>
	  <router-link v-if="displayResetPasswordLogin" class="create float-left" to="/reset">{{lang[this.appLang].reset_password}}</router-link>
	  <div class="clear"></div>
	</div>
	<div v-else class="page-login-links" @click="createAccountModal">
	  <a class="forgot center-text pointer small-top">{{lang[this.appLang].or}} <br> {{lang[this.appLang].create_new_account}}</a>
	  <div class="clear"></div>
	</div>
  </form>
</template>

<script>
/* eslint-disable */
import GoogleMixin from '../mixins/google.mixin'
import FacebookMixin from '../mixins/facebook.mixin'
import AppleMixin from '../mixins/apple.mixin'
import MicrosoftMixin from '../mixins/microsoft.mixin'
import { mapActions } from 'vuex'
import {EventBus} from "../store/event-bus";
export default {
  name: 'AuthLogin',
  mixins: [GoogleMixin, FacebookMixin, AppleMixin, MicrosoftMixin],
  props: {
	isModal: {
	  type: Boolean,
	  required: false,
	  default: false
	},
  },
  data () {
    return {
      loading: true,
      submitted: false,
      emailOrUsername: '',
      password: '',
      captcha: '',
      revealPassword: false,
	  emailLogin: false
    }
  },
  computed: {
	  displayFormLogin () {
	  	return (this.company?.login?.form_login && !this.isModal) ?? this.emailLogin;
	  },
	  displayResetPasswordLogin () {
		  return (this.company?.login?.reset_password && !this.isModal) ?? true;
	  },
	  displayRegisterLogin () {
		  return (this.company?.login?.create_account && !this.isModal) ?? true;
	  }
  },
  mounted () {
  	if (!this.isModal) {
	  if (this.isLoggedIn) {
	  	this.$router.push(this.home)
	  } else if (!this.route.query.state && !this.$route.query.oauth && !this.$route.params.oauth) {
	  	this.$store.commit('setLoading', false);
	  }
	  this.$store.commit('setInitialize', true);
	}
  },
  methods: {
    ...mapActions(['login']),
    submit() {
      // if (this.captcha === '') return this._vm.errorHandler({ message: 'Please select recaptcha' });
      if (this.submitted) return
      this.submitted = true

	  this.emailOrUsername = this.emailOrUsername.trim()

      let loginData = {
      	isModal: this.isModal,
        captcha: this.captcha,
        password: this.password
      };
      if (this.emailOrUsername.indexOf('@') !== -1) {
        loginData.email = this.emailOrUsername;
      } else {
        loginData.username = this.emailOrUsername;
      }

      this.login(loginData).then((result) => {
        this.submitted = false

		if (result === true) {
			this.emailOrUsername = ''
			this.password = ''
		}
      })
    },
    close () {
		EventBus.$emit('toggleAddAccountModal', false)
	},
	createAccountModal () {
		EventBus.$emit('toggleAddAccountModalRegister')
	}
  },
  watch: {
	emailOrUsername(nv) {
	  if (nv.includes('@')) {
	    this.storage.verifyEmail = nv
	  }
	}
  }
}
</script>

<style scoped>
</style>
