<template>
  <div
      class="menu-wrapper menu-light menu-modal center-text activate-page"
      :class="{ 'active-menu': toggle }"
      style="height: auto; max-height: 92vh; margin-top: -172.5px"
  >
    <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 15px; text-align: right;">
      <i class="font-17 fa color-black">
        <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
      </i>
    </a>

    <!--
    <img :src="'https://cdn.tapni.co/icons/reminder.png'" class="connections-img" :alt="lang[this.appLang].my_connections"/>
    <h4 class="small-bottom">{{ lang[this.appLang].follow_up_reminder }}</h4>
    -->

    <h1 class="left-text bold small-top half-bottom headerText" style="padding-left: 5px">
      {{ lang[this.appLang].follow_up_reminder }}
    </h1>

    <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">
        <div class="half-top" style="width: 48%">
          <button @click="minutes = 0" class="button white-button button-full pointer" :class="{ 'active-button': (minutes === 0) }">
            {{lang[this.appLang].now}}
          </button>
        </div>
        <div class="half-top" style="width: 48%">
          <button @click="minutes = 60" class="button white-button button-full pointer" :class="{ 'active-button': (minutes === 60) }">
          {{lang[this.appLang].one_hour}}
          </button>
        </div>
        <div class="half-top" style="width: 48%">
          <button @click="minutes = 1440" class="button white-button button-full pointer" :class="{ 'active-button': (minutes === 1440) }">
            {{lang[this.appLang].one_day}}
          </button>
        </div>
        <div class="half-top" style="width: 48%">
          <button @click="minutes = 4320" class="button white-button button-full pointer" :class="{ 'active-button': (minutes === 4320) }">
            {{lang[this.appLang].three_days}}
          </button>
        </div>
        <div class="half-top" style="width: 48%">
          <button @click="minutes = 10080" class="button white-button button-full pointer" :class="{ 'active-button': (minutes === 10080) }">
            {{lang[this.appLang].one_week}}
          </button>
        </div>
        <div class="half-top" style="width: 48%">
          <button @click="minutes = 20160" class="button white-button button-full pointer" :class="{ 'active-button': (minutes === 20160) }">
            {{lang[this.appLang].two_weeks}}
          </button>
        </div>
        <div class="half-top" style="width: 48%">
          <button @click="minutes = 43200" class="button white-button button-full pointer" :class="{ 'active-button': (minutes === 43200) }">
            {{lang[this.appLang].one_month}}
          </button>
        </div>
        <div class="half-top" style="width: 48%">
          <button @click="minutes = 'custom'" class="button white-button button-full pointer" :class="{ 'active-button': (minutes === 'custom') }">
            {{lang[this.appLang].custom}}
          </button>
        </div>
      </div>
      <input
          type="text"
          class="edit-input full-top half-bottom"
          v-model="customTime"
          id="datetime-input"
          :placeholder="lang[this.appLang].select_date_time"
          style="width: 100%"
          v-show="minutes === 'custom'"
      />

      <date-picker
          type="datetime"
          element="datetime-input"
          v-model="customTime"
          color="#000000"
          :calendar="'gregory'"
          displayFormat= "YYYY-MM-DD HH:mm"
          :jumpMinute="5"
          :roundMinute="true"
          :min='$moment().format("yyyy/MM/DD HH:mm")'
      />


      <textarea v-model='reminderText' :placeholder="lang[this.appLang].enter_reminder_text" class="edit-input full-bottom half-top" rows="4"  wrap="hard" maxlength="150" style=" width: 100%; margin: 0 auto; min-height: 65px; resize: none; line-height: 20px;" @keydown="$event.keyCode === 13 ? $event.preventDefault() : false"/>
      <div class="small-top half-bottom" style="width: 100%">
        <button class="button black-button button-full pointer" @click="setReminder">
          {{lang[this.appLang].set_reminder}}
        </button>
      </div>
  </div>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { LocalNotifications } from '@capacitor/local-notifications';
import VueDatetimeJs from 'vue-datetime-js';
import UserService from "../../services/UserService";
import { Filesystem, Directory } from '@capacitor/filesystem';


export default {
  components: {
    datePicker: VueDatetimeJs
  },
  data() {
    return {
      toggle: false,
      minutes: 0,
      reminderText: '',
      name: '',
      username: '',
      photo: null,
      email: '',
      customTime: ''
    };
  },
  mounted() {
    EventBus.$on("toggleFollowUpScheduleModal", (data) => {
      LocalNotifications.requestPermissions();
      this.toggle = true;
      this.minutes = 0;
      let conn = data;
      this.customTime = this.$moment().add(1, 'days').format("yyyy/MM/DD HH:mm");


      if (conn.status === "connected") {
        if (
            conn.receiver &&
            conn.receiver.id !== this.storage.UserId
        ) {
          this.name = conn.receiver.name || "@" + conn.receiver.username;
          this.username = conn.receiver.username;
          this.photo = conn.receiver.photo;
        }

        if (
            conn.requester &&
            conn.requester.id !== this.storage.UserId
        ) {
          this.name = conn.requester.name || "@" + conn.requester.username;
          this.username = conn.requester.username;
          this.photo = conn.requester.photo;
        }
      } else {
        this.name = conn.payload.name || conn.payload.email;
        this.username = null;
        this.email = conn.payload.email || ''
        this.photo = null;
      }
      this.reminderText = 'This is a reminder to follow up with ' + this.name;
    });
    EventBus.$on("closeModal", () => {
      this.toggle = false;
    });
  },
  methods: {
    close () {
      EventBus.$emit("closeModal");
    },
    async setReminder() {

      //add one more second more, for case when 0 minutes are. schedule time must be now + 1 sec minimum
      let at;
      if (this.minutes === 'custom' && this.customTime) at = new Date(this.$moment(this.customTime, "yyyy/MM/DD HH:mm").valueOf())
      else  at =  new Date(new Date().getTime() + (this.minutes * 60000) + 1000)

      const id = new Date().getTime()

      let data = {
        notifications: [{
          id,
          title: 'Follow Up Reminder',
          body: this.reminderText,
          sound: null,
          data: {username: this.username, name: this.name, email: this.email},
          extra: {username: this.username, name: this.name, email: this.email},
          schedule: { at: at },
        }]
      }


      if(this.photo && Capacitor.getPlatform() === 'ios') {
        let img = await UserService.getFile(this.photo)
        let imgBase64 = Buffer.from(img.data, 'binary').toString('base64')
        const fileName = id.toString() + '.jpeg';

        const savedFile = await Filesystem.writeFile({
          path: fileName,
          data: imgBase64,
          directory: Directory.Data
        });

        data.notifications[0].attachments = [{id: id.toString(), url: savedFile.uri}]
      }

      LocalNotifications.schedule(data);

      this.successSnack(this.lang[this.appLang].reminder_created)
      this.close();
    },

  },
};
</script>

<style>

.connections-img {
  margin: 0 auto;
  margin-top: 2px;
  width: 65px;
}

.active-button{
  border: 1px solid #000000;
}

.vpd-content{
  border-radius: 20px;
}

.vpd-header{
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.vpd-actions button:nth-child(3) {
  display: none;
}
</style>
