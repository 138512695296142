<template>
    <form @submit.prevent="submit" class="menu-wrapper menu-light menu-modal center-text activate-page" :class="{'active-menu': toggle}" style="height: auto; margin-top: -172.5px;">
      <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
	      <i class="font-17 fa color-black">
		      <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
	      </i>
      </a>
      <img :src="'https://cdn.tapni.co/icons/profile.png'" class="user-img half-bottom" :alt="lang[this.appLang].change_username"/>
      <span>
        <input v-if="user" type="text" v-model="username" :placeholder="lang[this.appLang].username"
             class="edit-input h-40 lh-40" style="width: 100%" required @keydown.space.prevent />
	      <p class="small-text small-top-padding half-bottom">{{lang[this.appLang].new_username}}</p>

        <button type="submit" class="button white-button button-full half-bottom" style="margin: 0 auto; margin-top: 13px;">
          {{lang[this.appLang].update_username}}
        </button>
      </span>
    </form>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapState, mapActions} from 'vuex'
export default {
  name: 'Username',
  data () {
    return {
      toggle: false,
      username: ''
    }
  },
  mounted () {
    EventBus.$on('toggleUsernameModal', this.toggleModal)
    EventBus.$on('closeModal', () => { this.toggle = false })
  },
  computed: {
    rules() {
      return this.$store.getters.rules
    }
  },
  methods: {
    ...mapActions(['saveProfile', 'getLoggedInAccounts', 'getUser']),
    forceClose () {
      EventBus.$emit('closeModal')
    },
    close () {
      this.toggleModal()
      EventBus.$emit('toggleProfileModal', false)
    },
    async submit () {
      if (!this.username) return this.errorSnack(this.lang[this.appLang].username_check);

      const result = await this.saveProfile({ username: this.username, preventTopOver: true })
      if (result === true) {
        this.successSnack(this.lang[this.appLang].change_username_success)
        await this.getLoggedInAccounts(this.storage.refreshTokens.split(','))
        EventBus.$emit('closeModal')
        this.$router.push('/' + this.username + '#preview')
        await this.getUser({ username: this.storage.username })
        this.storage.username = this.username
      }
    },
    toggleModal () {
      this.toggle = !this.toggle
      this.username = this.user.username
    }
  }
}
</script>

<style scoped>
  .user-img {
    margin: 0 auto;
    margin-top: 2px;
    width: 65px;
  }
  input {
	margin: 0 auto;
	height: 25px;
  }
</style>
