<template>
  <form class="page-login content-boxed content-boxed-padding" @submit.prevent="submit" autocomplete="on" :style="isModal ? 'max-width: 500px;' : ''">
	<a v-if="isModal" @click="close" class="color-black pull-right pointer" style="margin-top: 0px; position: absolute; right: 20px; text-align: right;">
	  <i class="font-17 fa color-black">
		  <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 35%;"/>
	  </i>
	</a>
    <h1 class="bold small-top no-bottom center-text">{{lang[this.appLang].register}}</h1>
    <p class="half-bottom small-top center-text color-black"></p>

	<span v-if="emailLogin">
		<div class="page-login-field half-top">
		  <i class="fa" style="padding-top: 3px;">
			  <img src="https://cdn.tapni.co/icons/mail.png" class="fa responsive-image" style="width: 60%;">
		  </i>
		  <input v-model="email" @keydown.space.prevent type="email" :placeholder="lang[this.appLang].email" name="email" required>
		  <em></em>
		</div>
		<div class="page-login-field half-top">
		  <i class="fa" style="padding-top: 2px;">
			  <img src="https://cdn.tapni.co/icons/user.png" class="fa responsive-image" style="width: 50%;">
		  </i>
		  <input v-model="username" @keydown.space.prevent type="text"
				 autocorrect="off" autocapitalize="off" spellcheck="false"
				 :placeholder="lang[this.appLang].username" name="username" required>
		  <em></em>
		</div>
		<div class="page-login-field half-top half-bottom">
		  <i class="fa" style="padding-top: 2px;">
			  <img src="https://cdn.tapni.co/icons/padlock.png" class="fa responsive-image" style="width: 50%;">
		  </i>
		  <input v-model="password" :type="revealPassword ? 'text' : 'password'" :placeholder="lang[this.appLang].password" name="password" required minlength="8">
		  <em @click="revealPassword = !revealPassword" class="pointer">
			<img v-show="!revealPassword" src="https://cdn.tapni.co/icons/reveal-password.png" class="fa responsive-image password-reveal-icon">
			<img v-show="revealPassword" src="https://cdn.tapni.co/icons/hide-password.png" class="fa responsive-image password-reveal-icon">
		  </em>
		</div>
		<button type="submit" class="button google-button bg-tapni-grey pointer button-90 button-center uppercase button-rounded bold full-top">
		  <span v-if="!submitted">{{formButtonText}}</span>
		  <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 15px auto 0;width: 100%; text-align: center; font-size: 20px;"></i>
		</button>
	</span>
	<div class="decoration decoration-lines-thin half-top half-bottom"></div>

	<!-- Apple Login Button -->
	<a @click="appleLogin" v-if="displayAppleLogin"
		 class="button-center button button-90 google-button pointer">
		  <img src="https://cdn.tapni.co/icons/apple-logo.png" style="position: absolute; margin-left: -11px; padding: 8px 0; height: 100%;"/>
		  <span v-if="!appleLoad"> {{lang[this.appLang].sign_up_with}} Apple</span>
		  <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 11px auto 0;width: 100%; text-align: center; font-size: 24px;"></i>
	</a>

	<!-- Google Login Button -->
	<a @click="googleLogin"
		 class="button-center button button-90 google-button pointer ">
		  <img src="https://cdn.tapni.co/icons/g-logo.png" style="position: absolute; margin-left: -9px; padding: 8px 0; height: 100%;"/>
		  <span v-if="!googleLoad">{{lang[this.appLang].sign_up_with}} Google</span>
		  <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 11px auto 0;width: 100%; text-align: center; font-size: 24px;"></i>
	</a>

	<!-- Facebook Login Button -->
	<a @click="facebookLogin"
		 class="button-center button button-90 google-button pointer">
		  <img src="https://cdn.tapni.co/icons/facebook-blue.png" style="position: absolute; margin-left: -10px; padding: 10px 0; height: 100%;"/>
		  <span v-if="!facebookLoad">{{lang[this.appLang].sign_up_with}} Facebook</span>
		  <i v-else class="fa fa-circle-o-notch fa-spin" style="margin: 11px auto 0;width: 100%; text-align: center; font-size: 24px;"></i>
	</a>

	<!-- Email Login Button -->
	<a @click="emailLogin = true" v-if="!emailLogin"
	   class="button-center button button-90 google-button pointer">
		<img src="https://cdn.tapni.co/icons/mail.png" style="position: absolute; margin-left: -8px; padding: 12px 0; height: 100%;"/>
		<span>{{lang[this.appLang].sign_up_with}} Email</span>
	</a>

	<div class="decoration decoration-lines-thin no-bottom"></div>

    <p class="close-text center-text half-top color-black">
		{{lang[this.appLang].terms_by_registering}} <br>
		<a class="" href="https://tapni.co/policies/terms-of-service">{{lang[this.appLang].terms_of_service}}</a> {{lang[this.appLang].and}} <a href="https://tapni.co/policies/privacy-policy">{{lang[this.appLang].privacy_policy}}</a>
    </p>
    <div class="decoration decoration-lines-thin no-bottom"></div>
    <div v-if="!isModal" class="page-login-links">
	  <router-link class="create float-right" to="/verify">{{lang[this.appLang].verify_account}}</router-link>
	  <router-link class="forgot float-left" to="/login">{{lang[this.appLang].sign_in}}</router-link>
	  <div class="clear"></div>
	</div>
	<div v-else class="page-login-links" @click="loginAccountModal">
	  <a class="forgot center-text pointer small-top">{{lang[this.appLang].or}} <br> {{lang[this.appLang].sign_in_with_new}}</a>
	  <div class="clear"></div>
	</div>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions } from 'vuex'
import GoogleMixin from '../mixins/google.mixin'
import FacebookMixin from '../mixins/facebook.mixin'
import AppleMixin from '../mixins/apple.mixin'
import MicrosoftMixin from '../mixins/microsoft.mixin'
import router from "../router";
import {EventBus} from "../store/event-bus";
export default {
  name: 'AuthRegister',
  mixins: [GoogleMixin, FacebookMixin, AppleMixin, MicrosoftMixin],
  props: {
  	isModal: {
  	  type: Boolean,
	  required: false,
  	  default: false
  	},
  },
  data () {
    return {
      loading: true,
      submitted: false,
      formButtonText: 'REGISTER',
      username: '',
      email: '',
      password: '',
      captcha: '',
      revealPassword: false,
	    emailLogin: false,
      invitationCode: ''
    }
  },
  mounted () {
  	if (!this.isModal) {
	  if (this.isLoggedIn) {
	  	this.$router.push(this.home)
	  } else if (!this.route.query.state && !this.$route.query.oauth && !this.$route.params.oauth) {
	  	this.$store.commit('setLoading', false);
	  }
	  this.$store.commit('setInitialize', true);

    this.invitationCode = this.$route.query.ic || ''

	  if (this.isB2B && !this.company.login?.create_account) {
	  	this.$router.push('/login')
	  }
	}
  },
  methods: {
    ...mapActions([
      'register', 'loginSetup', 'getLoggedInAccounts'
    ]),
    submit() {
      // if (this.captcha === '') return this._vm.errorHandler({ message: 'Please select recaptcha' });
      if (this.password.length < 8) return this.errorSnack({ message: this.lang[this.appLang].password_8_chars });

      if (this.submitted) return
      this.submitted = true

	  this.email = this.email.trim()
	  this.username = this.username.trim()

      let data = {
        email: this.email,
        username: this.username,
        captcha: this.captcha,
        password: this.password,
        ic: this.invitationCode
      };

      this.register(data).then((response) => {
	    this.submitted = false
	    if (response.data && response.data.success) {
	      if (response.data.message === 'SUCCESS_REGISTER') {
	      	if (this.isModal) EventBus.$emit('toggleAddAccountModalVerify', false)
	      	else router.push('/verify')
	        this.successSnack(this.lang[this.appLang].register_success);
	        this.formButtonText = this.lang[this.appLang].check_your_inbox
	      } else if (response.data.message === 'SUCCESS_LOGIN') {
			if (this.isModal) {
			  this.username = ''
			  this.email = ''
			  this.password = ''
			  EventBus.$emit('closeModal')
			  this.successSnack(this.lang[this.appLang].register_success_modal)
			}
			this.loginSetup(response)
			this.getLoggedInAccounts(this.storage.refreshTokens.split(','))
	        router.push('/' + response.data.data.username + '#edit')
	      }
        }
      })
    },
	close () {
      EventBus.$emit('toggleAddAccountModal', false)
	},
	loginAccountModal () {
	  EventBus.$emit('toggleAddAccountModalLogin', false)
	}
  },
  watch: {
    email(nv) { this.storage.verifyEmail = nv }
  }
}
</script>

<style scoped>
</style>
