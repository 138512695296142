import to from 'await-to-js'
import AuthService from '../services/AuthService'
import {mapActions} from 'vuex'
import {MsAuthPlugin} from '@recognizebv/capacitor-plugin-msauth'
import {EventBus} from "../store/event-bus";

export default {
  data () {
    return {
      microsoftLoad: false
    }
  },
  computed: {
    displayMicrosoftLogin () {
      return (this.company?.login?.microsoft_login && !this.isModal) ?? false;
    }
  },
  methods: {
    ...mapActions(['loginSetup', 'getLoggedInAccounts']),
    async microsoftLogin () {
      this.microsoftLoad = true
      const [errAuth, user] = await to(MsAuthPlugin.login({
        clientId: this.$store.state.company.sso?.azure?.sso?.clientID,
        tenant: this.$store.state.company.sso?.azure?.sso?.tenant,
        scopes: ['User.Read'],
        knownAuthorities: [],
        keyHash: '4+5wCp8QcLptlO0aeP5RDTTOWyg=', // Android,
        redirectUri: process.env.NODE_ENV === 'development' ? 'http://localhost:7777/login' : 'https://' + window.location.host + '/login'
      }))
      if (errAuth) {
      	console.log("Error: " + JSON.stringify(errAuth));
      	return this.microsoftLoad = false
      }
      if (user && (user.accessToken || user.idToken)) {
        const [err, response] = await to(AuthService.microsoftSDK(user))
        if (err) {
          this.microsoftLoad = false
          this.$store.commit('setLoading', false)
          return this.errorHandler(err)
        }
        if (response.data.success) {
          this.loginSetup(response)
	      this.getLoggedInAccounts(this.storage.refreshTokens.split(','))
          this.$router.push('/' + response.data.data.username + '#edit')
          setTimeout(() => {
            if (response.data.message === 'SUCCESS_REGISTER') {
	            EventBus.$emit('openModalOverlay')
              EventBus.$emit('toggleUsernameModal')
            }
            this.microsoftLoad = false
            this.$store.commit('setLoading', false)
          }, 1000)
        } else this.errorSnack(this.lang[this.appLang].unexpected_err)
      } else {
        this.microsoftLoad = false
        this.$store.commit('setLoading', false)
      }
    },
    ssoLogin () {
      EventBus.$emit('toggleSSOModal')
    }
  }
}
