import Vue from 'vue'
import Router from 'vue-router'
import Routes from '../routes'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router)

export default new Router({
  routes: Routes,
  mode: 'history'
})
