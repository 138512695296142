/* eslint-disable */
import { mapState, mapGetters } from 'vuex'
import { Browser } from '@capacitor/browser';
import { Share } from '@capacitor/share';

export default {
  computed: {
    ...mapState(['lang', 'route', 'user', 'linkTypes', 'company']),
    ...mapGetters(['isLoggedIn', 'appLang']),
    isLoggedInUser() {
      return this.user &&
        this.user.id === this.storage.UserId &&
        this.user.username === this.storage.username &&
        this.isLoggedIn
    },
    home() {
      if (this.storage.token && this.storage.username) {
        return '/' + this.storage.username
      } else return '/welcome'
    },
    isCompletedProfileSetup() {
      if (this.user) {
        // Check if verified email
        if (this.user.status !== 'VERIFIED') return false
        if (this.user.links.length === 0) return false
        if (this.user.tags.length === 0) return false
        if (this.user.photo === 'https://cdn.tapni.co/images/user.png') return false
        return true
      }
      return null
    },
    cdnHost() {
      return process.env.VUE_APP_CDN_HOST
    },
    isB2B() {
      return !['my', 'preview', 'dev', 'localhost'].includes(this.$store.getters.subdomain);
    },
    isiOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
        // iPad on iOisNativeS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        || Capacitor.getPlatform() === 'ios'
    },
    isNative() {
      return Capacitor.isNativePlatform()
    }
  },
  methods: {
    errorHandler(error) {
      if (error && error.response && error.response.data && error.response.data.error) {
        if (
          error.response.data.error === 'ACCESS_DENIED' ||
          error.response.data.error === 'TOKEN_EXPIRED'
        ) {
          if (this.$store) this.$store.dispatch('logout');
          return error;
        }
        // Link click network error bug fix
        if (!error.response.data.error.includes('Network Error') && !error.response.data.error.includes('Cannot read properties')) {
          this.errorSnack(error.response.data.error)
        }
      }
      return error
    },
    errorSnack(message) {
      let errMessage = document.getElementById('errorMessage')
      let errorSnack = document.getElementById('errorSnack')
      errMessage.innerHTML = message
      errorSnack.classList.add('active-snack')
      setTimeout(function () { errorSnack.classList.remove('active-snack') }, 3000)
    },
    successSnack(message) {
      let successMessage = document.getElementById('successMessage')
      let successSnack = document.getElementById('successSnack')
      successMessage.innerHTML = message
      successSnack.classList.add('active-snack')
      setTimeout(function () { successSnack.classList.remove('active-snack') }, 3000)
    },
    closeSnacks() {
      document.getElementById('successSnack').classList.remove('active-snack')
      document.getElementById('errorSnack').classList.remove('active-snack')
    },
    tapsEmoji(count) {
      if (count < 10) return ''
      else if (count <= 50) return '🔥'
      else if (count <= 100) return '💥'
      else if (count <= 300) return '💎'
      else if (count > 300) return '🚀'
      return ''
    },
    redirectLink(data, openNative = false, returnLink = false) {
      let url = ''
      const linkType = this.linkTypes.filter(e => e.type === data.type)[0]
      if (!linkType.prefill_url) {
        url = linkType.base_url + data.url
      } else {
        url = data.url
      }
      if (openNative && Capacitor.isNativePlatform()) {
        return Browser.open({ url });
      }
      if (!returnLink) {
        //return window.open(url);
        return window.location.href = url;
      }
      else return url
    },
    async downloadContactCard(url, openNative = false) {

      console.log(url)
      // ako ne radi na telefonu, skidaj url direktno, bez axiosa
      //let response = await UserService.downloadVcard(url)
      //let _url = window.URL.createObjectURL(response.data);
      if (Capacitor.isNativePlatform() && openNative) {
        return Browser.open({ url });
      }
      return window.open(url, '_blank');
    },
    getMinutes(hours, minutes) {
      return parseInt(hours) * 60 + parseInt(minutes)
    },
    /** Function which checks if provided object is empty  */
    isEmpty(obj) {
      if (!obj) return true
      for (let prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          return false
        }
      }
      return JSON.stringify(obj) === JSON.stringify({})
    },
    /** Copy string to clipboard */
    copy(str = '') {
      const el = document.createElement('textarea')
      el.value = str
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.successSnack(this.lang[this.appLang].copied)
    },
    share(url, eventLog = 'qr') {

      if (this.isNative) {
        this.eventLog({
          type: eventLog + '_native_share'
        })

        Share.share({
          title: '',
          text: '',
          url,
          dialogTitle: ''
        });
      } else {
        this.eventLog({
          type: eventLog + '_web_share'
        })

        navigator.share({
          title: '',
          text: '',
          url
        })
      }
    },
  }
}
