<template>
  <form
    @submit.prevent="submit"
    class="menu-wrapper menu-light menu-modal center-text activate-page"
    :class="{ 'active-menu': toggle }"
    style="
      height: auto;
      margin-top: -172.5px;
      padding-bottom: 30px !important;
      max-height: 90vh;
    "
  >
    <a
      @click="close"
      class="color-black pull-right pointer"
      style="margin-top: 5px; position: absolute; right: 5px"
    >
      <i class="font-17 fa color-black">
        <img
          src="https://cdn.tapni.co/icons/down-arrow.png"
          class="fa responsive-image"
          style="width: 45%"
        />
      </i>
    </a>
    <h1
      v-if="user"
      class="bold small-top left-text headerText"
      style="padding-left: 5px"
    >
      {{
        this.lang[this.appLang][
          addConnection ? "createConnectionHeader" : "connection_edit"
        ]
      }}
    </h1>

    <div class="formContainer">
      <Connection
        v-if="!isEmpty(data) && !isAnonymous"
        :data="data"
        ref="connectionsRef"
        :showActionButton="false"
        isBigger
        style="min-height: 85px"
      />

      <input
        v-if="isAnonymous"
        type="text"
        v-model="name"
        :placeholder="lang[this.appLang].name"
        class="edit-input half-bottom"
        style="margin: 0 auto; width: 100%"
        :class="{ disabledField: !isAnonymous }"
        :disabled="!isAnonymous"
      />

      <input
        v-if="isAnonymous"
        type="text"
        v-model="email"
        :placeholder="lang[this.appLang].email"
        class="edit-input half-bottom"
        style="margin: 0 auto; width: 100%"
        :class="{ disabledField: !isAnonymous }"
        :disabled="!isAnonymous"
      />

      <div
        v-if="!showDetails"
        style="width: 65%; display: inline-block; margin: 0 auto"
      >
        <p
          @click="showDetails = true"
          class="center-text small-bottom pointer underline color-black"
          style="font-weight: 500"
        >
          {{
            addConnection
              ? lang[this.appLang].add_details
              : lang[this.appLang].show_details
          }}
        </p>
      </div>

      <input
        v-if="isAnonymous"
        type="text"
        v-model="phone"
        v-show="showDetails"
        :placeholder="lang[this.appLang].phone"
        class="edit-input half-bottom"
        style="margin: 0 auto; width: 100%"
        :class="{ disabledField: !isAnonymous }"
        :disabled="!isAnonymous"
      />

      <input
        v-if="isAnonymous"
        type="text"
        v-model="title"
        v-show="showDetails"
        :placeholder="lang[this.appLang].contact_card_job_title"
        class="edit-input half-bottom"
        style="margin: 0 auto; width: 100%"
        :class="{ disabledField: !isAnonymous }"
        :disabled="!isAnonymous"
      />

      <input
        v-if="isAnonymous"
        type="text"
        v-model="companyName"
        v-show="showDetails"
        :placeholder="lang[this.appLang].company"
        class="edit-input half-bottom"
        style="margin: 0 auto; width: 100%"
        :class="{ disabledField: !isAnonymous }"
        :disabled="!isAnonymous"
      />

      <input
          v-if="isAnonymous"
          type="text"
          v-model="address"
          v-show="showDetails"
          :placeholder="lang[this.appLang].address"
          class="edit-input half-bottom"
          style="margin: 0 auto; width: 100%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
      />

      <input
          v-if="isAnonymous"
          type="text"
          v-model="website"
          v-show="showDetails"
          :placeholder="lang[this.appLang].website"
          class="edit-input half-bottom"
          style="margin: 0 auto; width: 100%"
          :class="{ disabledField: !isAnonymous }"
          :disabled="!isAnonymous"
      />

      <textarea
        v-autogrow
        v-model="note"
        v-show="showDetails"
        :placeholder="lang[this.appLang].note_p"
        class="edit-input half-bottom small-top"
        rows="2"
        style="
          width: 100%;
          margin: 0 auto;
          min-height: 65px;
          resize: none;
          line-height: 20px;
        "
      />

      <multiselect
        v-show="showDetails"
        v-model="markers"
        :options="options"
        :multiple="true"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        :placeholder="lang[this.appLang].createMarkerPlaceholder"
        label="name"
        track-by="name"
        :preselect-first="true"
        selectLabel=""
        deselectLabel=""
        :tagPlaceholder="lang[this.appLang].clickToCreateMarker"
        :taggable="true"
        @tag="addTag($event)"
        style="
          border-radius: 12px;
          margin: 0 auto !important;
          width: 100% !important;
        "
        class="full-bottom"
        selectedLabel=""
        :loading="isLoading"
      />
    </div>

    <div
      class="full-top"
      style="
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
      "
      v-if="!addConnection"
    >
      <a
        v-if="!addConnection && isNative"
        @click="openFollowUpSchedule"
        class="button white-button small-bottom pointer"
        style="width: 100%; margin: 0"
      >
        <img
          src="https://cdn.tapni.co/icons/bell.png"
          height="22"
          width="22"
          class="btn-icon"
        />
        {{ lang[this.appLang].follow_up_reminder }}
      </a>

      <a
        v-if="!addConnection"
        @click="downloadVcard"
        class="button white-button small-bottom pointer"
        style="width: 100%; margin: 0"
      >
        <img
          src="https://cdn.tapni.co/icons/download.png"
          height="25"
          width="25"
          class="btn-icon"
        />
        {{ lang[this.appLang].download_contact }}
      </a>
    </div>
    <div
      class="half-top"
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <!-- Delete -->
      <a
        v-if="!confirmDelete && !addConnection"
        @click="confirmDelete = true"
        class="button white-button pointer"
        style="
          width: 60px;
          margin: 0;
          border-radius: 8px;
          padding-left: 7px;
          padding-right: 7px;
        "
      >
        <img
          src="https://cdn.tapni.co/icons/delete.png"
          height="25"
          width="25"
          class="btn-icon"
          style="margin-right: 0px"
        />
      </a>

      <a
        v-if="confirmDelete"
        @click="removeConnection"
        class="button white-button pointer"
        style="
          color: #fc1414;
          width: auto;
          margin: 0;
          border-radius: 12px;
          font-size: 14px;
        "
      >
        {{ lang[this.appLang].confirm }}
      </a>
      <!-- Delete -->
      <a
        class="button black-button pointer"
        style="margin: 0; width: 63%; margin-left: auto"
        @click="submit"
        v-if="!addConnection"
      >
        {{ lang[this.appLang].save }}
      </a>

      <div v-if="addConnection" class="addConnectionButtonContainer">
        <button
          class="button-center half-top black-button pointer no-bottom"
          style="width: 100%"
        >
          {{ lang[this.appLang].create_connection }}
        </button>
        <div
          v-if="!scanID"
          class="decoration decoration-lines-thin half-bottom"
        ></div>

        <button
          v-if="!scanID"
          class="button-center white-button pointer small-bottom"
          style="width: 100%"
          @click="addByScanning"
        >
          <img
            src="https://cdn.tapni.co/icons/card-scanner.png"
            height="22"
            width="22"
            class="btn-icon"
          />
          {{ lang[this.appLang].create_using_scanner }}
        </button>

        <!--
        <LinkIcon
          v-if="!scanID"
          @click.native="addByScanning"
          :link-style="'link-list'"
          :data="{
            type: 'scan',
            text: lang[this.appLang].create_using_scanner,
          }"
          hideExternalIcon
          :editing="false"
          class="button-full"
        />
        -->
      </div>
    </div>
  </form>
</template>

<script>
import { EventBus } from "../../store/event-bus";
import { mapState, mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import UserService from "../../services/UserService";
import { Camera, CameraResultType } from "@capacitor/camera";
import Connection from "../../components/Connection";
import LinkIcon from "../../components/LinkIcon";

export default {
  name: "ConnectionModal",
  components: {
    Multiselect,
    Connection,
    LinkIcon,
  },
  computed: {
    ...mapState(["loading"]),
    showCompanyName() {
      return this.companyName || this.isAnonymous;
    },
    showCompanyTitle() {
      return this.title || this.isAnonymous;
    },
    vcardAvailable() {
      return Object.keys(this.data?.vcard || {}).length;
    },
  },
  data() {
    return {
      submitDisabled: false,
      data: {},
      id: "",
      name: "",
      email: "",
      email2: "",
      phone: "",
      phone2: "",
      note: "",
      companyName: "",
      address: "",
      title: "",
      website: "",
      website2: "",
      scanID: "",
      scanImage: "",
      showImage: false,

      toggle: false,
      showDetails: false,
      confirmDelete: false,

      isLoading: false,
      options: [],
      markers: [],
      isAnonymous: false,
      addConnection: false,
    };
  },
  mounted() {
    EventBus.$on("toggleConnectionModal", this.toggleModal);
    EventBus.$on("closeModal", () => {
      this.toggle = false;
      this.resetForm();
    });
  },
  methods: {
    ...mapActions([
      "saveConnection",
      "getUser",
      "deleteConnection",
      "createConnection",
    ]),

    openFollowUpSchedule() {
      this.close();
      EventBus.$emit("toggleFollowUpScheduleModal", this.data);
    },

    async addTag(name) {
      await UserService.createMarker({ name });

      await this.getMarkers();

      this.selectMarkerByName(name);
    },

    selectMarkerByName(name) {
      const marker = this.options.find((marker) => marker.name === name);
      this.markers.push(marker);
    },

    join() {
      this.close();
      this.$router.push("/register");
    },

    close() {
      EventBus.$emit("closeModal");
    },

    contact(type, data) {
      this.redirectLink({
        type,
        url: data,
      });
    },

    async removeConnection() {
      await this.deleteConnection(this.id);
      this.successSnack(this.lang[this.appLang].connection_delete_success);
      EventBus.$emit("refreshConnections");
      this.close();
      this.$forceUpdate();
    },

    async submit() {
      if (this.submitDisabled) return;

      this.submitDisabled = true;
      if (this.isAnonymous && !this.name) {
        return this.errorSnack("Please enter valid name");
      }
      if (this.isAnonymous && !this.email) {
        return this.errorSnack("Please enter valid email");
      }

      if (this.addConnection) {
        const response = await this.createConnection({
          name: this.name,
          email: this.email,
          email2: this.email2,
          phone: this.phone,
          phone2: this.phone2,
          address: this.address,
          website: this.website,
          website2: this.website2,
          note: this.note,
          markers: this.markers.map((marker) => marker.id),
          company: this.companyName,
          scanID: this.scanID,
          title: this.title,
        });
        if (response?.data?.success) {
          this.successSnack(this.lang[this.appLang].createConnectionSuccess);
          EventBus.$emit("closeModal");
        }
      } else {
        await this.saveConnection({
          id: this.id,
          name: this.name,
          email: this.email,
          email2: this.email2,
          phone: this.phone,
          phone2: this.phone2,
          address: this.address,
          website: this.website,
          website2: this.website2,
          note: this.note,
          markers: this.markers.map((marker) => marker.id),
          company: this.companyName,
          title: this.title,
        });
        this.successSnack(this.lang[this.appLang].connection_edit_success);
      }

      if (this.$route.name === "HomeUsername") {
        await this.getUser({ username: this.user.username });
      } else {
        EventBus.$emit("refreshConnections");
      }

      this.close();
      this.$forceUpdate();
      this.submitDisabled = false;
    },

    toggleModal(data) {
      this.data = data;
      this.resetForm();

      // Check if the connection is form or direct
      this.isAnonymous =
        data.addConnection || Object.keys(data.payload || {}).length;

      this.showDetails = !this.isAnonymous;

      if (data.addConnection) {
        this.addConnection = true;
        this.name = data.name;
        this.email = data.email;
        this.email2 = data.email2;
        this.phone = data.phone;
        this.phone2 = data.phone;
        this.address = data.address;
        this.website = data.website;
        this.website2 = data.website2;
        this.note = data.note;
        this.companyName = data.company;
        if (data.scanID) {
          this.scanID = data.scanID;
          this.showDetails = true;
        }
      } else {
        this.id = data.id;
        this.markers = data.markers;
        if (data.payload) {
          this.name = data.payload.name;
          this.email = data.payload.email;
          this.email2 = data.payload.email2;
          this.phone = data.payload.phone;
          this.phone2 = data.payload.phone2;
          this.website = data.payload.website;
          this.website2 = data.payload.website2;
          this.note = data.payload.note;
          this.companyName = data.payload.company;
          this.address = data.payload.address;
          this.title = data.payload.title;
          this.scanImage = data.scan?.front_picture;
        } else {
          this.name = `${data.vcard.vcardFirstName || ""} ${
            data.vcard.vcardLastName || ""
          }`.trim();
          this.email = data.vcard.vcardEmail;
          this.phone = data.vcard.vcardPhone;
          this.note = data.note;
          this.companyName = data.company;
          this.title = data.vcard.vcardTitle;
        }
      }

      this.getMarkers(data);
      this.toggle = !this.toggle;
    },

    resetForm() {
      this.id = "";
      this.name = "";
      this.email = "";
      this.email2 = "";
      this.phone = "";
      this.phone2 = "";
      this.address = "";
      this.website = "";
      this.website2 = "";
      this.note = "";
      this.companyName = "";
      this.markers = [];
      this.addConnection = false;
      this.confirmDelete = false;
      this.showImage = false;
      this.scanImage = "";
      this.scanID = null;
    },

    async getMarkers() {
      const {
        data: { markers },
      } = await UserService.getAllMarkers();
      this.options = markers;
    },

    closeAddConnection() {
      EventBus.$emit("closeModal");
    },

    async addByScanning() {
      EventBus.$emit("closeModal");

      if (Capacitor.isNativePlatform()) {
        await Camera.requestPermissions();
      }

      try {
        const image = await Camera.getPhoto({
          quality: 100,
          limit: 1,
          allowEditing: false,
          resultType: CameraResultType.Base64,
        });
        if (image) EventBus.$emit("toggleScanCropperModal", image.base64String);
      } catch (err) {
        console.error(err.message);
      }
    },

    downloadVcard() {
      //for anonymous connections
      let url =
        process.env.VUE_APP_API_ROOT +
        "/v1/users/connection/" +
        this.data.id +
        "/vcard";

      //for direct connections
      if (this.data.status === "connected") {
        let userId;
        if (this.storage.UserId !== this.data.requester?.id) {
          userId = this.data.requester?.id;
        } else {
          userId = this.data.receiver?.id;
        }
        url = process.env.VUE_APP_API_ROOT + "/v1/users/" + userId + "/vcard";
      }

      this.downloadContactCard(url);
    },
  },
};
</script>

<style scoped>
.link-img {
  margin: 0 auto;
  width: 55px;
}
#linkText {
  font-size: 22px;
}

#linkUrl {
  font-size: 15px;
}

input {
  margin: 7px 0;
  margin: 0 auto;
  height: 40px;
}
</style>

<style>
.multiselect * {
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-size: 15px !important;
  font-weight: 400;
  color: #757575 !important;
}

.multiselect__option > span {
  color: black !important;
}

.multiselect__option:after {
  font-size: 15px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  color: #757575 !important;
}

.multiselect__input::placeholder {
  font-size: 15px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
  font-weight: 400;
  color: #757575 !important;
}

.multiselect__tags {
  border: none;
  border-radius: 12px;
  overflow-x: scroll;
  padding-top: 0px !important;
  height: 65px !important;
  background: rgb(244, 244, 244) !important;
}

.multiselect__tags input {
  background: rgb(244, 244, 244);
  margin-top: 7px !important;
  color: black !important;
}

.multiselect__tag {
  background: black;
  margin-top: 10px !important;
  margin-bottom: -5px !important;
}

.multiselect__tag > span {
  color: white !important;
}

.multiselect__tag-icon:after {
  color: white;
}

.multiselect__option--selected.multiselect__option--highlight {
  background: #f3f3f3;
  color: #35495e;
}

.multiselect__option--highlight {
  background: #f3f3f3;
  color: #35495e;
}

.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: black;
}

.multiselect__option--highlight:after {
  background: #f3f3f3;
  color: #35495e;
}

.multiselect__element {
  display: none;
}

.multiselect__element:nth-last-child(-n + 5) {
  display: block;
}

.multiselect__placeholder {
  padding-top: -5px !important;
  color: #7b7777;
}
.multiselect__input {
  font-size: 14px;
  color: #7b7777;
  padding-left: 0px;
  margin-left: 2px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 5px 5px 20px;
  max-height: calc(92vh - 346px);
  overflow-y: scroll;
}

.formContainer input,
.formContainer textarea {
  margin: unset;
}

.formContainer textarea {
  /* margin-bottom: 27px; */
}

.formContainer p {
  margin-bottom: 5px;
}

.phoneContainer {
  display: flex;
  align-items: center;
  /* margin-bottom: 20px; */
}

.phoneContainer input {
  flex: 1;
}

.phoneContainer img {
  width: 40px;
}

.emailContainer {
  display: flex;
  align-items: center;
}

.emailContainer input {
  flex: 0.71;
}

.emailContainer img {
  flex: 0.29;
  width: 40px;
}

.bottomInputMargin {
  margin-bottom: 7px !important;
}

.topInputMargin {
  margin-top: 7px !important;
}

.disabledField {
  opacity: 0.5 !important;
}

.addConnectionButtonContainer {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.decoration {
  margin-top: 20px;
}

.vpd-wrapper {
  background-color: transparent !important;
}
</style>
