<template>
    <div class="menu-wrapper menu-light menu-white menu-modal center-text activate-page" :class="{'active-menu': toggle}" style="height: auto; max-height: 92vh; margin-top: -172.5px;">
      <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
	      <i class="font-17 fa color-black">
		      <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
	      </i>
      </a>
	  <h3 class="bold center-text color-black small-bottom">{{lang[this.appLang].app_language}}</h3>

	  <LinkIcon @click.native="updateLanguage('en')"
	            :link-style="'link-grid'"
	            :data="{ type: 'lang/en', text: lang[this.appLang].english }"
	            :editing="false"
	            :class="{ 'add-button': true }" />

	  <LinkIcon @click.native="updateLanguage('es')"
	            :link-style="'link-grid'"
	            :data="{ type: 'lang/es', text: lang[this.appLang].spanish }"
	            :editing="false"
	            :class="{ 'add-button': true }" />

	  <LinkIcon @click.native="updateLanguage('de')"
	            :link-style="'link-grid'"
	            :data="{ type: 'lang/de', text: lang[this.appLang].german }"
	            :editing="false"
	            :class="{ 'add-button': true }" />

	  <LinkIcon @click.native="updateLanguage('it')"
	  		    :link-style="'link-grid'"
	  		    :data="{ type: 'lang/it', text: lang[this.appLang].italian }"
	  		    :editing="false"
	  		    :class="{ 'add-button': true }" />

	  <LinkIcon @click.native="updateLanguage('fr')"
	  		  	:link-style="'link-grid'"
	  		  	:data="{ type: 'lang/fr', text: lang[this.appLang].french }"
	  		  	:editing="false"
	  		  	:class="{ 'add-button': true }" />

	  <LinkIcon @click.native="updateLanguage('kr')"
	  		    :link-style="'link-grid'"
	  		    :data="{ type: 'lang/kr', text: lang[this.appLang].korean }"
	  		    :editing="false"
	  		    :class="{ 'add-button': true }" />

	  <LinkIcon @click.native="updateLanguage('sr')"
				:link-style="'link-grid'"
				:data="{ type: 'lang/sr', text: lang[this.appLang].serbian }"
				:editing="false"
				:class="{ 'add-button': true }" />

	  <LinkIcon @click.native="updateLanguage('tr')"
	  		    :link-style="'link-grid'"
	  		    :data="{ type: 'lang/tr', text: lang[this.appLang].turkish }"
	  		    :editing="false"
	  		    :class="{ 'add-button': true }" />
    </div>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapMutations, mapActions} from 'vuex'
import LinkIcon from "../LinkIcon";
import UtilService from "../../services/UtilService";
import CONSTANTS from '../../constants';
import { Device } from '@capacitor/device';

export default {
  name: 'Language',
	components: {LinkIcon},
	data () {
    return {
      toggle: false
    }
  },
  async mounted () {
    EventBus.$on('toggleLanguageModal', this.toggleModal)
    EventBus.$on('closeModal', () => { this.toggle = false })

	let lang = (this.isNative) ? await Device.getLanguageCode() : UtilService.getFirstBrowserLanguage()
	if (lang && CONSTANTS.SUPPORTED_LANGUAGES.includes(lang)) {
		this.updateLanguage(lang);
	}
  },
  methods: {
    ...mapActions(['saveProfile', 'getLoggedInAccounts']),
    ...mapMutations(['setLanguage']),
    forceClose () {
      EventBus.$emit('closeModal')
    },
    close () {
      this.forceClose()
      if (this.isLoggedIn) {
        EventBus.$emit('toggleProfileModal')
      }
    },
    async updateLanguage (lang) {
	  this.$store.dispatch('lang/registerLang', lang)
	  this.setLanguage(lang)
      if (this.isLoggedIn && !this.isEmpty(this.user) && this.user.username) {
        await this.saveProfile({ lang, username: this.user.username })
      }
      EventBus.$emit('closeModal')
      this.$forceUpdate()
    },
    toggleModal () {
      this.toggle = !this.toggle
    }
  }
}
</script>

<style scoped>
  .user-img {
    margin: 0 auto;
    margin-top: 2px;
    width: 65px;
  }
  input {
	margin: 0 auto;
	height: 25px;
  }
</style>
