const Home = () => import(/* webpackChunkName: "Home" */ './views/Home.vue')
const NotFound = () => import(/* webpackChunkName: "404" */ './views/404.vue')
const DownloadApp = () => import(/* webpackChunkName: "404" */ './views/DownloadApp.vue')
const Maintenance = () => import(/* webpackChunkName: "Maintenance" */ './views/Maintenance.vue')
const Update = () => import(/* webpackChunkName: "Update" */ './views/Update.vue')
const LinkAdding = () => import(/* webpackChunkName: "LinkAdding" */ './views/LinkAdding.vue')
const Tags = () => import(/* webpackChunkName: "Tags" */ './views/Tags.vue')
const Connections = () => import(/* webpackChunkName: "Connections" */ './views/Connections.vue')

// const Terms = () => import(/* webpackChunkName: "Terms" */ './views/Terms.vue')
// const Help = () => import(/* webpackChunkName: "Help" */ './views/Help.vue')
const Devices = () => import(/* webpackChunkName: "Devices" */ './views/Devices.vue')
const Referral = () => import(/* webpackChunkName: "Referral" */ './views/Referral.vue')
const Products = () => import(/* webpackChunkName: "Products" */ './views/Products.vue')
const Welcome = () => import(/* webpackChunkName: "Welcome" */ './views/Welcome.vue')
const Login = () => import(/* webpackChunkName: "Login" */ './views/Login.vue')
const Register = () => import(/* webpackChunkName: "Register" */ './views/Register.vue')
const Reset = () => import(/* webpackChunkName: "Reset" */ './views/Reset.vue')
const Verify = () => import(/* webpackChunkName: "Verify" */ './views/Verify.vue')
const CompanyInvitation = () => import(/* webpackChunkName: "Verify" */ './views/CompanyInvitation.vue')

export default [
  {
    path: '/404',
    name: 'Error404',
    component: NotFound
  },
  {
  	path: '/app',
  	name: 'DownloadApp',
  	component: DownloadApp
  },
  {
    path: '/maintenance',
    name: 'ErrorMaintenance',
    component: Maintenance
  },
  {
    path: '/update',
    name: 'ErrorUpdate',
    component: Update
  },
  {
    path: '/welcome',
    name: 'AuthWelcome',
    component: Welcome
  },
  {
    path: '/login',
    name: 'AuthLogin',
    component: Login
  },
  {
    path: '/reset',
    name: 'AuthReset',
    component: Reset
  },
  {
    path: '/register',
    name: 'AuthRegister',
    component: Register
  },
  {
    path: '/verify',
    name: 'AuthVerify',
    component: Verify
  },
  {
    path: '/link/add',
    name: 'LinkAdding',
    component: LinkAdding
  },
  {
    path: '/connections',
    name: 'Connections',
    component: Connections
  },
  {
    path: '/tags',
    name: 'Tags',
    component: Tags
  },
  {
    path: '/t/:serial',
    name: 'HomeSerial',
    component: Home
  },
  /*{
    path: '/terms/:lang?',
    name: 'Terms',
    component: Terms
  },*/
  {
    path: '/devices/:lang?',
    name: 'Devices',
    component: Devices
  },
  {
    path: '/referral',
    name: 'Referral',
    component: Referral
  },
  {
    path: '/products',
    name: 'Products',
    component: Products
  },
  /* {
    path: '/help',
    name: 'AuthHelp',
    component: Help
  }, */
  {
    path: '/:username?',
    name: 'HomeUsername',
    component: Home
  },
  {
    path: '/users/invitation',
    name: 'CompanyInvitation',
    component: CompanyInvitation
  },
  {
    path: '/call/:tel',
    beforeEnter (to) {
      location.href = 'tel:' + to.params.tel
    }
  }
]
