import Api from './Api'

export default {
  getTypes () {
    return Api().get(`/links/types/v2`)
  },
  updateLinksOrder (data) {
    return Api().put(`/links/order`, data)
  },
  edit (data) {
    return Api().put(`/links/${data.id}`, data)
  },
  add (data) {
    return Api().post(`/links`, data)
  },
  remove (data) {
    return Api().delete(`/links/` + data.id)
  },
  linkClick (data) {
    return Api().post(`/links/click/${data.id}`, data)
  },
}
