import to from 'await-to-js'
import AuthService from '../services/AuthService'
import {mapActions} from 'vuex'
import {SignInWithApple} from '@capacitor-community/apple-sign-in';

import decode from 'jwt-decode'
import {EventBus} from '../store/event-bus';
export default {
  data () {
    return {
      appleLoad: false
    }
  },
  computed: {
    displayAppleLogin () {
      return (this.company?.login?.apple_login && !this.isModal) ?? ((Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios') || (!Capacitor.isNativePlatform() && this.isiOS));
    }
  },
  methods: {
    ...mapActions(['loginSetup', 'getLoggedInAccounts']),
    async appleLogin () {
      this.appleLoad = true
      const [errAuth, user] = await to(SignInWithApple.authorize({
        clientId: 'co.tapni.app',
        redirectURI: 'https://' + this.$store.getters.subdomain + '.tapni.co/login',
        scopes: 'email name'
      }))
      if (errAuth) return this.appleLoad = false
      user.response.tokenData = decode(user.response.identityToken);

      if (user && user.response && user.response.identityToken) {
        const [err, response] = await to(AuthService.appleSDK(user.response))
        if (err) {
          this.appleLoad = false
          this.$store.commit('setLoading', false)
          return this.errorHandler(err)
        }
        if (response.data.success) {
          this.loginSetup(response)
	      this.getLoggedInAccounts(this.storage.refreshTokens.split(','))
          this.$router.push('/' + response.data.data.username + '#edit')
          setTimeout(() => {
            if (response.data.message === 'SUCCESS_REGISTER') {
	            EventBus.$emit('openModalOverlay')
              EventBus.$emit('toggleUsernameModal')
            }
            this.appleLoad = false
            this.$store.commit('setLoading', false)
          }, 1000)
        } else this.errorSnack(this.lang[this.appLang].unexpected_err)
      } else {
        this.appleLoad = false
        this.$store.commit('setLoading', false)
      }
    }
  }
}
