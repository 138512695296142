<template>
    <div v-if="!isEmpty(user)" class="menu-wrapper menu-light menu-modal center-text activate-page" :class="{'active-menu': toggle}" :style="`height: ${modalHeight}px; margin-top: -172.5px;`">
      <a @click="close" class="color-black pull-right pointer" style="margin-top: 5px; position: absolute; right: 5px;">
	      <i class="font-17 fa color-black">
		      <img src="https://cdn.tapni.co/icons/down-arrow.png" class="fa responsive-image" style="width: 45%;"/>
	      </i>
      </a>
      <img :src="'https://cdn.tapni.co/icons/direct.png'" class="direct-img half-bottom" alt="Direct"/>
	  <h1 class="bold center-text color-black half-bottom">{{this.lang[this.appLang].direct_pick}}</h1>
	  <div class="directlink-wrapper bg-white">
		  <LinkIcon v-for="link in user.links" :key="link.id"
		            :data="link" :link-style="'link-grid small'"
		            :editing="false" :direct-pick="true" class="link-transition"
		            @directPick="directPick"
		  />
	  </div>

	  <p class="center-text font-15q" style="position: absolute; bottom: 30px; width: 90%; max-width: 500px;" v-html="this.lang[this.appLang].direct_p" />
    </div>
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapActions} from 'vuex'
import LinkIcon from '../LinkIcon'
export default {
  name: 'Direct',
  components: {LinkIcon},
  data () {
    return {
      toggle: false
    }
  },
  computed: {
    modalHeight () {
      if (this.user && this.user.links && this.user.links.length > 0) {
        const h = 400 + (Math.floor(this.user.links.length / 3) * 110)
        return h < 550 ? h : 550;
      }
      return 400
    }
  },
  mounted () {
    EventBus.$on('toggleDirectModal', this.toggleModal)
    EventBus.$on('closeModal', () => { this.toggle = false })
  },
  methods: {
    ...mapActions(['saveProfile', 'addOrUpdateLink']),
    close () {
      EventBus.$emit('closeModal')
    },
    toggleModal () {
      this.toggle = !this.toggle
    },
    directPick (data) {
      this.addOrUpdateLink({
        id: data.id,
        is_direct: true
      })
      EventBus.$emit('closeModal', true)
    }
  }
}
</script>

<style scoped>
  .direct-img {
    margin: 0 auto;
    margin-top: 2px;
    width: 45px;
  }

  input {
	margin: 0 auto;
	height: 25px;
  }

  .directlink-wrapper {
    max-height: 65%;
    max-width: 380px;
    margin: 0 auto;
    overflow-y: scroll;
    border-radius: 20px;
    padding: 20px 0%;
    border: 1px solid #e8e8e8;
  }
</style>
