import Api from './Api'



export default {
  getMe() {
    return Api().get(`/users/me`)
  },
  getByUsername(data) {
    let params = data.utmParams || {}
    if(data.dontTap) params.dontTap = true;
    return Api().get(`/users/${data.username}`, {params})
  },
  getByNumber(data) {
    let params = data.utmParams || {}
    if(data.dontTap) params.dontTap = true;
    return Api().get(`/users/tag/${data.serial}`, {params})
  },
  save(data) {
    return Api().put(`/users`, data)
  },
  updateLinkStyle(data) {
    return Api().put(`/users/style`, data)
  },
  updateIsPublic(data) {
    return Api().put(`/users/public`, data)
  },
  updateIsActive(data) {
    return Api().put(`/users/active`, data)
  },
  updateIsDirect(data) {
    return Api().put(`/users/direct`, data)
  },
  getAffiliate(UserId) {
    return Api().get(`/affiliate/` + UserId)
  },
  affiliatePayout() {
    return Api().post(`/affiliate/payout`)
  },
  referralApply(data) {
    return Api().post(`/affiliate/apply`, data)
  },
  newPassword(data) {
    return Api().put(`/users/new-password`, data)
  },
  connect(data) {
    return Api().post(`/users/connect`, data)
  },
  saveConnection(data) {
    return Api().put(`/users/connection/` + data.id, data)
  },
  deleteConnection(id) {
    return Api().delete(`/users/connection/` + id)
  },
  createConnection(data) {
    return Api().post('users/connection', data)
  },
  accept(data) {
    return Api().post(`/users/accept`, data)
  },
  getConnection(connectionId) {
    return Api().get(`/users/connection/${connectionId}`)
  },
  getConnections(params) {
    params.markers = params.markers?.join(',');
    return Api().get(`/users/connections`, {
      params
    })
  },
  eventLog(data) {
    return Api().post(`/users/log`, data)
  },
  getLoggedInAccounts(data) {
    return Api().post(`/users/tokens`, data)
  },
  updateVCard(data) {
    return Api().put(`/users/vcard`, data)
  },
  deleteAccount(data) {
    return Api().post(`/users/profile/delete`, data)
  },
  getAllMarkers() {
    return Api().get('/users/markers')
  },
  createMarker(data) {
    return Api().post('/users/marker', data)
  },
  downloadVcard(url) {
    return Api().get(url, {responseType: 'blob'})
  },
  getFile(url) {
    return Api().get(url, {responseType: 'arraybuffer',  transformRequest: (data, headers) => {
        delete headers['X-Client-Version'];
        delete headers['X-Client-Name'];
        delete headers['Authorization'];
        return data;
      }}
    )
  }
}
