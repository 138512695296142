<template>
    <AuthLogin v-if="page === 'login'" class="menu-wrapper menu-light menu-modal activate-page no-bottom" :isModal="true"
               :class="{'active-menu': toggle}" style="height: 85vh; margin-top: -172.5px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;" />
    <AuthRegister v-else-if="page === 'register'" class="menu-wrapper menu-light menu-modal activate-page no-bottom" :isModal="true"
               :class="{'active-menu': toggle}" style="height: 85vh; margin-top: -172.5px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;" />
    <AuthVerify v-else-if="page === 'verify'" class="menu-wrapper menu-light menu-modal activate-page no-bottom" :isModal="true"
               :class="{'active-menu': toggle}" style="height: 85vh; margin-top: -172.5px; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px;" />
</template>

<script>
import {EventBus} from '../../store/event-bus'
import {mapActions} from 'vuex'
import AuthLogin from "../../views/Login";
import AuthRegister from "../../views/Register";
import AuthVerify from "../../views/Verify";
export default {
    name: 'AddAccount',
    components: {AuthVerify, AuthRegister, AuthLogin},
    data () {
        return {
            toggle: false,
            page: 'login'
        }
    },
    mounted () {
        EventBus.$on('toggleAddAccountModalRegister', () => {
            this.page = 'register'
        })
        EventBus.$on('toggleAddAccountModalLogin', () => {
            this.page = 'login'
        })
        EventBus.$on('toggleAddAccountModalVerify', () => {
            this.page = 'verify'
	        this.toggle = !this.toggle
        })
        EventBus.$on('toggleAddAccountModal', this.toggleModal)
        EventBus.$on('closeModal', () => {
            this.toggle = false
            if (this.page !== 'verify') {
	            setTimeout(() => {
		            this.page = 'login'
	            }, 1500)
            }
        })
    },
    methods: {
        ...mapActions(['connect', 'getUser']),
        close () {
            EventBus.$emit('closeModal')
        },
        async submit () {},
        toggleModal () {
            this.toggle = !this.toggle
            if (!this.toggle) {
                this.page = 'login'
            }
        }
    }
}
</script>

<style scoped>
.link-img {
    margin: 0 auto;
    width: 55px;
}
#linkText {
    font-size:22px;
}

#linkUrl {
    font-size:15px;
}
input {
    margin: 7px 0;
    margin: 0 auto;
    height: 25px;
}
</style>
